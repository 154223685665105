import React, { Component } from "react";
import {
  mask_tel_celular_area,
  mask_CNPJ,
  mask_minusculo_sem_espaco,
} from "../../plugins/Mascara/Mascara";
import {
  api,
  dominio,
  handleError,
  handleSuccess,
  handleInfo,
} from "../../plugins/Config/Config";
import Select from "react-select";

export default class Login_lojista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      statusOptions: [],
      model: {
        emp_id: 0,
        emp_senha: "",
        emp_razaosocial: "",
        emp_endereco: "",
        emp_fone: "",
        emp_celular: "",
        emp_cnpj: "",
        emp_email: "",
        emp_perfilfacebooks: "",
        emp_nomefantasia: "",
        emp_senha_repetir: "",
        emp_historia: "",
        emp_principaisprodutos: "",
        emp_canalyoutube: "",
        emp_website: "",
        emp_twitter: "",
        emp_instagram: "",
        emp_linkedin: "",
      },
    };
  }

  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    model.emp_celular = mask_tel_celular_area(model.emp_celular);
    model.emp_fone = mask_tel_celular_area(model.emp_fone);
    model.emp_cnpj = mask_CNPJ(model.emp_cnpj);
    model.emp_senha = mask_minusculo_sem_espaco(model.emp_senha);
    model.emp_email = mask_minusculo_sem_espaco(model.emp_email);

    this.setState({ model });
    // console.log(JSON.stringify(this.state.model));
  };

  get_empresa = () => {
    api
      .get("api/empresa/" + localStorage.getItem("id"),{  headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },})
      .then((response) => {
        response.data = response.data[0];

        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data !== "") {
          this.setState({ empresa: response.data });
          this.setState({
            model: {
              emp_id: response.data.emp_id,
              emp_senha: response.data.emp_senha,
              emp_senha_repetir: response.data.emp_senha,
              emp_razaosocial: response.data.emp_razaosocial,
              emp_endereco: response.data.emp_endereco,
              emp_fone: response.data.emp_fone,
              emp_celular: response.data.emp_celular,
              emp_cnpj: response.data.emp_cnpj,
              emp_email: response.data.emp_email,
              emp_nomefantasia: response.data.emp_nomefantasia,
              emp_historia: response.data.emp_historia,
              emp_principaisprodutos: response.data.emp_principaisprodutos,
              emp_canalyoutube: response.data.emp_canalyoutube,
              emp_website: response.data.emp_website,
              emp_twitter: response.data.emp_twitter,
              emp_instagram: response.data.emp_instagram,
              emp_perfilfacebooks: response.data.emp_perfilfacebook,
              emp_linkedin: response.data.emp_linkedin,
            },
          });
          if (response.data.emp_lemeliquida) {
            document.getElementById("emp_lemeliquida").checked = true;
          }
          if (response.data.emp_queimaestoque) {
            document.getElementById("emp_queimaestoque").checked = true;
          }
          const selectedOption = [];
          selectedOption.push({
            value: response.data.emp_cidade,
            label: response.data.emp_cidade,
          });
          this.setState({ selectedOption });
        }
        let spans = "";
        let emp_imagem400 = response.data.emp_imagem400;

        if (emp_imagem400 !== null) {
          spans +=
            '<input type="checkbox"  style="display: none;" className="checkbox_imagem" value="' +
            emp_imagem400 +
            '" id="' +
            emp_imagem400 +
            '" />';
          spans += "<label for=" + emp_imagem400 + ">";
          spans +=
            '<img className="thumb-upload"  style="max-width: 150px;max-height: 150px;"  id=' +
            emp_imagem400 +
            " src=" +
            dominio +
            "/" +
            emp_imagem400 +
            " />";
          spans += "</label>";
        }

        document.getElementById("list").innerHTML = spans;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  save = () => {
    const model = this.state.model;
    // console.log(JSON.stringify(model));
    if (
      model.emp_razaosocial !== "" &&
      model.emp_endereco !== "" &&
      this.state.selectedOption[0].value !== "" &&
      model.emp_fone !== "" &&
      model.emp_celular !== "" &&
      model.emp_email !== "" &&
      model.emp_nomefantasia !== ""
    ) {
      /*  if (model.emp_senha !== model.emp_senha_repetir) {
        handleError("Senha repetida não coincide com a senha");
        return false;
      }*/
      var emp_lemeliquida, emp_queimaestoque;
      if (document.getElementById("emp_lemeliquida").checked) {
        emp_lemeliquida = 1;
      } else {
        emp_lemeliquida = 0;
      }
      if (document.getElementById("emp_queimaestoque").checked) {
        emp_queimaestoque = 1;
      } else {
        emp_queimaestoque = 0;
      }
      var data_uri = document.querySelectorAll("output span img");
      // console.log(data_uri);

      var empresa_logo = [];
      for (let i = 0; i < data_uri.length; i++) {
        empresa_logo.push(data_uri[i].src);
      }
      let data = {
        emp_id: parseInt(model.emp_id),
        emp_razaosocial: model.emp_razaosocial,
        emp_endereco: model.emp_endereco,
        emp_cidade: this.state.selectedOption[0].value,
        emp_fone: model.emp_fone,
        emp_celular: model.emp_celular,
        emp_cnpj: model.emp_cnpj,
        emp_email: model.emp_email,
        emp_nomefantasia: model.emp_nomefantasia,
        emp_historia: model.emp_historia,
        emp_principaisprodutos: model.emp_principaisprodutos,
        emp_canalyoutube: model.emp_canalyoutube,
        emp_website: model.emp_website,
        emp_twitter: model.emp_twitter,
        emp_instagram: model.emp_instagram,
        emp_imagem400: empresa_logo,
        emp_lemeliquida: emp_lemeliquida,
        emp_queimaestoque: emp_queimaestoque,
        emp_perfilfacebooks: model.emp_perfilfacebooks,
        emp_linkedin: model.emp_linkedin,
      };

      /*   console.log("data eh: " + JSON.stringify(data));
      return false;*/

      api({
        method: "put",
        url: "api/empresa",
        data: data,       
        headers: {
            Authorization: "bearer " + sessionStorage.getItem("token"),
          },
     
      })
        .then((response) => {
          handleSuccess(response.data.message);
          this.get_empresa();
          document.getElementById("prod_foto").value = "";
        })
        .catch((error) => {
          if (error.response) {
            this.catchResponse(error.response.status);
          }
        });
    } else {
      handleError("Preencha todos os campos");
    }
  };
  getCidades = () => {
    api({
      method: "get",
      url: "api/cidade",
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        const statusOptions = [];
        for (let i = 0; i < response.data.length; i++) {
          statusOptions.push({
            value: response.data[i].cid_nome + "/" + response.data[i].cid_uf,
            label: response.data[i].cid_nome + "/" + response.data[i].cid_uf,
          });
        }
        this.setState({ statusOptions });
      })
      .catch((error) => {
        handleError("Erro ao executar");
        console.log(error.response);
        return false;
      });
  };
  componentDidMount = () => {
    this.getCidades();

    this.get_empresa();
  };

  Limpar = () => {
    this.setState({
      model: {
        emp_id: 0,
        emp_senha: "",
        emp_razaosocial: "",
        emp_endereco: "",
        emp_fone: "",
        emp_celular: "",
        emp_cnpj: "",
        emp_email: "",
        emp_nomefantasia: "",
        emp_senha_repetir: "",
        emp_historia: "",
        emp_principaisprodutos: "",
        emp_canalyoutube: "",
        emp_website: "",
        emp_twitter: "",
        emp_instagram: "",
        emp_perfilfacebooks: "",
        emp_linkedin: "",
      },
    });
    if (document.getElementById("emp_lemeliquida")) {
      document.getElementById("emp_lemeliquida").checked = false;
    }
    if (document.getElementById("emp_queimaestoque")) {
      document.getElementById("emp_queimaestoque").checked = false;
    }
    if (document.getElementById("list")) {
      document.getElementById("list").innerHTML = "";
    }
    if (document.getElementById("prod_foto")) {
      document.getElementById("prod_foto").value = "";
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    document.getElementById("list").innerHTML = "";

    var fileInput = document.getElementById("prod_foto");
    var fileList = [];

    if (fileInput.files.length > 1) {
      handleError("Escolha uma unica imagem como logo");
      document.getElementById("prod_foto").value = "";
      return false;
    }
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }

    for (var i = 0, f; (f = fileList[i]); i++) {
      //    this.GerarDataURI(f, i);
     // console.log(JSON.stringify(f.name) + " " + JSON.stringify(fileList[i]));

      // Only process image files.
      if (!f.type.match("image.*")) {
        continue;
      }

      var reader = new FileReader();

      reader.onload = (function (theFile) {
        return function (e) {
          (function () {
            var image = new Image();
            image.addEventListener(
              "load",
              function () {
                // tamanho original

                var canvas = document.createElement("canvas"),
                  ctx = canvas.getContext("2d");
                // coordenadas origem (source)

                // tamanho destino
                var MAX_WIDTH = 400;
                var MAX_HEIGHT = 400;
                var width = this.width;
                var height = this.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(this, 0, 0, width, height);

                let ext = theFile.name.split(".");

                var dataurl = canvas.toDataURL("image/" + ext[ext.length - 1]);
                //  console.log(dataurl);
                var span = document.createElement("span");
                span.innerHTML = [
                  '<img class="img_data_uri" style="max-width: 150px;max-height: 150px;" name="img_data_uris" src="',
                  dataurl,
                  '" title="',
                  escape(theFile.name),
                  '"/>',
                ].join("");
                document.getElementById("list").insertBefore(span, null);
              },
              false
            );
            image.src = e.target.result;
          })();
        };
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  }

  render() {
    const { selectedOption, statusOptions } = this.state;
    //const { selectedGerente } = this.state;
    const { model } = this.state;

    return (
      <>
        <div className="container">
          <div class="form-group row">
            {/* ininio do formulario de registro*/}
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <div class="card text-center">
                <div class="card-header_nav">
                  <h2 className="text-center">Dados da conta</h2>
                </div>
                <div class="card-body">
                  <form>
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label
                          for="emp_razaosocial"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Razao social:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="emp_razaosocial"
                          value={model.emp_razaosocial}
                          placeholder="Nome"
                          onChange={(e) => this.setValues(e, "emp_razaosocial")}
                        />
                      </div>
                      <div class="col-sm-6">
                        <label
                          for="emp_nomefantasia"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Nome fantasia:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="emp_nomefantasia"
                          value={model.emp_nomefantasia}
                          placeholder="Nome fantasia"
                          onChange={(e) =>
                            this.setValues(e, "emp_nomefantasia")
                          }
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-5">
                        <label
                          for="emp_endereco"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Endereço:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="emp_endereco"
                          value={model.emp_endereco}
                          placeholder="Endereço"
                          onChange={(e) => this.setValues(e, "emp_endereco")}
                        />
                      </div>
                      <div class="col-sm-4">
                        <label
                          for="emp_cidade"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Cidade:</b>
                        </label>
                        <Select
                          id="emp_cidade"
                          value={selectedOption}
                          onChange={this.handleChange}
                          options={statusOptions}
                        />
                      </div>
                      <div class="col-sm-3">
                        <label
                          for="emp_fone"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Telefone:</b>
                        </label>

                        <input
                          type="text"
                          maxLength="18"
                          class="form-control"
                          id="emp_fone"
                          value={model.emp_fone}
                          placeholder="Telefone com DDD"
                          onChange={(e) => this.setValues(e, "emp_fone")}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-3">
                        <label
                          for="emp_celular"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Celular:</b>
                        </label>

                        <input
                          type="text"
                          maxLength="19"
                          class="form-control"
                          id="emp_celular"
                          value={model.emp_celular}
                          placeholder="Celular com DDD"
                          onChange={(e) => this.setValues(e, "emp_celular")}
                        />
                      </div>
                      <div class="col-sm-3">
                        <label
                          for="emp_email"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>
                            Email:<b style={{ fontSize: "10px" }}>(Login)</b>
                          </b>
                        </label>

                        <input
                          type="email"
                          class="form-control"
                          id="emp_email"
                          readOnly
                          value={model.emp_email}
                          placeholder="Email"
                          onChange={(e) => this.setValues(e, "emp_email")}
                        />
                      </div>
                      <div class="col-sm-3" style={{ display: "none" }}>
                        <label
                          for="emp_cnpj"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>CNPJ:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="emp_cnpj"
                          value={model.emp_cnpj}
                          placeholder="CNPJ"
                          onChange={(e) => this.setValues(e, "emp_cnpj")}
                        />
                      </div>
                      <div
                        class="input-group col-sm-3"
                        style={{ display: "none" }}
                      >
                        <label
                          for="emp_senha"
                          style={{ width: "100%", color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>
                            Senha:
                            <b style={{ fontSize: "10px" }}>
                              (Max 10 caracteres)
                            </b>
                          </b>
                        </label>

                        <input
                          type="password"
                          class="form-control"
                          id="emp_senha"
                          maxLength="10"
                          value={model.emp_senha}
                          placeholder="Senha"
                          onChange={(e) => this.setValues(e, "emp_senha")}
                        />
                        <div class="input-group-prepend">
                          <div
                            onClick={(e) =>
                              (document.getElementById("emp_senha").type =
                                document.getElementById("emp_senha").type ==
                                "text"
                                  ? "password"
                                  : "text")
                            }
                            class="input-group-text"
                          >
                            <i class="fas fa-eye"></i>
                          </div>
                        </div>
                      </div>
                      <div
                        class="input-group col-sm-3"
                        style={{ display: "none" }}
                      >
                        <label
                          for="emp_senha_repetir"
                          style={{ width: "100%", color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>
                            {" "}
                            Repita:
                            <b style={{ fontSize: "10px" }}>
                              (Max 10 caracteres)
                            </b>
                          </b>
                        </label>

                        <input
                          type="password"
                          class="form-control"
                          id="emp_senha_repetir"
                          maxLength="10"
                          value={model.emp_senha_repetir}
                          placeholder="Repita a sua Senha"
                          onChange={(e) =>
                            this.setValues(e, "emp_senha_repetir")
                          }
                        />
                        <div class="input-group-prepend">
                          <div
                            onClick={(e) =>
                              (document.getElementById(
                                "emp_senha_repetir"
                              ).type =
                                document.getElementById("emp_senha_repetir")
                                  .type == "text"
                                  ? "password"
                                  : "text")
                            }
                            class="input-group-text"
                          >
                            <i class="fas fa-eye"></i>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row mt-3">
                        <div class="col-sm-12">
                          <h5 className="text-center">Campos opcionais</h5>{" "}
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label
                            for="emp_principaisprodutos"
                            style={{ color: "#7c7f82" }}
                            class="col-form-label"
                          >
                            <b>Principais produtos:</b>
                          </label>

                          <input
                            type="text"
                            class="form-control"
                            id="emp_principaisprodutos"
                            value={model.emp_principaisprodutos}
                            placeholder="Exemplo:Churrasqueiras, Lareiras, Fogões a Lenha."
                            onChange={(e) =>
                              this.setValues(e, "emp_principaisprodutos")
                            }
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label
                            for="emp_perfilfacebooks"
                            style={{ color: "#7c7f82" }}
                            class="col-form-label"
                          >
                            <b>Link do perfil do Facebook:</b>
                          </label>

                          <input
                            type="text"
                            class="form-control"
                            id="emp_perfilfacebooks"
                            value={model.emp_perfilfacebooks}
                            placeholder="Link Facebook."
                            onChange={(e) =>
                              this.setValues(e, "emp_perfilfacebooks")
                            }
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label
                            for="emp_canalyoutube"
                            style={{ color: "#7c7f82" }}
                            class="col-form-label"
                          >
                            <b>Link do canal do Youtube:</b>
                          </label>

                          <input
                            type="text"
                            class="form-control"
                            id="emp_canalyoutube"
                            value={model.emp_canalyoutube}
                            placeholder="Link Youtube."
                            onChange={(e) =>
                              this.setValues(e, "emp_canalyoutube")
                            }
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label
                            for="emp_twitter"
                            style={{ color: "#7c7f82" }}
                            class="col-form-label"
                          >
                            <b>Link do perfil do Twitter :</b>
                          </label>

                          <input
                            type="text"
                            class="form-control"
                            id="emp_twitter"
                            value={model.emp_twitter}
                            placeholder="Link Twitter."
                            onChange={(e) => this.setValues(e, "emp_twitter")}
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label
                            for="Instagram"
                            style={{ color: "#7c7f82" }}
                            class="col-form-label"
                          >
                            <b>Link do perfil do Instagram :</b>
                          </label>

                          <input
                            type="text"
                            class="form-control"
                            id="emp_instagram"
                            value={model.emp_instagram}
                            placeholder="Link Instagram."
                            onChange={(e) => this.setValues(e, "emp_instagram")}
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label
                            for="emp_linkedin"
                            style={{ color: "#7c7f82" }}
                            class="col-form-label"
                          >
                            <b>Link do perfil do Linkedin :</b>
                          </label>

                          <input
                            type="text"
                            class="form-control"
                            id="emp_linkedin"
                            value={model.emp_linkedin}
                            placeholder="Link Linkedin."
                            onChange={(e) => this.setValues(e, "emp_linkedin")}
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label
                            for="emp_website"
                            style={{ color: "#7c7f82" }}
                            class="col-form-label"
                          >
                            <b>Link do Website da empresa :</b>
                          </label>

                          <input
                            type="text"
                            class="form-control"
                            id="emp_website"
                            value={model.emp_website}
                            placeholder="Link Website."
                            onChange={(e) => this.setValues(e, "emp_website")}
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <div class="col-sm-12">
                            <h6 className="text-left">
                              <b> Participar das Campanhas?</b>
                            </h6>{" "}
                          </div>
                          <div class="text-left custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="emp_lemeliquida"
                            />
                            <label
                              class="custom-control-label"
                              for="emp_lemeliquida"
                            >
                              Leme Liquida
                            </label>
                          </div>
                          <div class="text-left custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="emp_queimaestoque"
                            />
                            <label
                              class="custom-control-label"
                              for="emp_queimaestoque"
                            >
                              Queima de estoque
                            </label>
                          </div>{" "}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label htmlFor="emp_historia">
                            <b>História:</b>
                          </label>
                          <br />
                          <textarea
                            className="form-control"
                            style={{
                              minWidth: "500px",
                              maxWidth: "100%",
                              minHeight: "50px",
                              height: "100%",
                              width: "100%",
                            }}
                            id="emp_historia"
                            value={this.state.model.emp_historia}
                            placeholder="História da empresa"
                            onChange={(e) => this.setValues(e, "emp_historia")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 mb-3 text-left">
                        <label htmlFor="prod_foto">
                          <b>Logo da empresa:</b>
                        </label>
                        <br />
                        <input
                          type="file"
                          name="prod_foto[]"
                          style={{
                            border: "0px",
                          }}
                          accept=".jpg, .jpeg, .png, .webp"
                          id="prod_foto"
                          onChange={this._handleImageChange}
                        />
                      </div>
                      <img id="output"></img>
                      <output id="list"></output>
                      <hr />
                    </div>{" "}
                    <br />
                    <div class="form-group row mt-2">
                      <div class="col-sm-4"> </div>
                      <div class="col-sm-4">
                        <button
                          type="button"
                          onClick={this.save}
                          class="btn btn-block btn-primary"
                        >
                          Alterar
                        </button>
                      </div>
                      <div class="col-sm-4"> </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer text-muted">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
            <div class="col-sm-1"></div>
          </div>
        </div>
      </>
    );
  }
}
