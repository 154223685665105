import React from "react";
import logo from "../../imagens/keep_delivery_horiz.png";
import { Link } from "react-router-dom";
function Navbar_public() {
  /* CODIGO PARA FECHAR MENU RESPONSIVO AO CLICAR EM UM LINK */
  var el_array = document.getElementsByClassName("link");
  for (var el of el_array) {
    el.addEventListener(
      "click",
      function FecharMenuResponsivo() {
        var element = document.getElementById("navbarSupportedContent");
        element.classList.remove("show");
      },
      false
    );
  }

  return (
    <div>
      <div className="p-2 bg-danger text-white">
        <i className="fab fa-whatsapp"></i> (19) 3573-7080{" "}
        <i className="fa fa-envelope"></i> contato@keepdelivery.com.br
      </div>
      <nav
        className="navbar navbar-expand-lg"
        style={{
          backgroundColor: "#ecf0f4",
          top: "1",
          width: "100%",
          zIndex: "2",
        }}
      >
        <Link className="navbar-brand ml-5" to="/produtos">
          {/*<Link className="navbar-brand ml-5"  to="/login_lojista">*/}
          <img src={logo} alt="logo" style={{ width: "116px" }} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>
            <i className="fas fa-bars" />
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto"></ul>
          <form className="form-inline my-2 my-lg-0">
            <ul className="navbar-nav mr-auto">
              {!localStorage.getItem("nivel") ? (
                <>
                  <Link
                    className="dropdown-item link"
                    style={{ textDecoration: "none", display: "block" }}
                    to="/login_transportadora"
                  >
                    <b>Transportadora</b>&nbsp;
                    <i
                      style={{ color: "#2364c5", fontSize: "16px" }}
                      className="fas fa-truck"
                    ></i>
                  </Link>
                  <Link
                    className="dropdown-item link"
                    style={{ textDecoration: "none" }}
                    to="/login_lojista"
                  >
                    <b>Lojista</b>&nbsp;
                    <i
                      style={{ color: "#2364c5", fontSize: "16px" }}
                      className="fas fa-user-tie"
                    ></i>
                  </Link>
                  <Link
                    className="dropdown-item link"
                    style={{ textDecoration: "none", display: "block" }}
                    to="/login_cliente"
                  >
                    <b>Cliente</b>&nbsp;
                    <i
                      style={{ color: "#2364c5", fontSize: "16px" }}
                      className="fas fa-user"
                    ></i>
                  </Link>
                </>
              ) : (
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    style={{ padding: "0.3rem 1rem" }}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <b>{localStorage.getItem("nome")}</b>&nbsp;
                    <i
                      style={{ color: "#ecf0f4", fontSize: "16px" }}
                      className="fas fa-user-tie"
                    ></i>
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="list-group">
                      {
                        // 2 = cliente
                        localStorage.getItem("nivel") === "2" ? (
                          <>
                            <Link
                              to="/rastreamento"
                              className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                              style={{
                                color: "#000000",
                                textDecoration: "none",
                              }}
                            >
                              <span>
                                Meus pedidos&nbsp;
                                <i  style={{ color: "red", fontSize: "16px" }} className="fa fa-list-alt" aria-hidden="true"></i>
                               
                              </span>
                            </Link>
                            <Link
                              to="/conta"
                              className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                              style={{
                                color: "#000000",
                                textDecoration: "none",
                              }}
                            >
                              <span>
                                Conta&nbsp;
                                <i
                                  style={{ color: "blue", fontSize: "16px" }}
                                  className="fas fa-user-tie"
                                ></i>
                              </span>
                            </Link>

                            <Link
                              className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                              style={{
                                color: "#000000",
                                textDecoration: "none",
                              }}
                              to="/alterarsenha"
                            >
                              Alterar Senha&nbsp;
                              <i
                                style={{ color: "#2364c5", fontSize: "16px" }}
                                className="fas fa-user-edit"
                              ></i>
                            </Link>
                          </>
                        ) : (
                          ""
                        )
                      }
                      {
                        // 0 = lojista
                        localStorage.getItem("nivel") === "0" ? (
                          <>
                            <Link
                              to="sistema/cad_produto"
                              className="list-group-item list-group-item-action font-weight-bold  my-2 my-sm-0"
                              style={{
                                color: "#000000",
                                textDecoration: "none",
                              }}
                            >
                              <span>
                                Produtos&nbsp;
                                <i
                                  style={{ color: "red", fontSize: "16px" }}
                                  className="fas fa-rocket"
                                ></i>
                              </span>
                            </Link>
                            <Link
                              to="sistema/carrinho"
                              className="list-group-item list-group-item-action font-weight-bold  my-2 my-sm-0"
                              style={{
                                color: "#000000",
                                textDecoration: "none",
                              }}
                            >
                              <span>
                                Carrinho&nbsp;
                                <i
                                  style={{ color: "red", fontSize: "16px" }}
                                  className="fas fa-shopping-cart"
                                ></i>
                              </span>
                            </Link>
                            <Link
                              to="sistema/conta"
                              className="list-group-item list-group-item-action font-weight-bold  my-2 my-sm-0"
                              style={{
                                color: "#000000",
                                textDecoration: "none",
                              }}
                            >
                              <span>
                                Conta&nbsp;
                                <i
                                  style={{ color: "blue", fontSize: "16px" }}
                                  className="fas fa-user-tie"
                                ></i>
                              </span>
                            </Link>
                          </>
                        ) : (
                          ""
                        )
                      }
                      <Link
                        to="/logout"
                        className="list-group-item list-group-item-action   font-weight-bold  my-2 my-sm-0"
                        style={{ color: "#000000", textDecoration: "none" }}
                      >
                        <span>
                          Sair&nbsp;
                          <i
                            style={{ color: "purple", fontSize: "16px" }}
                            className="fas fa-sign-out-alt"
                          ></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </li>
              )}
              {
                //Se o carrinho estiver vazio,não sera permitido entrar nele
                localStorage.getItem("carrinho") ? (
                  <Link
                    className="dropdown-item link"
                    style={{ textDecoration: "none", display: "block" }}
                    to="/carrinho"
                  >
                    {" "}
                    <span className="icon-menu">
                      {localStorage.getItem("carrinho")}
                    </span>
                    &nbsp;
                    <i
                      style={{ color: "#11ca17", fontSize: "16px" }}
                      className="fas fa-shopping-cart"
                    ></i>
                  </Link>
                ) : (
                  <Link
                    className="dropdown-item link"
                    style={{ textDecoration: "none", display: "block" }}
                    to="/produtos"
                  >
                    {" "}
                    <span className="icon-menu">0</span>&nbsp;
                    <i
                      style={{ color: "#11ca17", fontSize: "16px" }}
                      className="fas fa-shopping-cart"
                    ></i>
                  </Link>
                )
              }
            </ul>
          </form>
        </div>
      </nav>
    </div>
  );
}
export default Navbar_public;
