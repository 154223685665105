import React, { useState, useEffect } from "react";
import { api, handleError, handleInfo } from "../../plugins/Config/Config";
import { mask_data } from "../../plugins/Mascara/Mascara";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "../../styles/pre_loader.css";
import { useHistory } from "react-router-dom";
import preloader from "../../../imagens/keep_delivery_horiz.png";
const ListarClientes = () => {
  const [clientes, setClientes] = useState([]);
  const [preLoader, setPreLoader] = useState([]);
  let history = useHistory();
  useEffect(() => {
    atualizarGet();
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  const atualizarGet = () => {
    setPreLoader(true);
    api
      .get("api/cliente/listar")
      .then((response) => {
        //console.log('response de cliente é' + JSON.stringify(response));
        // console.log('response: ' + JSON.stringify(response.data));
        setPreLoader(false);
        setClientes(response.data);
      })
      .catch((error) => {
        setPreLoader(false);
        const clientes = [];
        setClientes(clientes);
        handleError("Nenhum lançamento cadastrado");
        return false;
      });
  };

  const status = (id, status) => {
    let data = {
      cli_id: id,
      cli_situacao: status,
      tipo: "cliente",
    };

    api({
      method: "put", //you can set what request you want to be
      url: "api/admin/" + id,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        atualizarGet();
       // console.log(response);
        handleInfo(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  return (
    <>
      <div className="container-fluid">
        {preLoader ? (
          <div style={{ backgroundColor: "#212529" }} id="loading-wrapper">
            <div id="loading-text">
              <picture data-fileentryid="1138056">
                <img
                  style={{
                    width: "300px",
                    alignItems: "center",
                    margin: "0px -30px",
                  }}
                  src={preloader}
                />
              </picture>
            </div>
          </div>
        ) : (
          ""
        )}
        <h2
          className="font-weight-bold text-center"
          style={{ marginTop: "60px" }}
        >
          Lista de clientes
        </h2>

        {clientes.length > 0 ? (
          <div>
            <div className="form-row">
              <div className="col-md-3 ">
                <label id="empurra_export">&nbsp;&nbsp;&nbsp;</label>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn btn-success"
                  table="table-to-xls"
                  filename="Lista-de-clientes"
                  sheet="tablexls"
                  buttonText="Export em XLS"
                />
              </div>
              <div className="col-md-6"> </div>
              <div className="col-md-3"></div>
            </div>
            <div id="customers" className="table-responsive">
              <div className="wrapper">
                <div className="scroll">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <table
                        id="table-to-xls"
                        style={{ marginTop: "30px", marginBottom: "50px" }}
                        className=" table table-sm table-hover table-bordered text-center"
                      >
                        <thead className="thead-dark">
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Nome
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Endereço
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Número
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Complemento
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Bairro
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Cidade
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Contato
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Email
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Data de cadastro
                            </th>
                            <th
                              style={{
                                backgroundColor: "#218838",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientes.map((cliente) => (
                            <tr key={cliente.cli_id}>
                              <td>{cliente.cli_nome}</td>
                              <td>{cliente.cli_endereco}</td>
                              <td>{cliente.cli_numero}</td>
                              <td>{cliente.cli_complemento}</td>
                              <td>{cliente.cli_bairro}</td>
                              <td>{cliente.cli_cidade}</td>
                              <td>{cliente.cli_fone}</td>
                              <td>{cliente.cli_email}</td>
                              <td>{mask_data(cliente.cli_datacadastro)}</td>

                              {cliente.cli_situacao == "A" ? (
                                <td>
                                  <button
                                    className="btn btn-success"
                                    style={{ fontSize: "12px" }}
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Você tem certeza que deseja desativar o cliente "' +
                                            cliente.cli_nome +
                                            '" ?'
                                        )
                                      ) {
                                        status(cliente.cli_id, "I");
                                      }
                                    }}
                                  >
                                    <b>Ativo</b>
                                  </button>
                                </td>
                              ) : (
                                <td>
                                  <button
                                    className="btn btn-secondary"
                                    style={{ fontSize: "12px" }}
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Você tem certeza que deseja ativar o cliente "' +
                                            cliente.cli_nome +
                                            '" ?'
                                        )
                                      ) {
                                        status(cliente.cli_id, "A");
                                      }
                                    }}
                                  >
                                    <b>Inativo</b>
                                  </button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default ListarClientes;
