import React, { Component } from "react";
import { mask_tel_celular_area, mask_CNPJ } from "../plugins/Mascara/Mascara";
import "../styles/Produtos.css";
import { api, handleError, handleSuccess,handleInfo } from "../plugins/Config/Config";
import logo from "../../imagens/keep_delivery_horiz.png";
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery

export default class Empresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      statusOptions: [],
      Empresa: [],
      photoIndex: 0,
      isOpen: false,
      images: {
        titulo: "",
        imagem: "",
        texto: "",
        id: "",
      },
      preloader: false,
      model: {
        emp_id: 0,
        emp_nome: "",
        emp_endereco: "",
        emp_cidade: "",
        emp_fone: "",
        emp_celular: "",
        emp_cnpj: "",
        emp_senha: "",
        emp_email: "",
        emp_nomefantasia: "",
      },
    };
  }

  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };
  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    model.emp_fone = mask_tel_celular_area(model.emp_fone);
    model.emp_celular = mask_tel_celular_area(model.emp_celular);
    model.emp_cnpj = mask_CNPJ(model.emp_cnpj, "on");
    this.setState({ model });
  };
  save = () => {
    const model = this.state.model;

    if (
      model.emp_nome !== "" &&
      model.emp_endereco !== "" &&
      model.emp_cidade !== "" &&
      model.emp_fone !== "" &&
      model.emp_celular !== "" &&
      model.emp_cnpj !== "" &&
      model.emp_email !== "" &&
      model.emp_nomefantasia !== ""
    ) {
      let data = {
        emp_id: parseInt(model.emp_id),
        emp_nome: model.emp_nome,
        emp_endereco: model.emp_endereco,
        emp_cidade: model.emp_cidade,
        emp_fone: model.emp_fone,
        emp_celular: model.emp_celular,
        emp_cnpj: model.emp_cnpj,
        emp_senha: "123",
        emp_email: model.emp_email,
        emp_situacao: "I",
        emp_nomefantasia: model.emp_nomefantasia,
      };

      // console.log('data eh: ' + JSON.stringify(data));

      api({
        method: "post",
        url: "api/interesse",
        data: data,
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          handleSuccess(response.data.message);
          this.Limpar();
          // this.Get_empresa();
        })
        .catch((error) => {
          if (error.response) {
            this.catchResponse(error.response.status);
          }
        });
    } else {
      handleError("Preencha todos os campos");
    }
  };

  componentDidMount = () => {};

  Limpar = () => {
    this.setState({
      model: {
        emp_id: 0,
        emp_nome: "",
        emp_endereco: "",
        emp_cidade: "",
        emp_fone: "",
        emp_celular: "",
        emp_cnpj: "",
        emp_senha: "",
        emp_email: "",
        emp_nomefantasia: "",
      },
    });
  };

  render() {
    const { model } = this.state;
    return (
      <>
        <div className="container">
          <div class="card ">
            <div class="card-header">
              <h1 className="text-center">
                <img style={{ width: "186px" }} src={logo} alt={logo} />
              </h1>
            </div>{" "}
            <div class="card-body">
              <form style={{ marginTop: "30px" }}>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-4 ">
                      <label htmlFor="emp_nome">Razão Social:</label>
                      <input
                        id="emp_nome"
                        className="form-control"
                        required="required"
                        type="text"
                        value={model.emp_nome}
                        placeholder="Nome da Empresa"
                        onChange={(e) => this.setValues(e, "emp_nome")}
                      />
                    </div>
                    <div className="col-md-4 ">
                      <label htmlFor="emp_nomefantasia">Nome Fantasia:</label>
                      <input
                        id="emp_nomefantasia"
                        className="form-control"
                        required="required"
                        type="text"
                        value={model.emp_nomefantasia}
                        placeholder="Nome Fantasia"
                        onChange={(e) => this.setValues(e, "emp_nomefantasia")}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="emp_endereco">Endereço:</label>
                      <input
                        id="emp_endereco"
                        className="form-control"
                        required="required"
                        type="text"
                        value={model.emp_endereco}
                        placeholder="Endereço"
                        onChange={(e) => this.setValues(e, "emp_endereco")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-3">
                      <label htmlFor="emp_cidade">Cidade:</label>
                      <input
                        id="emp_cidade"
                        className="form-control"
                        required="required"
                        type="text"
                        value={model.emp_cidade}
                        placeholder="Cidade"
                        onChange={(e) => this.setValues(e, "emp_cidade")}
                      />
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="emp_fone">Telefone:</label>
                      <input
                        id="emp_fone"
                        className="form-control"
                        maxLength="14"
                        required="required"
                        type="text"
                        value={model.emp_fone}
                        placeholder="Telefone"
                        onChange={(e) => this.setValues(e, "emp_fone")}
                      />
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="emp_celular">Celular:</label>
                      <input
                        id="emp_celular"
                        className="form-control"
                        maxLength="15"
                        required="required"
                        type="text"
                        value={model.emp_celular}
                        placeholder="Celular"
                        onChange={(e) => this.setValues(e, "emp_celular")}
                      />
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="emp_cnpj">CNPJ:</label>
                      <input
                        id="emp_cnpj"
                        className="form-control"
                        maxLength="18"
                        required="required"
                        type="text"
                        value={model.emp_cnpj}
                        placeholder="CNPJ"
                        onChange={(e) => this.setValues(e, "emp_cnpj")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="emp_email">Email:</label>
                      <input
                        id="emp_email"
                        className="form-control"
                        required="required"
                        type="text"
                        value={model.emp_email}
                        placeholder="Email"
                        onChange={(e) => this.setValues(e, "emp_email")}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={this.save}
                  >
                    Cadastrar&nbsp;<i className="fas fa-save"></i>{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ml-3"
                    onClick={this.Limpar}
                  >
                    Limpar Campos&nbsp;<i className="fas fa-trash-alt"></i>{" "}
                  </button>
                </div>
              </form>
            </div>
            <div class="card-footer text-muted text-center ">
              ©2020 Viaconect - All Rights Reserved
            </div>
          </div>
        </div>
      </>
    );
  }
}
