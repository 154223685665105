/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { handleInfo,handleError } from "../../plugins/Config/Config";
import { dominio, api } from "../../plugins/Config/Config";
import Navbar_public from "../../layouts/Navbar_public";
import logo from "../../../imagens/keep_delivery_horiz.png";
import Footer from "../../footer/footer";
class cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo: {
        pagina: "Keep Delevery | Carrinho",
      },
      id: {
        empresa: 0,
        categoria: "0",
        subcategoria: "0",
        nome: "0",
        cliente: 1,
      },
      preco: 0,
      quantidade: 0,
      total: 0,
      list: [],
      organizado: [],
      produtos: [],
      pegar: {
        titulo: "",
        quantidade: "",
      },
      modal: [],
      status: false,
      limpar: false,
      s: "T",
      n: "F",
      url: "",
      texto_button: "Continuar",
      model: {
        obs: "",
      },
      tela: 0,
    };
  }
  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    //localStorage.clear();
    const { id } = this.state;
    let nome = "";
    nome = localStorage.getItem("nome");
    id.nome = nome;

    this.setState({ id });
    this.verifica_tela();
    var organizado = JSON.parse(localStorage.getItem("organizado"));
    var list = JSON.parse(localStorage.getItem("list"));
    var obs = localStorage.getItem("obs");
    if (organizado && organizado.length > 0) {
      const { model } = this.state;
      let total_geral;
      let total_quantidade;
      let total_unitario;
      total_geral = organizado.reduce(
        (total, numero) => total + numero.carp_valortotal,
        0
      );
      total_quantidade = organizado.reduce(
        (total, numero) => total + numero.carp_qtde,
        0
      );
      total_unitario = organizado.reduce(
        (total, numero) => total + numero.carp_valorunitario,
        0
      );
      this.setState({ total: total_geral });
      this.setState({ quantidade: total_quantidade });
      this.setState({ preco: total_unitario });
      this.setState({ organizado: organizado });
      if (obs !== "") {
        model.obs = obs;
        this.setState({ model });
      } else {
        model.obs = "Escreva sua observação";
        this.setState({ model });
      }
    } else {
      this.setState({
        redirect: true,
      });
      /*if (list && list.length > 0) {
        let org = []
        this.setState({ organizado: list })
        let result = []
        // eslint-disable-next-line array-callback-return
        list.filter(function (i) {
          var hasElement = false
          var j
          for (j = 0; j < result.length; j++) {
            if (result[j].carp_produto === i.carp_produto) {
              hasElement = true
              break
            }
          }
          if (hasElement) {
            result[j].carp_qtde += i.carp_qtde
            result[j].carp_varlortotal += i.carp_varlortotal
          } else {
            result.push(i)
          }
        })
        org.push(JSON.stringify(result))
        // this.setState({ produtos: JSON.stringify(result) })
        console.log('org' + org)
        console.log('lsit' + JSON.stringify(list))
        localStorage.setItem('organizado', organizado)
        this.setState({ organizado: org })
      } else {
        this.setState({
          redirect: true
        })
      }*/
    }
  }
  saveListStorage(list) {
    var jsonStr = JSON.stringify(list);
    localStorage.setItem("list", jsonStr);
  }
  /*atualizar = () => {
    const { list, organizado } = this.state
    this.setState({ url: '/carrinho' })
    let result = []
    // eslint-disable-next-line array-callback-return
    list.filter(function (i) {
      var hasElement = false
      var j
      for (j = 0; j < result.length; j++) {
        if (result[j].carp_produto === i.carp_produto) {
          hasElement = true
          break
        }
      }
      if (hasElement) {
        result[j].carp_qtde += i.carp_qtde
        result[j].carp_varlortotal += i.carp_varlortotal
      } else {
        result.push(i)
      }
    })
    organizado.push(JSON.stringify(result))
    localStorage.setItem('organizado', organizado)

  }*/
  somar = () => {
    const { organizado } = this.state;
    let total_geral;
    let total_quantidade;
    let total_unitario;
    total_geral = organizado.reduce(
      (total, numero) => total + numero.carp_valortotal,
      0
    );
    total_quantidade = organizado.reduce(
      (total, numero) => total + numero.carp_qtde,
      0
    );
    total_unitario = organizado.reduce(
      (total, numero) => total + numero.carp_valorunitario,
      0
    );
    this.setState({ total: total_geral });
    this.setState({ quantidade: total_quantidade });
    this.setState({ preco: total_unitario });
  };
  addData(item) {
    const { organizado } = this.state;
    let arrNew = [];
    let total = 0;
    let isUpdate = false;
    organizado.forEach((valor) => {
      if (valor.carp_produto === item.carp_produto) {
        valor.carp_qtde += 1;
        total = valor.carp_valortotal;
        valor.carp_valortotal = item.carp_valorunitario * valor.carp_qtde;
        isUpdate = true;
      }
      arrNew.push(valor);
    });
    if (isUpdate === false) {
      arrNew.push(item);
    }
    this.setState({ organizado: arrNew });
    this.setState({ list: organizado });
    localStorage.setItem("list", JSON.stringify(organizado));
    this.setState({ status: false });
    this.somar();
  }
  removeData = (item) => {
    const { organizado } = this.state;
    let arrNew = [],
      total = 0,
      isUpdate = false;
    if (item.carp_qtde !== 0) {
      organizado.forEach((valor) => {
        if (valor.carp_produto === item.carp_produto) {
          valor.carp_qtde -= 1;
          total = valor.carp_valortotal;
          valor.carp_valortotal = item.carp_valorunitario * valor.carp_qtde;
          isUpdate = true;
        }
        arrNew.push(valor);
      });
      if (isUpdate === false) {
        arrNew.push(item);
      }
      this.setState({ organizado: arrNew });
      this.setState({ list: organizado });
      localStorage.setItem("list", JSON.stringify(organizado));
    } else {
      alert(
        "Tem certeza que deseja remover o produto " +
          item.prod_nome +
          "do carrinho?"
      );
      this.setState({ status: true });
    }
    this.somar();
  };
  prova(item, s, n) {
    const { organizado } = this.state;
    let arrNew = [],
      isUpdate = false;
    organizado.forEach((valor) => {
      if (valor.carp_produto === item.carp_produto) {
        if (s) {
          valor.carp_prova = s;
        } else {
          valor.carp_prova = n;
        }

        isUpdate = true;
      }
      arrNew.push(valor);
    });
    if (isUpdate === false) {
      arrNew.push(item);
    }
    this.setState({ organizado: arrNew });
    this.setState({ list: organizado });
    localStorage.setItem("list", JSON.stringify(organizado));
    this.setState({ status: false });
    this.somar();
  }
  pegar_delete = (item) => {
    const { pegar } = this.state;
    pegar.titulo = item.prod_nome;
    pegar.quantidade = item.carp_qtde;
    this.setState({ pegar });
    this.setState({ modal: item });
  };
  delete = () => {
    const { organizado, modal, pegar } = this.state;
    let array = organizado;
    var result = array.filter(function (el) {
      return el.carp_produto === modal.carp_produto;
    });
    for (var elemento of result) {
      var index = organizado.indexOf(elemento);
      array.splice(index, 1);
    }
    this.setState({ organizado: array });
    this.setState({ list: organizado });
    localStorage.setItem("list", JSON.stringify(organizado));
    localStorage.setItem("organizado", JSON.stringify(organizado));
    this.somar();
    handleInfo("Excluido com sucesso");
    this.atualiza_carrinho();
    if (organizado.length === 0) {
      pegar.titulo = "";
      pegar.quantidade = "";
      this.setState({ pegar });
      this.limpar();
    }
  };
  atualiza_carrinho = () => {
    const { organizado } = this.state;
    let i;
    i = organizado.length;
    localStorage.setItem("carrinho", i);
  };
  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    this.setState({ model });
    localStorage.setItem("obs", this.state.model.obs);
  };
  finalizar = () => {
    const { organizado, model } = this.state;
    var novoArray = [];
    var id = [];
    debugger;
    if (organizado.length === 0) {
      handleInfo("O carrinho está vazio");
    } else {
      for (let index = 0; index < organizado.length; index++) {
        id.push(organizado[index].carp_empresa);
      }
      var values = id.filter(function (a) {
        return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
      }, Object.create(null));
     // console.log(JSON.stringify(values));
      for (let i = 0; i < values.length; i++) {
        novoArray.push(filtrarArray(organizado, values[i]));
      }

    //  console.log("Novo Array" + JSON.stringify(novoArray));

      function filtrarArray(array, car_empresa) {
        return array.filter(function (val) {
        //  console.log(val.carp_empresa === car_empresa);
          return val.carp_empresa === car_empresa;
        });
      }
      //list.unshift({ car_cliente: 1, car_valortotal: '', car_empresa: produtos.prod_empresa })

      var carrinho = [];
      var produtos = [];
      var preco = [];
      let car_email = null;
      var total = 0;
      for (let i = 0; i < novoArray.length; i++) {
        total = 0;
        //descobrir empresa

        carrinho = novoArray[i];
       // console.log("Carrinho" + JSON.stringify(carrinho));
        for (let j = 0; j < carrinho.length; j++) {
          produtos = carrinho[j];
          total = total + produtos.carp_valortotal;
          car_email = produtos.email;
      //    console.log("produtos" + JSON.stringify(produtos));
        }
        preco.push({
          car_cliente: parseInt(produtos.carp_cliente),
          car_valortotal: total,
          car_empresa: produtos.carp_empresa,
          car_email: produtos.email,
          car_texto: model.obs,
        });
      }
     // console.log("Preço total" + JSON.stringify(preco));
      //novoArray.unshift(preco)
     // console.log("Novo Array" + JSON.stringify(novoArray));
      // let preco = novoArray.reduce((preco, valor) => preco + valor.valor, 0)
      let data = {
        carrinho: preco,
        carrinho_produto: novoArray,
      };
    //  console.log("Dados enviados" + JSON.stringify(data));
      api({
        method: "POST", // verbo http
        url: "api/carrinho", // url
        data: JSON.stringify(data),
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          handleInfo("Finalizado com sucesso");
        })
        .catch((error) => {});
      this.setState({ url: "/obrigado" });
      this.setState({
        redirect: true,
      });
    }
  };
  limpar = () => {
    localStorage.removeItem("list");
    localStorage.removeItem("organizado");
    localStorage.removeItem("carrinho");
    localStorage.removeItem("obs");
    this.setState({ url: "/produtos" });
    this.setState({ item: [] });
    this.setState({ organizado: [] });
    this.setState({ list: [] });
    this.setState({ quantidade: 0 });
    this.setState({ total: 0 });
    this.setState({ limpar: true });
    this.setState({ texto_button: "Voltar Comprar" });
    this.setState({ redirect: true });
  };
  verifica_tela = () => {
    if (window.innerWidth < 640 || window.innerHeight < 480) {
      this.setState({ tela: 1 });
    } else if (window.innerWidth < 1024 || window.innerHeightt < 768) {
      this.setState({ tela: 2 });
    } else {
    }
  };
  render() {
    const {
      produtos,
      total,
      quantidade,
      titulo,
      organizado,
      status,
      s,
      n,
      url,
      pegar,
      limpar,
      texto_button,
      tela,
    } = this.state;
    document.title = titulo.pagina;
    if (this.state.redirect) {
      return <Redirect to={url} />;
    } else {
      return (
        <>
          {tela === 1 ? (
            <>
              <div class="p-2 bg-danger text-white">
                <i class="fab fa-whatsapp"></i> (19) 3573-7080{" "}
                <i class="far fa-envelope"></i> contato@keepdelivery.com.br
              </div>
              <nav
                className="navbar navbar-expand-lg"
                style={{
                  backgroundColor: "#ecf0f4 ",
                  top: "1",
                  width: "100%",
                  zIndex: "2",
                }}
              >
                <Link className="navbar-brand ml-5" to="/produtos">
                  {/*<Link className="navbar-brand ml-5"  to="/login_lojista">*/}
                  <img src={logo} alt="logo" style={{ width: "116px" }} />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span>
                    <i className="fas fa-bars" />
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto"></ul>
                  <form className="form-inline my-2 my-lg-0">
                    <ul className="navbar-nav mr-auto">
                      {!localStorage.getItem("nivel") ? (
                        <>
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none", display: "block" }}
                            to="/login_transportadora"
                          >
                            <b>Transportadora</b>&nbsp;
                            <i
                              style={{ color: "#2364c5", fontSize: "16px" }}
                              className="fas fa-truck"
                            ></i>
                          </Link>
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none" }}
                            to="/login_lojista"
                          >
                            <b>Lojista</b>&nbsp;
                            <i
                              style={{ color: "#2364c5", fontSize: "16px" }}
                              className="fas fa-user-tie"
                            ></i>
                          </Link>
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none", display: "block" }}
                            to="/login_cliente"
                          >
                            <b>Cliente</b>&nbsp;
                            <i
                              style={{ color: "#2364c5", fontSize: "16px" }}
                              className="fas fa-user"
                            ></i>
                          </Link>
                        </>
                      ) : (
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            style={{ padding: "0.3rem 1rem" }}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <b>{localStorage.getItem("nome")}</b>&nbsp;
                            <i
                              style={{ color: "#ecf0f4;", fontSize: "16px" }}
                              className="fas fa-user-tie"
                            ></i>
                          </Link>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <div class="list-group">
                              {
                                // 2 = cliente
                                localStorage.getItem("nivel") === "2" ? (
                                  <>
                                    <Link
                                      to="/rastreamento"
                                      className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                                      style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <span>
                                        Meus pedidos&nbsp;
                                        <i
                                          style={{
                                            color: "red",
                                            fontSize: "16px",
                                          }}
                                          class="far fa-list-alt"
                                        ></i>
                                      </span>
                                    </Link>
                                    <Link
                                      to="/conta"
                                      className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                                      style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <span>
                                        Conta&nbsp;
                                        <i
                                          style={{
                                            color: "blue",
                                            fontSize: "16px",
                                          }}
                                          class="fas fa-user-tie"
                                        ></i>
                                      </span>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              {
                                // 0 = lojista
                                localStorage.getItem("nivel") === "0" ? (
                                  <>
                                    <Link
                                      to="sistema/conta"
                                      className="list-group-item list-group-item-action font-weight-bold  my-2 my-sm-0"
                                      style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <span>
                                        Conta&nbsp;
                                        <i
                                          style={{
                                            color: "blue",
                                            fontSize: "16px",
                                          }}
                                          class="fas fa-user-tie"
                                        ></i>
                                      </span>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              <Link
                                to="/logout"
                                className="list-group-item list-group-item-action   font-weight-bold  my-2 my-sm-0"
                                style={{
                                  color: "#000000",
                                  textDecoration: "none",
                                }}
                              >
                                <span>
                                  Sair&nbsp;
                                  <i
                                    style={{
                                      color: "purple",
                                      fontSize: "16px",
                                    }}
                                    class="fas fa-sign-out-alt"
                                  ></i>
                                </span>
                              </Link>
                            </div>
                          </div>
                        </li>
                      )}
                      {
                        //Se o carrinho estiver vazio,não sera permitido entrar nele
                        localStorage.getItem("carrinho") ? (
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none", display: "block" }}
                            to="/carrinho"
                          >
                            {" "}
                            <span className="icon-menu">
                              {localStorage.getItem("carrinho")}
                            </span>
                            &nbsp;
                            <i
                              style={{ color: "#11ca17", fontSize: "16px" }}
                              className="fas fa-shopping-cart"
                            ></i>
                          </Link>
                        ) : (
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none", display: "block" }}
                            to="/produtos"
                          >
                            {" "}
                            <span className="icon-menu">0</span>&nbsp;
                            <i
                              style={{ color: "#11ca17", fontSize: "16px" }}
                              className="fas fa-shopping-cart"
                            ></i>
                          </Link>
                        )
                      }
                    </ul>
                  </form>
                </div>
              </nav>
            </>
          ) : (
            <Navbar_public />
          )}
          <div className="site-section">
            <div className="container">
              <div className="row mb-5">
                <div className="site-blocks-table">
                  <table
                    className="table table-bordered"
                    style={{ fontSize: "12px" }}
                  >
                    <thead>
                      <tr>
                        <th className="product-thumbnail">Imagem</th>
                        <th className="product-name">Produtos</th>
                        <th className="product-price">Preço</th>
                        <th className="product-quantity">Quantidade</th>
                        <th className="product-total">Total</th>
                        <th className="product-remove">Remover</th>
                      </tr>
                    </thead>
                    <tbody>
                      {organizado || organizado.car_produto !== ""
                        ? organizado.map((item) => (
                            <tr key={item.carp_produto}>
                              <td className="product-thumbnail">
                                <img
                                  src={item.prod_caminhofoto}
                                  alt="Image"
                                  className="img-fluid"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                  }}
                                />
                              </td>
                              <td className="product-name">
                                <b className="text-black">{item.prod_nome}</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                R${" "}
                                {parseFloat(item.carp_valorunitario).toFixed(2)}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  className="input-group mb-3"
                                  style={{ maxWidth: "120px" }}
                                >
                                  <div className="input-group-prepend">
                                    {status === true ? (
                                      <button
                                        className="btn btn-outline-primary js-btn-minus"
                                        type="button"
                                        onClick={(e) => this.removeData(item)}
                                        disabled
                                      >
                                        -
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-outline-primary js-btn-minus"
                                        type="button"
                                        onClick={(e) => this.removeData(item)}
                                      >
                                        -
                                      </button>
                                    )}
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control text-center"
                                    value={item.carp_qtde}
                                    placeholder={item.carp_qtde}
                                    aria-label="Example text with button addon"
                                    aria-describedby="button-addon1"
                                  />
                                  <div className="input-group-append">
                                    <Link
                                      className="btn btn-outline-primary js-btn-plus"
                                      type="button"
                                      onClick={(e) => this.addData(item)}
                                    >
                                      +
                                    </Link>
                                  </div>
                                  {item.prova === "T" ? (
                                    <>
                                      <label>Levar para provar</label>
                                      <div class="form-check form-check-inline">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="inlineRadioOptions"
                                          id="inlineRadio1"
                                          onClick={(e) => this.prova(item, s)}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="inlineRadio1"
                                        >
                                          Sim
                                        </label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="inlineRadioOptions"
                                          id="inlineRadio2"
                                          onClick={(e) => this.prova(item, n)}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="inlineRadio2"
                                        >
                                          Não
                                        </label>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                R$ {parseFloat(item.carp_valortotal).toFixed(2)}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  top: "5px",
                                }}
                              >
                                <Link
                                  className="btn btn-primary btn-sm"
                                  data-toggle="modal"
                                  data-target="#deletar"
                                  onClick={(e) => this.pegar_delete(item)}
                                >
                                  X
                                </Link>
                              </td>
                            </tr>
                          ))
                        : "Sem produtos"}
                      <tr>
                        <td className="product-thumbnail"></td>
                        <td className="product-name"></td>
                        <td className="product-price"></td>
                        <td className="product-quantity">
                          <b className="text-black text-uppercase">
                            {quantidade}
                          </b>
                        </td>
                        <td className="product-total">
                          <b className="text-black text-uppercase">
                            Total R$ {parseFloat(total).toFixed(2)}
                          </b>
                        </td>
                        <td className="product-remove">
                          {limpar === false ? (
                            <button
                              className="btn btn-danger btn-sm"
                              data-toggle="modal"
                              data-target="#limpar_carrinho"
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          ) : (
                            <button
                              className="btn btn-danger btn-sm"
                              data-toggle="modal"
                              data-target="#limpar_carrinho"
                              disabled
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row mb-4">
                  <div className="col-md-10 mb-2">
                    <div style={{ width: "auto", fontSize: "9px" }}>
                      <p className="card-text">
                        Lembramos que A Keep Delivery é uma plataforma que une
                        lojistas e consumidores nessa fase de pandemia Covid-19.
                        Toda e qualquer questão em relação a valores, produtos
                        que cliente solicita para prova e entrega dos produtos é
                        uma negociação entre as partes envolvidas, ficando a
                        Keep Delivery ausente nesses detalhes.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <label className="text-black">
                      Observação para o vendedor
                    </label>
                    <textarea
                      className="form-control"
                      row="3"
                      id="obs"
                      placeholder="Escreva aqui uma mensagem para o vendedor"
                      value={this.state.model.obs}
                      onChange={(e) => this.setValues(e, "obs")}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Link
                        to="/produtos"
                        className="btn btn-outline-primary btn-sm btn-block"
                      >
                        {texto_button}{" "}
                        <i className="far fa-hand-point-left"></i>
                        <i className="fas fa-shopping-cart"></i>
                      </Link>
                    </div>
                    <div className="col-md-6">
                      {limpar === false ? (
                        <button
                          className="btn btn-primary btn-sm btn-block"
                          onClick={(e) => this.finalizar(produtos)}
                        >
                          Finalizar
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-sm btn-block"
                          onClick={(e) => this.finalizar(produtos)}
                          disabled
                        >
                          Finalizar
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="deletar"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Tem certeza que Deseja remover
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-md-2">
                      <label>Qtd</label>
                      <p>{pegar.quantidade}</p>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Produto</label>
                      <p>{pegar.titulo}</p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Não
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={(e) => this.delete()}
                  >
                    Sim
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="limpar_carrinho"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Tem certeza que quer esvaziar o carrinho?
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-md-2">
                      <label>Qtd</label>
                      {organizado.map((item) => (
                        <p>{item.carp_qtde}</p>
                      ))}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Produto</label>
                      {organizado.map((item) => (
                        <p>{item.prod_nome}</p>
                      ))}
                    </div>
                  </div>
                </div>
                {limpar === false ? (
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Não
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={(e) => this.limpar()}
                    >
                      Sim
                    </button>
                  </div>
                ) : (
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      disabled
                    >
                      Não
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={(e) => this.limpar()}
                      disabled
                    >
                      Sim
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  }
}
export default cart;
