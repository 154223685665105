import React, { Component } from 'react';
import {dominio, api, handleError, handleSuccess, handleInfo } from '../../plugins/Config/Config';

const ResizeImage = (file) => {
  
  var reader = new FileReader();

  var img = document.createElement("img");
 // var   marca_img = new Image();
  var canvas = document.createElement("canvas");
          reader.onload = (function (theFile) {

              //  console.log(PegarExtensaoDoNome(JSON.stringify(theFile)));
              return function (e) {

                
                   
                            //  marca_img.src =  marca;
                              img.src = e.target.result;
              
                         
                             //var canvas = $("<canvas>", {"id":"testing"})[0];
                             var ctx = canvas.getContext("2d");
                             ctx.drawImage(img, 0, 0);
                 
                             var MAX_WIDTH = 200;
                             var MAX_HEIGHT = 200;
                             var width = img.width;
                             var height = img.height;
                 
                             if (width > height) {
                                 if (width > MAX_WIDTH) {
                                     height *= MAX_WIDTH / width;
                                     width = MAX_WIDTH;
                                 }
                             } else {
                                 if (height > MAX_HEIGHT) {
                                     width *= MAX_HEIGHT / height;
                                     height = MAX_HEIGHT;
                                 }
                             }
                             canvas.width = width;
                             canvas.height = height;
                             var ctx = canvas.getContext("2d");
                             let marca_width = (width/2);
                             let marca_height = (height/2);
                             ctx.drawImage(img, 0, 0, width, height);
                            // ctx.drawImage(marca_img, marca_width,marca_height, 50, 50);
              
                                          

              };
          })(file);

          // Read in the image file as a data URL.
          reader.readAsDataURL(file);
}

class Cadastrar_solicitacao extends Component {

  constructor(props) {
    super(props)
    this.state = {
      grupo: {
        pc_id: 0,
        pc_descricao: '',
        pc_status: '',
        pc_imagem:'',
        pc_descricao_alterar: '',
        pcs_descricao: '',
        pcs_descricao_alterar: ''
      },
      grupos: [],
      subgrupos: [],
      objetivos: [],
      modal: [],
    }
  }

  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };
  // função para escrever nos inputs e ja associando ao state
  setValues = (e, field) => {
    const { grupo } = this.state;
    grupo[field] = e.target.value;
    this.setState({ grupo });

  }



alterar = (grupo_soli) => {
 
  
  this.setState({ grupo: { pc_descricao: grupo_soli.pc_descricao,pc_id: grupo_soli.pc_id,pc_imagem: grupo_soli.pc_imagem,pc_status: grupo_soli.pc_status } })
  if(grupo_soli.pc_imagem){
  var span = '';
  span +='<img class="thumb-upload"  src='+dominio+'/imagens/categoria/'+grupo_soli.pc_id+'/'+grupo_soli.pc_imagem+' />';
  document.getElementById('list').innerHTML = span;    
}
}


  //função que recebe os dados do accodian para preencher o state modal que tem o intuito de preencher os modal
  ModalDados = (grupo_soli, tipo) => {

    this.setState({ modal: { pc_id: grupo_soli.pc_id, pc_descricao: grupo_soli.pc_descricao, pc_status: grupo_soli.pc_status, titulo: tipo, pcs_descricao: grupo_soli.pcs_descricao, pcs_id: grupo_soli.pcs_id, pcs_produtocategoria: grupo_soli.pcs_produtocategoria, ssg_status: grupo_soli.ssg_status } })


  }

  // função para cadastrar grupo
  save = (tipo) => {
    const { grupo } = this.state;
   var method,pc_status;
    if(grupo.pc_id > 0){
      method = 'put';
          pc_status = grupo.pc_status;
   }else{
     method = 'post';
    pc_status = 'A';
   }


   
    if (tipo == 'sub') {
      document.getElementById("submodal_fechar").click();

      var url = 'api/subcategoria';
      const { modal } = this.state;
   
      if (grupo.pcs_descricao !== '') {
    
        var data = {
          pcs_produtocategoria: modal.pc_id,
          pcs_descricao: grupo.pcs_descricao,
                   
        };
       
      }
    } else {
    
      let data_uri = document.getElementsByClassName('alterar_img')[0];
      if(data_uri){
        data_uri = data_uri.src;
      }else{
        data_uri = null;
      }
      var url = 'api/categoria';
      if (grupo.pc_descricao !== '') {

        var data = {
          pc_id: grupo.pc_id,
          pc_descricao: grupo.pc_descricao,
          pc_status: pc_status,
          pc_imagem: data_uri
          

        };
      }
    }

    if (grupo.pc_descricao == '' && grupo.pcs_descricao == '') {
      handleError('Preencha os campos');
      return false;
    }

    
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then(response => {
        handleSuccess(response.data.message);
        document.getElementById('list').innerHTML = '';
        document.getElementById('prod_foto').value = '';
        
        this.setState({ modal: { sg_id: '', pc_descricao: '', pc_status: '', titulo: '', pcs_descricao_alterar: '', pcs_descricao: '', pcs_id: '', pcs_produtocategoria: '' } })
        this.setState({ grupo: { pc_descricao: '', pc_status: '', sg_id: 0, pc_descricao_alterar: '', pcs_descricao: '', pcs_descricao_alterar: '' } })

        this.atualizar_acordian();

      })
      .catch((error) => {
        if (error.response) {
          this.catchResponse(error.response.status);
        }
      });


  }

  //função para atualizar o accordian caso haja alguma mudança no state grupos
  atualizar_acordian() {
    api.get('api/categoria')
      .then((response) => {
        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data != '') {
          this.setState({ grupos: response.data })
        }
      })
      .catch((error) => {
        console.log(error)
      })

    api.get('api/subcategoria')
      .then((response) => {
        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data != '') {
          this.setState({ subgrupos: response.data })
        }
      })
      .catch((error) => {
        console.log(error)
      })

  }
  // função referente aos botões ativar e desativar em modal grupo e subgrupo
  status = (id, status, tipo) => {
    const { modal } = this.state;
    if (tipo == 'sub') {
      var url = 'api/subcategoria';
      var data = {
        pcs_id: id,
        pcs_descricao: modal.pcs_descricao,
        pcs_produtocategoria: modal.pcs_produtocategoria,


      };
      this.setState({ modal: { ssg_status: status } })
      document.getElementById("subeditmodal_fechar").click();
    } else {
      var url = 'api/categoria';
      var data = {
        pc_id: id,
        pc_descricao: modal.pc_descricao,
        pc_status: status,

      };
      this.setState({ modal: { pc_status: status } })
      document.getElementById("fechargrupo").click();
    }


    api({
      method: 'put',
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then(response => {
        handleSuccess(response.data.message);
        this.atualizar_acordian();
      })
      .catch((error) => {
        if (error.response) {
          this.catchResponse(error.response.status);
        }
      });
  }
  // função para alterar o nome do grupo
  alterarGrupo = (id, tipo) => {
    const { grupo } = this.state;
    const { modal } = this.state;
    if (tipo == 'sub') {

      if (grupo.pcs_descricao_alterar !== '') {
        document.getElementById("subeditmodal_fechar").click();
        var url = 'api/subcategoria';
        var data = {
          pcs_id: id,
          pcs_descricao: grupo.pcs_descricao_alterar,
          pcs_produtocategoria: modal.pcs_produtocategoria,

        };
      } else {
        handleError('Preencha o novo nome da Subcategoria');
        return false
      }
    }
    this.setState({ modal: { pc_id: '', pc_descricao: '', pc_status: '', titulo: '', pcs_descricao_alterar: '', pcs_descricao: '', pcs_id: '', pcs_produtocategoria: '' } })
    this.setState({ grupo: { pc_descricao: '', pc_status: '', pc_id: 0, pc_descricao_alterar: '', pcs_descricao: '', pcs_descricao_alterar: '' } })

    api({
      method: 'put',
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then(response => {
        handleSuccess(response.data.message);
        this.atualizar_acordian();
      })
      .catch((error) => {
        console.log(error)
        handleError(error.response.data.message);
        return false;
      });

  }

  // função para deletar o grupo
  DeletarGrupo = (id, status, tipo) => {
    if (tipo == 'sub') {
      var url = 'api/subcategoria';
    } else {
      var url = 'api/categoria';
    }

    api({
      method: 'DELETE', //you can set what request you want to be
      url: url + id + '/' + status,
      data: id,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then(rows => {
        //  const grupos = this.state.grupos.filter(grupo_soli => grupo_soli.sg_id !== sg_id);
        //  this.setState({ grupos });
        this.atualizar_acordian();
        if(status === 'A'){
          handleInfo('Categoria ativada com sucesso');
        }else{
          handleInfo('Categoria desativada com sucesso');
        }
      

      })
      .catch(error => {

        handleError(error.response.data.message);
        console.log(error.response);
      })
  }


  // função que executa sempre que a pagina é carregada
  componentDidMount = () => {
    this.atualizar_acordian();
  }

  //  função para formar o primeiro acordian do lado esquerdo
  metadeInferior = (value) => {

    const { grupos } = this.state;
    var metade = parseInt(grupos.length / 2);
    // return value >= 10;
    var index = grupos.indexOf(value);
    if (index > -1) {
      if (index < metade) {

        return value;
      }

    }
  }
  //  função para formar o segundo acordian do lado direito
  metadeSuperior = (value) => {

    const { grupos } = this.state;
    var metade = parseInt(grupos.length / 2);
    // return value >= 10;
    var index = grupos.indexOf(value);
    if (index > -1) {

      if (index >= metade && index <= grupos.length) {
        return value;
      }

    }
  }

  _handleImageChange(e) {



        e.preventDefault();

     
        document.getElementById('list').innerHTML = '';

        var fileInput = document.getElementById('prod_foto');
        var fileList = [];


        if (fileInput.files.length > 5) {
            handleError('No máximo 5 imagem');
            document.getElementById('prod_foto').value = '';
            return false
        }
        for (var i = 0; i < fileInput.files.length; i++) {
            fileList.push(fileInput.files[i]);
        }
   
 

    for (var i = 0, f; f = fileList[i]; i++) {

         //    this.GerarDataURI(f, i);
          //  console.log(JSON.stringify(f.name)+' '+JSON.stringify(fileList[i]));
          
            // Only process image files.
            if (!f.type.match('image.*')) {
                continue;
            }

            ResizeImage(f);
   
            var reader = new FileReader();

            // Closure to capture the file information.
      

            var img = document.createElement("img");
         //   var   marca_img = new Image();
            var canvas = document.createElement("canvas");
            reader.onload = (function (theFile) {

                //  console.log(PegarExtensaoDoNome(JSON.stringify(theFile)));
                return function (e) {

                 //   img.src = e.target.result;
             
                  
                           //  marca_img.src =  marca;
                             img.src = e.target.result;
            
                        
                            //var canvas = $("<canvas>", {"id":"testing"})[0];
                            var ctx = canvas.getContext("2d");
                            ctx.drawImage(img, 0, 0);
                
                            var MAX_WIDTH = 200;
                            var MAX_HEIGHT = 200;
                            var width = img.width;
                            var height = img.height;
                
                            if (width > height) {
                                if (width > MAX_WIDTH) {
                                    height *= MAX_WIDTH / width;
                                    width = MAX_WIDTH;
                                }
                            } else {
                                if (height > MAX_HEIGHT) {
                                    width *= MAX_HEIGHT / height;
                                    height = MAX_HEIGHT;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;
                            var ctx = canvas.getContext("2d");
                            let marca_width = (width/2);
                            let marca_height = (height/2);
                            ctx.drawImage(img, 0, 0, width, height);
                            //ctx.drawImage(marca_img, marca_width,marca_height, 50, 50);

 
                           
                            
                          //  var ext = theFile.name.substring(theFile.name.length-4,theFile.name.length);
                            let ext = theFile.name.split('.');
                           

                            var dataurl = canvas.toDataURL("image/"+ext[ext.length-1]);
                            //document.getElementById('output').src = dataurl;                    
            
                            // Render thumbnail.    
            

                            var span = document.createElement('span');
                            span.innerHTML = ['<img class="thumb-upload alterar_img" src="', dataurl,
                                '" title="',escape(theFile.name), '"/>'].join('');
                            document.getElementById('list').insertBefore(span, null);
                            
                   
            


                };
            })(f);

            // Read in the image file as a data URL.
            reader.readAsDataURL(f);

        }




    }
  render() {
    const { subgrupos } = this.state;
    const { grupos } = this.state;
    const { modal } = this.state;
    return (
      <div className="container" >
        {//modal referente ao cadastro de novos subgrupos de solicitação
        }
        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark" id="exampleModalLongTitle"><b>Cadastrar Subcategoria</b></h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form>
                <div className="modal-body">

                  <div className="form-row">
                    <legend style={{ fontSize: '14px', marginBottom: '20px' }}>Cadastre o nome da subcategoria<b>{" "+modal.pc_descricao}</b></legend>
                    <div className="form-group col-md-12">
                      <label htmlFor="pcs_descricao"><b>Nome de subcategoria:</b></label>
                      <input type="email" className="form-control" id="pcs_descricao" onChange={e => this.setValues(e, 'pcs_descricao')} value={this.state.modal.pcs_descricao} placeholder="" />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={e => this.save('sub')} className="btn btn-primary">Salvar</button>
                  <button type="button" className="btn btn-secondary" id='submodal_fechar' data-dismiss="modal">Fechar</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {//modal referente ao alterar de novos subgrupos de solicitação
        }
        <div className="modal fade" id="editarSubGrupo" tabIndex="-1" role="dialog" aria-labelledby="editarSubGrupoTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark" id="exampleModalLongTitle"><b>Editar Subcategoria</b></h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form>
                <div className="modal-body">

                  <div className="form-row">
                    <legend style={{ fontSize: '14px', marginBottom: '20px' }}>Alterar o nome da Subcategoria para um novo nome desejado</legend>
                    <div className="form-group col-md-12">
                      <label htmlFor="grupo"><b>{modal.titulo} Selecionado:</b></label>
                      <input type="text" className="form-control" id="grupo" readOnly value={modal.pcs_descricao} />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="pcs_descricao_alterar"><b>Novo Nome:</b></label>
                      <input type="text" className="form-control" onChange={e => this.setValues(e, 'pcs_descricao_alterar')} value={this.state.grupo.pcs_descricao_alterar} id="pcs_descricao_alterar" placeholder="" />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {modal.ssg_status == 'A'
                    ? <button type="button" onClick={e => this.status(modal.pcs_id, 'I', 'sub')} className="btn btn-danger">Desativar</button>
                    : <button type="button" onClick={e => this.status(modal.pcs_id, 'A', 'sub')} className="btn btn-success">Ativar</button>
                  }
                  <button type="button" onClick={e => this.alterarGrupo(modal.pcs_id, 'sub')} className="btn btn-primary">Salvar</button>
                  <button type="button" className="btn btn-secondary" id='subeditmodal_fechar' data-dismiss="modal">Fechar</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        
        <h2 className="font-weight-bold text-center" style={{ marginTop: '30px' }}> Cadastrao de Categoria </h2>
        <form style={{ marginTop: '30px', marginBottom: '30px' }}>
          <legend style={{ fontSize: '14px', marginBottom: '20px' }}>Cadastre os tipos de Categoria ,E pelo botão de <i style={{ color: '#007bff' }} className="fas fa-plus-circle"></i> adicione Subgrupos de solicitações referente ao tipo desejado de solicitação
:</legend>

          <div className="form-row">

            <div className="col-md-4 ">
              <label htmlFor="pc_descricao">Nome:</label>
              <input id="pc_descricao" className='form-control' required="required" type="text" value={this.state.grupo.pc_descricao} placeholder="Nome da Grupo..."
                onChange={e => this.setValues(e, 'pc_descricao')} />
            </div>
            <div className="col-md-4">
              <label htmlFor="prod_foto"><b>Imagens:</b></label>
              <br />
              <input type="file" name='prod_foto[]' style={{ backgroundColor: '#ecf0f4', border: '0px' }} accept="image/*" id='prod_foto'  onChange={this._handleImageChange} />
            </div>
            <div className="col-md-3">
            <button type='button' title="Cadastrar" onClick={e => this.save('grupo')} className='btn btn-primary' style={{ marginTop: '16%' }}  >Cadastrar <i className="fas fa-save"></i></button>
            </div>
          </div>


        </form>
        <output id="list"></output>
          
        <div className='row'>
          <div className="col-md-6">
            <div id="accordion" className="myaccordion">

              {
                grupos.filter(this.metadeInferior).map(grupo_soli => (
                  <div key={grupo_soli.pc_id} className="card shadow-lg bg-white rounded">
                    <div className="card-header" id="One">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed " style={{ color: '#000000' }} data-toggle="collapse" data-target={'#collapse' + grupo_soli.pc_id} aria-expanded="false" aria-controls={'collapse' + grupo_soli.pc_id}>
                          {grupo_soli.pc_descricao}
                        </button>
                        {grupo_soli.pc_status === 'A'
                       ? <span style={{ color: '#007bff' }} onClick={(e) => this.ModalDados(grupo_soli, 'Grupo')} data-toggle="modal" data-target="#exampleModalCenter" className="fa-stack fa-sm">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                        </span>
                       : <span style={{ color: '#6c757d' }} onClick={(e) => this.ModalDados(grupo_soli, 'Grupo')} data-toggle="modal" data-target="#exampleModalCenter" className="fa-stack fa-sm">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                        </span>
                       }

                        {/*grupo_soli.pc_status == 'A'
                            ?<span style={{ color: '#007bff' }}  onClick={(e) => this.ModalDados(grupo_soli, 'Grupo')}  data-toggle="modal" data-target="#exampleModalCenter" className="fa-stack fa-sm">
                            <i className="fas fa-circle fa-stack-2x"></i>
                            <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                            </span>
                           : <span style={{ color: '#6c757d' }}  onClick={(e) => this.ModalDados(grupo_soli, 'Grupo')}  data-toggle="modal" data-target="#exampleModalCenter" className="fa-stack fa-sm">
                           <i className="fas fa-circle fa-stack-2x"></i>
                           <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                         </span>
                */ }


                      </h2>
                    </div>
                    <div id={'collapse' + grupo_soli.pc_id} className="collapse" aria-labelledby="One" data-parent="#accordion">
                      <div className="card-body">
                        <ul>
                          <li className='row' style={{ backgroundColor: '#dcdcdc', borderRadius: '5px' }}>
                            <div className="col-md-9 col-9  "><b>{grupo_soli.pc_descricao}</b></div>
                            <div className="col-md-3 col-2  " style={{ maxWidth: '4.66667%' }}>
                         

                              {
                            grupo_soli.pc_status == 'A'
                            ?  <button type='button' title="Editar" className='btn btn_style btn-primary' onClick={(e) => this.alterar(grupo_soli)} data-toggle="modal" data-target="#editarGrupo" style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                <i className="fas fa-edit"></i>
                              </button>
                             :<button type='button' title="Editar" className='btn btn_style btn-secondary' onClick={(e) => this.alterar(grupo_soli)} data-toggle="modal" data-target="#editarGrupo" style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                             <i className="fas fa-edit"></i>
                           </button>
                           }
                            </div>
                            <div className="col-md-3 col-1  " style={{ maxWidth: '6.66667%' }}>
                             {grupo_soli.pc_status === "A"
                             ?
                             <>
                              <button type='button' title="Deletar" onClick={(e) => { if (window.confirm('Voce tem certeza que deseja desativar a categoria ' + grupo_soli.pc_descricao + ' ?')) this.DeletarGrupo(grupo_soli.pc_id,'I', 'grupo') }} className='btn btn_style btn-danger' style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                <i className="far fa-trash-alt"></i>
                              </button>
                             </>
                           :
                           <>
                           <button type='button' title="Deletar" onClick={(e) => { if (window.confirm('Voce tem certeza que deseja ativar a categoria ' + grupo_soli.pc_descricao + ' ?')) this.DeletarGrupo(grupo_soli.pc_id,'A', 'grupo') }} className='btn btn_style btn-danger' style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                <i className="far fa-trash-alt"></i>
                              </button>
                              </>
                             }
                            </div>

                          </li>
                          {
                            subgrupos.map(function (cell, i) {
                              const { grupos } = this.state;
                              if (cell.pcs_produtocategoria == grupo_soli.pc_id) {
                                return <li className='row'>
                                  <div className="col-md-9 col-9  ">{cell.pcs_descricao} </div>
                                  <div className="col-md-3 col-2  " style={{ maxWidth: '4.66667%' }}>
                                  
                                    {
                                     grupo_soli.pc_status == 'A'
                                       ?<button type='button' title="Editar" className='btn btn_style  btn-primary' onClick={(e) => this.ModalDados(cell)}  data-toggle="modal" data-target="#editarSubGrupo" style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                       <i className="fas fa-edit"></i>
                                     </button>
                                     :<button type='button' title="Editar" className='btn btn_style  btn-secondary' onClick={(e) => this.ModalDados(cell)}  data-toggle="modal" data-target="#editarSubGrupo" style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                     <i className="fas fa-edit"></i>
                                   </button>
                                      }
                                  </div>
                                  {/*
                                    <div className="col-md-3 col-1  " style={{ maxWidth: '6.66667%' }}>
                                      <button type='button' title="Deletar" className='btn btn_style btn-danger' onClick={(e) => { if (window.confirm('Voce tem certeza que deseja deletar o grupo ' + cell.pcs_descricao + ' ?')) this.DeletarGrupo(cell.pcs_id,'sub') }} style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                        <i className="far fa-trash-alt"></i>
                                      </button>
                                    </div>
                                    */ }
                                </li>
                              }


                            }.bind(this))
                          }


                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>


          <div className="col-md-6">
            <div id="accordion1" className="myaccordion">
              {
                grupos.filter(this.metadeSuperior).map(grupo_soli => (
                  <div key={grupo_soli.pc_id} className="card shadow-lg bg-white rounded">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" style={{ color: '#000000' }} data-toggle="collapse" data-target={'#collapse' + grupo_soli.pc_id} aria-expanded="false" aria-controls={'collapse' + grupo_soli.pc_id}>
                          {grupo_soli.pc_descricao}

                        </button>
                        {grupo_soli.pc_status === 'A'
                       ? <span style={{ color: '#007bff' }} onClick={(e) => this.ModalDados(grupo_soli, 'Grupo')} data-toggle="modal" data-target="#exampleModalCenter" className="fa-stack fa-sm">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                        </span>
                       : <span style={{ color: '#6c757d' }} onClick={(e) => this.ModalDados(grupo_soli, 'Grupo')} data-toggle="modal" data-target="#exampleModalCenter" className="fa-stack fa-sm">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                        </span>
                       }
                        {/*
                        grupo_soli.pc_status == 'A'
                            ?<span style={{ color: '#007bff' }}  onClick={(e) => this.ModalDados(grupo_soli, 'Grupo')}  data-toggle="modal" data-target="#exampleModalCenter" className="fa-stack fa-sm">
                            <i className="fas fa-circle fa-stack-2x"></i>
                            <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                            </span>
                           : <span style={{ color: '#6c757d' }}  onClick={(e) => this.ModalDados(grupo_soli, 'Grupo')}  data-toggle="modal" data-target="#exampleModalCenter" className="fa-stack fa-sm">
                           <i className="fas fa-circle fa-stack-2x"></i>
                           <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                         </span>
                */}
                      </h2>
                    </div>
                    <div id={'collapse' + grupo_soli.pc_id} className="collapse" aria-labelledby="headingOne" data-parent="#accordion1">
                      <div className="card-body">
                        <ul>
                          <li className='row' style={{ backgroundColor: '#dcdcdc', borderRadius: '5px' }}>
                            <div className="col-md-9 col-9  "><b>{grupo_soli.pc_descricao}</b></div>
                            <div className="col-md-3 col-2  " style={{ maxWidth: '4.66667%' }}>
                             
                            {
                            grupo_soli.pc_status == 'A'
                            ?  <button type='button' title="Editar" className='btn btn_style btn-primary' onClick={(e) => this.alterar(grupo_soli)} data-toggle="modal" data-target="#editarGrupo" style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                <i className="fas fa-edit"></i>
                              </button>
                             :<button type='button' title="Editar" className='btn btn_style btn-secondary' onClick={(e) => this.alterar(grupo_soli)} data-toggle="modal" data-target="#editarGrupo" style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                             <i className="fas fa-edit"></i>
                           </button>
                           }
                            </div>

                            <div className="col-md-3 col-1  " style={{ maxWidth: '6.66667%' }}>
                            {grupo_soli.pc_status === "A"
                             ?
                             <>
                              <button type='button' title="Deletar" onClick={(e) => { if (window.confirm('Voce tem certeza que deseja desativar a categoria ' + grupo_soli.pc_descricao + ' ?')) this.DeletarGrupo(grupo_soli.pc_id,'I', 'grupo') }} className='btn btn_style btn-danger' style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                <i className="far fa-trash-alt"></i>
                              </button>
                             </>
                           :
                           <>
                           <button type='button' title="Deletar" onClick={(e) => { if (window.confirm('Voce tem certeza que deseja ativar a categoria ' + grupo_soli.pc_descricao + ' ?')) this.DeletarGrupo(grupo_soli.pc_id,'A', 'grupo') }} className='btn btn_style btn-danger' style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                <i className="far fa-trash-alt"></i>
                              </button>
                              </>
                             }
                            </div>

                          </li>
                          {
                            subgrupos.map(function (cell, i) {

                              if (cell.pcs_produtocategoria == grupo_soli.pc_id) {
                                return <li className='row'>
                                  <div className="col-md-9 col-9  ">{cell.pcs_descricao} </div>
                                  <div className="col-md-3 col-2  " style={{ maxWidth: '4.66667%' }}>
                               
                                    {
                                    
                                     grupo_soli.pc_status == 'A'
                                       ?<button type='button' title="Editar" className='btn btn_style  btn-primary' onClick={(e) => this.ModalDados(cell)}  data-toggle="modal" data-target="#editarSubGrupo" style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                       <i className="fas fa-edit"></i>
                                     </button>
                                     :<button type='button' title="Editar" className='btn btn_style  btn-secondary' onClick={(e) => this.ModalDados(cell)}  data-toggle="modal" data-target="#editarSubGrupo" style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                     <i className="fas fa-edit"></i>
                                   </button>
                                     }

                                  </div>
                                  {/*
                                    <div className="col-md-3 col-1  " style={{ maxWidth: '6.66667%' }}>
                                      <button type='button' title="Deletar" className='btn btn_style btn-danger' onClick={(e) => { if (window.confirm('Voce tem certeza que deseja deletar o grupo ' + cell.pcs_descricao + ' ?')) this.DeletarGrupo(cell.pcs_id,'sub') }} style={{ color: 'white', fontSize: '12px', width: '20px', textAlign: 'center' }}>
                                        <i className="far fa-trash-alt"></i>
                                      </button>
                                    </div>
                                    */ }
                                </li>
                              }


                            }.bind(this))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Cadastrar_solicitacao;