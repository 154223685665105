import React, { Component } from "react";
import { Link } from "react-router-dom";
import { dominio, api } from "../../plugins/Config/Config";

import no_imagem from "../../../imagens/sem_imagem.jpg";

import obrigado from "../obrigado/obrigado";
class categorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icons: {
        favicon: "../../favicon/icons.ico",
      },
      titulo: {
        pagina: "Keepdelivery | Produtos",
      },
      labels: {
        h1: "Encontre Sapatos Perfeitos",
        h2: {
          uppercase: "Frete Grátis",
          uppercase2: "Devoluções Grátuitas",
          uppercase3: "Suporte ao Cliente",
          categoria: "Categorias",
          destaques: "Os mais vendidos",
          bigsale: "Grande Promoção",
        },
        h3: {
          categoria: {
            titulo0: "Mulheres",
            titulo1: "Crianças",
            titulo2: "Homens",
          },
        },

        destaques: [
          {
            titulo: "Encontrar a camiseta perfeita",
            preco: "R$ 50,00",
            imagem: "",
          },
          { titulo: "Encontrar a camiseta perfeita", preco0: "R$ 50,00" },
          { titulo: "Encontrar o sapato perfeito", preco0: "R$ 50,00" },
          { titulo: "Encontrar camisetas polos perfeito", preco0: "R$ 50,00" },
          { titulo: "Encontrar camisetas animadas", preco0: "R$ 50,00" },
          { titulo: "Encontrar o sapato perfeito", preco0: "R$ 50,00" },
        ],
        p: {
          banner:
            "Confortável e estiloso, para você começar o dia com pé direito",

          bigsale: {
            validade: "Até 25 de Setembro de 2020",
            desconto: "50%",
            texto: "em todos os itens",
            descricao:
              "Não perca essa promoção incrivel, todos intens de inverno pela metade do preço, tudo em até 12 x no cartão! Não perca",
          },
        },
        a: {
          destaques: {
            titulo0: "Tanlk Top",
            titulo1: "Corater",
            titulo2: "Camisa Polo",
            titulo3: "Camisa Star",
            titulo4: "Corater",
          },
          bigsale: {
            btn0: "Comprar Agora",
          },
        },
      },
      span: {
        caterogoria: {
          collections0: "Coleção Inverno",
          collections1: "Coleção Kids",
          collections2: "Coleção Masculina",
        },
      },
      imagens: {
        backgroundImage: "url(images/hero_1.jpg)",
        categoria: {
          img0: "images/women.jpg",
          img1: "images/children.jpg",
          img2: "images/men.jpg",
        },
        destaques: {
          img0: "images/cloth_1.jpg",
          img1: "images/shoe_1.jpg",
          img2: "images/cloth_2.jpg",
          img3: "images/cloth_3.jpg",
          img4: "images/shoe_1.jpg",
        },
      },
      categoria: [],
      subcategoria: [],
      id: {
        empresa: 0,
        categoria: "0",
        subcategoria: "0",
      },
      filtro: [
        {
          id: "2",
          descricao: "Nome, A a Z",
          preco: "",
          tamanho: "M",
          cor: "Verde",
          class_color: "bg-success color d-inline-block rounded-circle mr-2",
          quantidade: "100",
        },
        {
          id: "3",
          descricao: "Nome, Z a A",
          preco: "",
          tamanho: "G",
          cor: "Azul",
          class_color: "bg-primary color d-inline-block rounded-circle mr-2",
          quantidade: "100",
        },
        {
          id: "4",
          descricao: "Preço, Menor p/ Maior",
          preco: "",
          tamanho: "PP",
          cor: "Roxo",
          class_color: "bg-info color d-inline-block rounded-circle mr-2",
          quantidade: "100",
        },
        {
          id: "5",
          descricao: "Preço, Maior p/ Menor",
          preco: "",
          tamanho: "GG",
          cor: "Amarelo",
          class_color: "bg-warning color d-inline-block rounded-circle mr-2",
          quantidade: "100",
        },
        {
          id: "6",
          descricao: "Todos",
          preco: "Todos",
          tamanho: "Todos",
          cor: "Todas",
        },
      ],
      produtos: [],
      mais_vendidos: [
        {
          id: "0",
          titulo: "Tank Top",
          descricao: "Camisas perfeitas",
          valor: "R$ 50,00",
          imagem: "images/cloth_1.jpg",
          oferta: "10",
          slide: [{ frente: "", atras: "", esquerda: "", direita: "" }],
        },
        {
          id: "1",
          titulo: "Corater",
          descricao: "Sapatos Perfeitos",
          valor: "R$ 50,00",
          imagem: "images/shoe_1.jpg",
          oferta: "10",
          slide: [
            {
              frente: "images/shoe_1.jpg",
              atras: "images/shoe_1.jpg",
              esquerda: "images/shoe_1.jpg",
              direita: "images/shoe_1.jpg",
            },
          ],
        },
        {
          id: "2",
          titulo: "Camisa Polo",
          descricao: "Camisa Polo Perfeita",
          valor: "R$ 50,00",
          imagem: "images/cloth_2.jpg",
          oferta: "10",
          slide: [
            {
              frente: "images/cloth_2.jpg",
              atras: "images/cloth_2.jpg",
              esquerda: "images/cloth_2.jpg",
              direita: "images/cloth_2.jpg",
            },
          ],
        },
        {
          id: "3",
          titulo: "Camisa Star",
          descricao: "Camisas com desenho",
          valor: "R$ 50,00",
          imagem: "images/cloth_3.jpg",
          oferta: "10",
          slide: [
            {
              frente: "images/cloth_2.jpg",
              atras: "images/cloth_2.jpg",
              esquerda: "images/cloth_2.jpg",
              direita: "images/cloth_2.jpg",
            },
          ],
        },
        {
          id: "4",
          titulo: "Corater",
          descricao: "Sapatos perfeitos",
          valor: "R$ 50,00",
          imagem: "images/shoe_1.jpg",
          oferta: "10",
          slide: [
            {
              frente: "images/shoe_1.jpg",
              atras: "images/shoe_1.jpg",
              esquerda: "images/shoe_1.jpg",
              direita: "images/shoe_1.jpg",
            },
          ],
        },
        {
          id: "5",
          titulo: "Tank Top",
          descricao: "Camisas Perfeitas",
          valor: "R$ 50,00",
          imagem: "images/cloth_1.jpg",
          oferta: "10",
          slide: [
            {
              frente: "images/cloth_1.jpg",
              atras: "images/cloth_1.jpg",
              esquerda: "images/cloth_1.jpg",
              direita: "images/cloth_1.jpg",
            },
          ],
        },
      ],
    };
  }
  componentDidMount() {
    localStorage.setItem("username", "admin");
    localStorage.setItem("password", "admin");
    localStorage.setItem("produto", 0);
    this.verifica();
  }
  verifica = () => {
    const { id } = this.state;
    const { cat } = this.props.match.params;
    if (cat != null) {
      id.categoria = cat;
      this.setState({ id });
      this.AtualizarGet();
      this.Categorias();
    } else {
      this.AtualizarGet();
      this.Categorias();
    }
  };
  AtualizarGet() {
    const { empresa, categoria, subcategoria } = this.state.id;
    let url = "api/produto/" + empresa + "/" + categoria + "/" + subcategoria;

    api
      .get(url)
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        this.setState({ produtos: response.data });
      })
      .catch((error) => {});
  }
  Categorias() {
    const { id } = this.state;
    api
      .get("api/categoria/" + id.empresa + "")
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        this.setState({ categoria: response.data });
      })
      .catch((error) => {});
  }
  SubCategorias(item) {
    const { id, titulo } = this.state;
    titulo.pagina = "keepdelivery | " + item.pc_descricao;
    this.setState({ titulo });
    api
      .get("api/subcategoria/" + id.empresa + "/")
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        this.setState({ subcategoria: response.data });
      })
      .catch((error) => {});
  }
  setValues = (e, field) => {};
  todas_categorias = () => {
    const { id, titulo } = this.state;
    titulo.pagina = "keepdelivery | Produtos";
    id.categoria = "0";
    id.subcategoria = "0";
    this.setState({ id });
    this.AtualizarGet();
  };
  filtro_categoria = (item) => {
    const { id, titulo } = this.state;
    titulo.pagina = "keepdelivery | " + item.pc_descricao;
    id.categoria = item.pc_id;
    this.setState({ id });
    this.setState({ titulo });
    this.AtualizarGet();
    this.SubCategorias(item);
  };
  filtro_sub_categoria = (item) => {
    const { id, titulo } = this.state;
    titulo.pagina = "keepdelivery | " + item.pcs_descricao;
    id.subcategoria = item.pcs_id;
    this.setState({ id });
    this.setState({ titulo });
    this.AtualizarGet();
  };
  filtro = (item) => {
    //produtos é o array com todos os produtos.
    const { produtos, titulo } = this.state;
    if (item.descricao === "Preço, Maior p/ Menor") {
      produtos.sort(function (a, b) {
        return b.prod_preco - a.prod_preco;
      });
    }
    if (item.descricao === "Preço, Menor p/ Maior") {
      produtos.sort(function (a, b) {
        return a.prod_preco - b.prod_preco;
      });
    }
    if (item.descricao === "Nome, A a Z") {
      produtos.sort(function (a, b) {
        return a.prod_preco < b.prod_preco
          ? -1
          : a.prod_preco > b.prod_preco
          ? 1
          : 0;
      });
    }
    if (item.descricao === "Nome, Z a A") {
      produtos.sort(function (a, b) {
        return b.prod_preco < a.prod_preco
          ? -1
          : b.prod_preco > a.prod_preco
          ? 1
          : 0;
      });
    }
    if (item.descricao === "Relevancia") {
      produtos.sort();
    }

    titulo.pagina = "keepdelivery | " + item.descricao;
    this.setState({ titulo });
    /*console.log(
      'Tipo de ordenação:' +
        ' ' +
        item.descricao +
        ' ' +
        JSON.stringify(produtos)
    )*/
  };
  filtrar_preco = (item) => {
    //produtos é o array com todos os produtos.
    const { produtos } = this.state;
    let max = 4000,
      min = 1000;
    return produtos.filter((a) => a.prod_preco > min && a.prod_preco < max);
  };
  render() {
    const { titulo, produtos, categoria, filtro } = this.state;
    document.title = titulo.pagina;
    return (
      <>
        <div className="site-section">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-9 order-2">
                <div className="row">
                  <div className="col-md-12 mb-5">
                    <div className="float-md-left mb-4">
                      <h2 className="text-black h5">
                        Todos os produtos - {localStorage.getItem("mensagem")}
                      </h2>
                    </div>
                    <div className="d-flex">
                      <div className="dropdown mr-1 ml-md-auto">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm dropdown-toggle"
                          id="dropdownMenuOffset"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Recentes
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuOffset"
                        >
                          {categoria.map((item) => (
                            <Link
                              className="dropdown-item"
                              to="#"
                              key={item.id}
                            >
                              {item.titulo}
                            </Link>
                          ))}
                        </div>
                      </div>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm dropdown-toggle"
                          id="dropdownMenuReference"
                          data-toggle="dropdown"
                        >
                          Filtro
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuReference"
                        >
                          {filtro.map((item) => (
                            <Link
                              key={item.id}
                              className="dropdown-item"
                              to="#"
                              onClick={(e) => this.filtro(item)}
                            >
                              {item.descricao}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  {produtos.length > 0
                    ? produtos.map(function (item, i) {
                        if (item.prod_caminhofoto) {
                          var prod_caminhofoto = item.prod_caminhofoto.split(
                            ","
                          );
                          var src =
                            dominio +
                            "/imagens/" +
                            item.prod_id +
                            "/" +
                            prod_caminhofoto[0];
                        } else {
                          src = no_imagem;
                        }
                        return (
                          <div
                            className="col-sm-6 col-lg-4 mb-4"
                            data-aos="fade-up"
                          >
                            <div
                              className="block-4 text-center border"
                              key={item.prod_id}
                            >
                              <figure className="block-4-image" alt="figure">
                                <Link to={"/produto" + item.prod_id}>
                                  <img
                                    src={src}
                                    alt={src}
                                    className="img-fluid"
                                  />
                                </Link>
                              </figure>
                              <div className="block-4-text p-4">
                                <h3>
                                  <Link to={"/produto" + item.prod_id}>
                                    {item.prod_nome}
                                  </Link>
                                </h3>
                                <p className="mb-0">R$ {item.prod_preco}</p>
                                <p className="text-primary font-weight-bold">
                                  Empresa: {item.emp_nomefantasia}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : "Sem produtos"}
                </div>
                {/*<div className='row' data-aos='fade-up'>
                <div className='col-md-12 text-center'>
                  <div className='site-block-27'>
                    <ul>
                      <li>
                        <Link to='#'>&lt;</Link>
                      </li>
                      <li className='active'>
                        <span>1</span>
                      </li>
                      <li>
                        <Link to='#'>2</Link>
                      </li>
                      <li>
                        <Link to='#'>3</Link>
                      </li>
                      <li>
                        <Link to='#'>4</Link>
                      </li>
                      <li>
                        <Link to='#'>5</Link>
                      </li>
                      <li>
                        <Link to='#'>&gt;</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                  </div>*/}
              </div>

              <div className="col-md-3 order-1 mb-5 mb-md-0">
                <div className="border p-4 rounded mb-4">
                  <h3 className="mb-3 h6 text-uppercase text-black d-block">
                    Categorias
                  </h3>

                  <ul className="list-unstyled mb-0">
                    <li className="mb-1">
                      <Link
                        to="/"
                        className="d-flex"
                        onClick={(e) => this.todas_categorias()}
                      >
                        <span>Todas</span>{" "}
                        <span className="text-black ml-auto"></span>
                      </Link>
                    </li>
                    {categoria.map((item) => (
                      <li className="mb-1" key={item.pc_id}>
                        <Link
                          to="#"
                          className="d-flex"
                          onClick={(e) => this.filtro_categoria(item)}
                        >
                          <span>{item.pc_descricao}</span>{" "}
                          <span className="text-black ml-auto">
                            {item.quantidade}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                {/*  <div className='border p-4 rounded mb-4'>
                <div className='mb-4'>
                  <h3 className='mb-3 h6 text-uppercase text-black d-block'>
                    Filtrar por Preço
                  </h3>
                  <div id='slider-range' className='border-primary'></div>
                  <input
                    type='text'
                    name='text'
                    id='amount'
                    //value={this.state.filtro.preco}
                    className='form-control border-0 pl-0 bg-white'
                    disabled=''
                  />
                  button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={e => this.filtrar_preco()}
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </button>*
              </div>

              <div className='mb-4'>
                <h3 className='mb-3 h6 text-uppercase text-black d-block'>
                  Sub Categorias
                  </h3>
                {subcategoria.length > 0 ? (
                  subcategoria.map(item => (
                    <label for='s_sm' className='d-flex' key={item.pc_id}>
                      <input
                        type='checkbox'
                        id='s_sm'
                        className='mr-2 mt-1'
                        onClick={e => this.filtro_sub_categoria(item)}
                      />{' '}
                      <Link to='#' className='d-flex'>
                        <span className='text-black'>
                          {' '}
                          {item.pcs_descricao} ({item.quantidade})
                          </span>
                      </Link>
                    </label>
                  ))
                ) : (
                    <p>Sem Subcategoria</p>
                  )}
              </div>

              {/*<div className='mb-4'>
                  <h3 className='mb-3 h6 text-uppercase text-black d-block'>
                    Cor
                  </h3>
                  {filtro.map(item => (
                    <Link
                      to='#'
                      className='d-flex color-item align-items-center'
                    >
                      <span className={item.class_color}></span>{' '}
                      <span className='text-black'>
                        {item.cor} ({item.quantidade})
                      </span>
                    </Link>
                  ))}
                </div>
              </div>*/}
              </div>
            </div>
            <div className="site-section block-3 site-blocks-2">
              <div className="container">
                {/*<div className='row justify-content-center'>
              <div className='col-md-7 site-section-heading text-center pt-4'>
                <h2>Os Mais vendidos</h2>
              </div>
            </div>*/}
                {/*<div className='row'>
              <div className='col-md-12'>
                <div className='nonloop-block-3 owl-carousel'>
                  {mais_vendidos.map(item => (
                    <div className='item'>
                      <div className='block-4 text-center'>
                        <figure className='block-4-image'>
                          <img
                            src={item.imagem}
                            alt={item.imagem}
                            className='img-fluid'
                          />
                        </figure>
                        <div className='block-4-text p-4'>
                          <h3>
                            <Link to={`/produto${item.id}`}>{item.titulo}</Link>
                          </h3>
                          <p className='mb-0'>{item.descricao}</p>
                          <p className='text-primary font-weight-bold'>
                            {item.valor}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
                  </div>*/}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default categorias;
