import React, { Component } from "react";
import { TablePagination } from "@material-ui/core";
import { Link } from "react-router-dom";
import no_imagem from "../../../imagens/sem_imagem.jpg";
import Navbar_public from "../../layouts/Navbar_public";
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import banner1 from "../../../imagens/slide/banner1.jpg";
import banner2 from "../../../imagens/slide/banner2.jpg";
import banner3 from "../../../imagens/slide/banner3.jpg";
import banner4 from "../../../imagens/slide/banner4.jpg";
//import banner5 from "../../../imagens/slide/banner5.jpg";
//import banner6 from "../../../imagens/slide/banner6.jpg";
import img_leme_liquida from "../../../imagens/leme_liquida.jpg";
import { dominio, api, handleInfo } from "../../plugins/Config/Config";
import logo from "../../../imagens/keep_delivery_horiz.png";
import Footer from "../../../components/footer/footer";
import LazyLoad from "react-lazyload";
//import './botaoflutuante.css'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 9,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 779 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 778, min: 0 },
    items: 4,
  },
};

class produtos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icons: {
        favicon: "../../favicon/icons.ico",
      },
      titulo: {
        pagina: "Keepdelivery | Produtos",
      },
      slideLazyLoad: true,
      empresa: [],
      categoria: [],
      subcategoria: [],
      subcategoria_filter: [],
      tela: 0,
      pesquisar_produto: "",
      texto_de_pesquisa: "Todos os produtos",
      id: {
        empresa: "0",
        categoria: "0",
        subcategoria: "0",
        nome: "",
      },
      filtro: [
        {
          id: "2",
          descricao: "Nome, A a Z",
        },
        {
          id: "3",
          descricao: "Nome, Z a A",
        },
        {
          id: "4",
          descricao: "Preço, Menor p/ Maior",
        },
        {
          id: "5",
          descricao: "Preço, Maior p/ Menor",
        },
        {
          id: "6",
          descricao: "Promoção",
        },
        {
          id: "7",
          descricao: "Todos",
        },
      ],
      produtos: [],
      cidades: [],
      currentPage: 1,
      todosPerPage: 30,
      arrayFiltrado:[],
      produtosPag:[],    
      lastID:0,
      totalPages:0,
      page:0,
      rowsPerPage:25,
    };
 
  }


 handleChangePage = async (event, newPage) => {
  const { rowsPerPage,produtos } = this.state;
       this.setState({
      page: newPage
    });
    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
       "rowsPerPage:" +
         rowsPerPage +
         " newPage " +
         newPage +
         " " +
         inicio +
         " ---- " +
         fim
     );*/
    if (fim > produtos.length) {
      fim = produtos.length;
    }
    const produtosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      produtosPag.push(produtos[i]);
    }
    this.setState({
      produtosPag: produtosPag
    });
  
  };

   handleChangeRowsPerPage = (event) => {
    const { produtos } = this.state;
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10)
    });    
    this.setState({
      page: 0
    });

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > produtos.length) {
      fim = produtos.length;
    }
    const produtosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      produtosPag.push(produtos[i]);
    }   
    this.setState({
      produtosPag: produtosPag
    });
  };


  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem("username", "admin");
    localStorage.setItem("password", "admin");
    const { id } = this.state;
    let nome = "";
    nome = localStorage.getItem("nome");
    id.nome = nome;

    let url_string = window.location.href;
    let url = new URL(url_string);
    let emp_id = url.searchParams.get("emp_id");
    let leme_liquida = url.searchParams.get("leme_liquida");
    this.setState({ id });
    if (emp_id) {
      this.filtro_empresas_get(emp_id);
    } else {
      if (leme_liquida == 0 || leme_liquida == 1) {
        let url = "api/produto/0/0/0/0/0/" + leme_liquida + "/3";
        this.AtualizarGetDestaque(url);
      } else {
        this.AtualizarGetDestaque();
      }
    }
    this.Categorias();
    this.SubCategorias();
    this.Empresas_produtos();
    this.verifica_tela();
    this.getCidades();

    setTimeout(this.atrasarTempoSlide, 1000 * 4);
  }
  atrasarTempoSlide = () => {
    this.setState({ slideLazyLoad: false });
  };
  filtro_empresas_get = (emp_id) => {
    const { id, titulo } = this.state;

    if (emp_id) {
      id.categoria = "0";
      id.subcategoria = "0";
      id.empresa = emp_id;
    }
    this.setState({ id });

    this.AtualizarGet();
  };

  AtualizarGetDestaque(url) {
    const { empresa, categoria, subcategoria } = this.state.id;
    if (!url) {
      var url = "api/produto/0/0/0/0/0/0/3";
      var lemeliquida_texto = false;
    } else {
      var lemeliquida_texto = true;
    }

    api
      .get(url)
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        //   console.log(JSON.stringify(response.data));
      //  this.setState({ currentPage: 1 });
        this.setState({ produtos: response.data });



     
       this.setState({ totalPages: response.data.length });
       
      
        const produtosPag = [];
        if (response.data.length > 0) {
          var inicio = 0;
          var fim = this.state.rowsPerPage;

          if (fim > response.data.length) {
            fim = response.data.length;
          }

          for (let i = inicio; i < fim; i++) {
            produtosPag.push(response.data[i]);
          }
          this.setState({ produtosPag: produtosPag });
        }else{
          this.setState({ produtos: [] });  
          this.setState({ produtosPag: [] });
        }

        if (lemeliquida_texto) {
          this.setState({ texto_de_pesquisa: "Leme Liquida" });
        } else {
          this.setState({ texto_de_pesquisa: "Produtos em destaque" });
        }
      })
      .catch((error) => {});
  }

  AtualizarGet = async () => {
    let { empresa, categoria, subcategoria, nome } = this.state.id;
    categoria = parseInt(categoria);
    empresa = parseInt(empresa);
    subcategoria = parseInt(subcategoria);
    if (categoria > 0) {
      empresa = 0;
    }
    if (subcategoria > 0) {
      empresa = 0;
    }
    // console.log("antes do if categoria " + JSON.stringify(this.state.id));
    /*   if (categoria == "0") {
     this.setState({
        id: {
          empresa: "0",
          categoria: "0",
          subcategoria: "0",
          nome: nome,
        },
      });
      subcategoria = 0;
    }*/
    // console.log("depois do if categoria " + JSON.stringify(this.state.id));
    let url = "api/produto/" + empresa + "/" + categoria + "/" + subcategoria;

    api
      .get(url)
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        if (response.data.status == "erro") {
          //  console.log(respoWnse.data.status );
          const { id } = this.state;

          id.categoria = "0";
          id.subcategoria = "0";
          id.empresa = "0";

          this.setState({ id });
          window.location.assign("https://keepdelivery.com.br");
        }
    
        this.setState({ produtos: response.data });
        this.setState({ totalPages: response.data.length });
       
      
        const produtosPag = [];
        if (response.data.length > 0) {
          var inicio = 0;
          var fim = this.state.rowsPerPage;

          if (fim > response.data.length) {
            fim = response.data.length;
          }

          for (let i = inicio; i < fim; i++) {
            produtosPag.push(response.data[i]);
          }
          this.setState({ produtosPag: produtosPag });
        }else{
          this.setState({ produtos: [] });  
          this.setState({ produtosPag: [] });
        }

        if (subcategoria > 0) {
          this.setState({
            texto_de_pesquisa:
              response.data[0].pc_descricao +
              " / " +
              response.data[0].pcs_descricao,
          });
        } else if (categoria > 0) {
          this.setState({ texto_de_pesquisa: response.data[0].pc_descricao });
        } else if (empresa > 0) {
          this.setState({
            texto_de_pesquisa: response.data[0].emp_nomefantasia,
          });
        } else if (empresa == 0) {
          this.setState({
            texto_de_pesquisa: "Todos os produtos",
          });
        }
      })
      .catch((error) => {});
  };
  Categorias() {
    const { id } = this.state;
    api
      .get("api/categoria/" + id.empresa + "")
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        this.setState({ categoria: response.data });
      })
      .catch((error) => {});
  }
  SubCategorias(item) {
    const { id } = this.state;
    //titulo.pagina = 'Keepdelivery | ' + item.pcs_descricao
    api
      .get("api/subcategoria/" + id.empresa + "/" + id.subcategoria + "")
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        this.setState({ subcategoria: response.data });
      })
      .catch((error) => {});
  }
  Empresas_produtos(item) {
    const { titulo } = this.state;
    if (!item) {
      titulo.pagina = "Keepdelivery | Produtos";
    } else {
      titulo.pagina = item.emp_nomefantasia;
    }
    api
      .get("api/empresa/1")
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        this.setState({ empresa: response.data });
      })
      .catch((error) => {});
  }
  getCidades = () => {
    api
      .get("api/cidade")
      .then((response) => {
        this.setState({ cidades: response.data });
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
  };
  todas_categorias = () => {
    const { id, titulo } = this.state;
    titulo.pagina = "Keepdelivery | Todos Produtos";
    id.categoria = "0";
    id.subcategoria = "0";
    this.setState({ id });
    this.AtualizarGet();
  };
  filtro_categoria = (item) => {
    const { id, titulo } = this.state;
    titulo.pagina = "Keepdelivery | " + item.pc_descricao;

    id.categoria = item.pc_id;
    id.pc_id = item.pcs_produtocategoria;
    this.setState({ id });
    this.setState({ titulo });
    this.AtualizarGet();
    this.SubCategorias(item);
    id.categoria = "0";
    id.subcategoria = "0";
    this.setState({ id });
  };
  filtro_sub_cat = (item) => {
    const { subcategoria, id } = this.state;
    this.setState({ color: "text-primary" });
    if (!item) {
      id.categoria = "0";
      this.setState({ id });
      this.setState({ subcategoria_filter: subcategoria });
      this.AtualizarGet();
      id.categoria = "0";
      id.subcategoria = "0";
      this.setState({ id });
    } else {
      id.categoria = item.pc_id;
      this.setState({ id });
      let subcategoria_filter = subcategoria.filter(
        (a) => a.pcs_produtocategoria === item.pc_id
      );
      this.setState({ subcategoria_filter });
      this.AtualizarGet();
      id.categoria = "0";
      id.subcategoria = "0";
      this.setState({ id });
    }
  };
  filtro_sub_categoria = (item) => {
    const { id, titulo } = this.state;
    titulo.pagina = "Keepdelivery | " + item.pcs_descricao;
    id.subcategoria = item.pcs_id;
    this.setState({ id });
    this.setState({ titulo });
    this.AtualizarGet();
    id.categoria = "0";
    id.subcategoria = "0";
    this.setState({ id });
  };
  filtro_empresa = (item, empresa) => {
    const { id, titulo } = this.state;
   // console.log("item " + JSON.stringify(item));
    if (item) {
      id.categoria = "0";
      id.subcategoria = "0";
      if (!item) {
        id.empresa = item;
        titulo.pagina = "Keepdelivery | Todas as empresas";
      } else {
        id.empresa = item.emp_id;
        titulo.pagina = "Keepdelivery | " + item.emp_nomefantasia;
      }
    } else {
      if (empresa) {
        id.categoria = "0";
        id.subcategoria = "0";
        if (!empresa) {
          id.empresa = empresa;
          titulo.pagina = "Keepdelivery | Todas as empresas";
        } else {
          id.empresa = empresa.emp_id;
          titulo.pagina = "Keepdelivery | " + empresa.emp_nomefantasia;
        }
      }
    }
    this.setState({ id });
    this.AtualizarGet();
  };
  reset_filtro = () => {
    const { id } = this.state;
    id.empresa = "0";
    id.categoria = "0";
    id.subcategoria = "0";
    this.AtualizarGet();
  };
  filtro = (item) => {
    //produtos é o array com todos os produtos.
    if (!item) {
      return false;
    }
    const { produtos, titulo,rowsPerPage } = this.state;
    if (produtos.length >= 0) {
      if (item.descricao === "Preço, Maior p/ Menor") {
        produtos.sort(function (a, b) {
          return b.prod_preco - a.prod_preco;
        });
      }
      if (item.descricao === "Preço, Menor p/ Maior") {
        produtos.sort(function (a, b) {
          return a.prod_preco - b.prod_preco;
        });
      }
      if (item.descricao === "Promoção") {
        produtos.sort(function (a, b) {
          return a.prod_preco < b.prod_promocao;
        });
      }
      if (item.descricao === "Nome, A a Z") {
        produtos.sort(function (a, b) {
          return a.prod_nome < b.prod_nome
            ? -1
            : a.prod_nome > b.prod_nome
            ? 1
            : 0;
        });
      }
      if (item.descricao === "Nome, Z a A") {
        produtos.sort(function (a, b) {
          return b.prod_nome < a.prod_nome
            ? -1
            : b.prod_nome > a.prod_nome
            ? 1
            : 0;
        });
      }

      titulo.pagina = "Keepdelivery | " + item.descricao;
    } else {
      titulo.pagina = "Keepdelivery | Sem produtos para sua pesquisa";
    }
    if (item.descricao === "Todos") {
      this.todas_categorias();
      this.setState({ subcategoria_filter: [] });
      this.reset_filtro();
    }
    this.setState({ titulo });
    var inicio = 0 * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
       "rowsPerPage:" +
         rowsPerPage +
         " newPage " +
         newPage +
         " " +
         inicio +
         " ---- " +
         fim
     );*/
    if (fim > produtos.length) {
      fim = produtos.length;
    }
    const produtosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      produtosPag.push(produtos[i]);
    }
    this.setState({
      produtosPag: produtosPag
    });
    this.setState({ page: 0 });
  };
  filtrar_preco = (item) => {
    //produtos é o array com todos os produtos.
    const { produtos } = this.state;
    let max = 4000,
      min = 1000;
    return produtos.filter((a) => a.prod_preco > min && a.prod_preco < max);
  };
  verifica_tela = () => {
    if (window.innerWidth < 640 || window.innerHeight < 480) {
      this.setState({ tela: 1 });
    } else if (window.innerWidth < 1024 || window.innerHeightt < 768) {
      this.setState({ tela: 0 });
    } else {
    }
  };
  filtro_cidade = (item) => {
    api
      .get("api/produto/parametro_5/" + item.cid_nome)
      .then((response) => {
        this.setState({ produtos: response.data });
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
  };

  removeAcento = (text) => {
    text = text.toLowerCase();
    text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
    text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
    text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
    text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
    text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
    text = text.replace(new RegExp("[Ç]", "gi"), "c");
    return text;
  };
  pesquisarProduto = (termo) => {
    termo = this.removeAcento(termo);
   
    api
      .get("api/pesquisar/" + termo)
      .then((response) => {
        //response.data[0].prod_caminhofoto.split(',');
        if (response.data.length > 0) {
          const { rowsPerPage } = this.state;
          this.setState({ produtos: response.data });
          this.setState({ totalPages: response.data.length });
          this.setState({ texto_de_pesquisa: "Termo pesquisado: " + termo });
          var inicio = 0 * rowsPerPage + 1;
          var fim = inicio + (rowsPerPage - 1);
            console.log(
             "rowsPerPage:" +
               rowsPerPage +
               " newPage " +
               0 +
               " " +
               inicio +
               " ---- " +
               fim
           );
          if (fim > response.data.length) {
            fim = response.data.length;
          }
          const produtosPag = [];
          for (let i = inicio - 1; i < fim; i++) {
            produtosPag.push(response.data[i]);
          }
          this.setState({
            produtosPag: produtosPag
          });
          this.setState({ page: 0 });
        } else {
          handleInfo("Nenhum produto foi encontrado!");
          return false;
        }
      })
      .catch((error) => {});
  };

  render() {
    const {
      pesquisar_produto,
      titulo,
      produtos,
      categoria,
      filtro,
      tela,
      cidades,
      empresa,
      currentPage,
      todosPerPage,
      slideLazyLoad,
      produtosPag,
       rowsPerPage,
       arrayFiltrado,
       totalPages,     
       page,
    } = this.state;
  
    document.title = titulo.pagina;
    let texto_de_pesquisa = this.state.texto_de_pesquisa;
    // Logic for displaying current todos


    return (
      <>
        {tela === 1 ? (
          <>
            <div className="p-2 bg-danger text-white">
              <i className="fab fa-whatsapp"></i> (19) 3573-7080{" "}
              <i className="far fa-envelope"></i> contato@keepdelivery.com.br
            </div>
            <nav
              className="navbar navbar-expand-lg"
              style={{
                backgroundColor: "#ecf0f4 ",
                top: "1",
                width: "100%",
                zIndex: "2",
              }}
            >
              <Link className="navbar-brand ml-5" to="/produtos">
                {/*<Link className="navbar-brand ml-5"  to="/login_lojista">*/}
                <img src={logo} alt="logo" style={{ width: "116px" }} />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span>
                  <i className="fas fa-bars" />
                </span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto"></ul>
                <form className="form-inline my-2 my-lg-0">
                  <ul className="navbar-nav mr-auto">
                    {!localStorage.getItem("nivel") ? (
                      <>
                        <Link
                          className="dropdown-item link"
                          style={{ textDecoration: "none", display: "block" }}
                          to="/login_transportadora"
                        >
                          <b>Transportadora</b>&nbsp;
                          <i
                            style={{ color: "#2364c5", fontSize: "16px" }}
                            className="fas fa-truck"
                          ></i>
                        </Link>
                        <Link
                          className="dropdown-item link"
                          style={{ textDecoration: "none" }}
                          to="/login_lojista"
                        >
                          <b>Lojista</b>&nbsp;
                          <i
                            style={{ color: "#2364c5", fontSize: "16px" }}
                            className="fas fa-user-tie"
                          ></i>
                        </Link>
                        <Link
                          className="dropdown-item link"
                          style={{ textDecoration: "none", display: "block" }}
                          to="/login_cliente"
                        >
                          <b>Cliente</b>&nbsp;
                          <i
                            style={{ color: "#2364c5", fontSize: "16px" }}
                            className="fas fa-user"
                          ></i>
                        </Link>
                      </>
                    ) : (
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="#"
                          style={{ padding: "0.3rem 1rem" }}
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <b>{localStorage.getItem("nome")}</b>&nbsp;
                          <i
                            style={{ color: "#ecf0f4", fontSize: "16px" }}
                            className="fas fa-user-tie"
                          ></i>
                        </Link>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <div className="list-group">
                            {
                              // 2 = cliente
                              localStorage.getItem("nivel") === "2" ? (
                                <>
                                  <Link
                                    to="/rastreamento"
                                    className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                                    style={{
                                      color: "#000000",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <span>
                                      Meus pedidos&nbsp;
                                      <i
                                        style={{
                                          color: "red",
                                          fontSize: "16px",
                                        }}
                                        className="far fa-list-alt"
                                      ></i>
                                    </span>
                                  </Link>
                                  <Link
                                    to="/conta"
                                    className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                                    style={{
                                      color: "#000000",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <span>
                                      Conta&nbsp;
                                      <i
                                        style={{
                                          color: "blue",
                                          fontSize: "16px",
                                        }}
                                        className="fas fa-user-tie"
                                      ></i>
                                    </span>
                                  </Link>
                                </>
                              ) : (
                                ""
                              )
                            }
                            {
                              // 0 = lojista
                              localStorage.getItem("nivel") === "0" ? (
                                <>
                                  <Link
                                    to="sistema/conta"
                                    className="list-group-item list-group-item-action font-weight-bold  my-2 my-sm-0"
                                    style={{
                                      color: "#000000",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <span>
                                      Conta&nbsp;
                                      <i
                                        style={{
                                          color: "blue",
                                          fontSize: "16px",
                                        }}
                                        className="fas fa-user-tie"
                                      ></i>
                                    </span>
                                  </Link>
                                </>
                              ) : (
                                ""
                              )
                            }
                            <Link
                              to="/logout"
                              className="list-group-item list-group-item-action   font-weight-bold  my-2 my-sm-0"
                              style={{
                                color: "#000000",
                                textDecoration: "none",
                              }}
                            >
                              <span>
                                Sair&nbsp;
                                <i
                                  style={{ color: "purple", fontSize: "16px" }}
                                  className="fas fa-sign-out-alt"
                                ></i>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </li>
                    )}
                    {
                      //Se o carrinho estiver vazio,não sera permitido entrar nele
                      localStorage.getItem("carrinho") ? (
                        <Link
                          className="dropdown-item link"
                          style={{ textDecoration: "none", display: "block" }}
                          to="/carrinho"
                        >
                          {" "}
                          <span className="icon-menu">
                            {localStorage.getItem("carrinho")}
                          </span>
                          &nbsp;
                          <i
                            style={{ color: "#11ca17", fontSize: "16px" }}
                            className="fas fa-shopping-cart"
                          ></i>
                        </Link>
                      ) : (
                        <Link
                          className="dropdown-item link"
                          style={{ textDecoration: "none", display: "block" }}
                          to="/produtos"
                        >
                          {" "}
                          <span className="icon-menu">0</span>&nbsp;
                          <i
                            style={{ color: "#11ca17", fontSize: "16px" }}
                            className="fas fa-shopping-cart"
                          ></i>
                        </Link>
                      )
                    }
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Categorias
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {categoria.map((cat) => (
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={(e) => this.filtro_sub_cat(cat)}
                            key={cat.pc_id}
                          >
                            {cat.pc_descricao}
                          </Link>
                        ))}
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Subcategorias
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {this.state.subcategoria_filter.length > 0 ? (
                          this.state.subcategoria_filter.map((item) => (
                            <Link
                              className="dropdown-item"
                              key={item.pc_id}
                              to="#"
                              onClick={(e) => this.filtro_sub_categoria(item)}
                            >
                              {item.pcs_descricao}
                            </Link>
                          ))
                        ) : (
                          <Link className="dropdown-item" to="#">
                            Selecione uma categoria
                          </Link>
                        )}
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Filtro
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {filtro.map((item) => (
                          <Link
                            className="dropdown-item"
                            key={item.id}
                            to="#"
                            onClick={(e) => this.filtro(item)}
                          >
                            {" "}
                            {item.descricao}
                          </Link>
                        ))}
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Cidades
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => this.reset_filtro()}
                        >
                          Todas as Empresas
                        </Link>
                        {cidades.map(
                          function (item, i) {
                            if (item.cid_id !== 3) {
                              return (
                                <>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    key={i}
                                  >
                                    <b>{item.cid_nome + "/" + item.cid_uf}</b>
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    key={i + item.cid_id}
                                    onClick={(e) => this.filtro_cidade(item)}
                                  >
                                    Todas as Empresas de {item.cid_nome}
                                  </Link>
                                  {empresa.map(
                                    function (empresa, i) {
                                      if (
                                        empresa.emp_cidade ===
                                        item.cid_nome + "/" + item.cid_uf
                                      ) {
                                        return (
                                          <Link
                                            key={i}
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              this.filtro_empresa(empresa)
                                            }
                                            to="#"
                                          >
                                            {empresa.emp_nomefantasia}
                                          </Link>
                                        );
                                      }
                                    }.bind(this)
                                  )}
                                </>
                              );
                            }
                          }.bind(this)
                        )}
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </nav>
          </>
        ) : (
          <Navbar_public />
        )}

        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
          style={{ marginTop: "4px" }}
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
         
          </ol>
          <div className="carousel-inner">
 
        
         
   
                <div className="carousel-item active ">
                  <img
                    className="d-block w-100"
                    src={banner4}
                    alt="First slide"
                  />
                </div>
            {slideLazyLoad === false ? (
              <>
               
                <div className="carousel-item ">
                  <img
                    className="d-block w-100"
                    src={banner3}
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item ">
                  <img
                    className="d-block w-100"
                    src={banner1}
                    alt="Four slide"
                  />
                </div>
                <div className="carousel-item ">
                  <img
                    className="d-block w-100"
                    src={banner2}
                    alt="Five slide"
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div className="container" style={{ marginTop: "4px" }}>
          <br />
          {tela === 1 ? (
            ""
          ) : (
            <div
              className="row"
              style={{
                marginTop: "5px",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {" "}
             {/*<div className="col-md-1 mr-5 ">
                <img
                  style={{ maxWidth: "110px", borderRadius: "70px" }}
                  src={img_leme_liquida}
                  onClick={(e) =>
                    this.AtualizarGetDestaque("api/produto/0/0/0/0/0/1/3")
                  }
                />
                </div>*/}
              <div className="col-md-12 ">
                <Carousel responsive={responsive}>
                  {this.state.categoria.map((cat, index) => {
                    return (
                      <>
                        <div
                          onClick={(e) => this.filtro_sub_cat(cat)}
                          key={index}
                        >
                          <img
                            style={{ height: "120px", width: "120px" }}
                            src={
                              dominio +
                              "/imagens/categoria/" +
                              cat.pc_id +
                              "/" +
                              cat.pc_imagem
                            }
                            alt="Alt text"
                          />
                        </div>
                      </>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          )}
          <br />
          <div className="row mb-5">
            <div className="col-md-9 order-2">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <div className="float-md-left mb-1">
                    {tela === 1 ? (
                      <h2
                        className="text-black h5 "
                        style={{ color: "#F58634" }}
                      >
                        {texto_de_pesquisa}
                      </h2>
                    ) : (
                      <h2
                        style={{ color: "#778796" }}
                        className="text-black h5 "
                      >
                        {texto_de_pesquisa}{" "}
                      </h2>
                    )}{" "}
                  </div>
                  <div className="d-flex">
                    <div className="dropdown mr-1 ml-md-auto">
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuOffset"
                      >
                        {categoria.map(function (item, i) {
                          return (
                            <Link className="dropdown-item" to="#" key={i}>
                              {item.titulo}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                    {tela === 1 ? (
                      ""
                    ) : (
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm dropdown-toggle"
                          id="dropdownMenuReference"
                          data-toggle="dropdown"
                        >
                          Filtro
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuReference"
                        >
                          {filtro.map((item) => (
                            <Link
                              key={item.id}
                              className="dropdown-item"
                              to="#"
                              onClick={(e) => this.filtro(item)}
                            >
                              {item.descricao}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">{  this.state.produtosPag.map((item, index) => {
      if (item.prod_caminhofoto) {
        var prod_caminhofoto = item.prod_caminhofoto.split(",");
        var src =
          dominio + "/imagens/" + item.prod_id + "/" + prod_caminhofoto[0];
      } else {
        src = no_imagem;
      }
      var contato_zap = String(item.emp_celular);
      if (contato_zap.length > 0) {
        contato_zap = contato_zap.replace("-", "");
        contato_zap = contato_zap.replace(" ", "");
        contato_zap = contato_zap.replace("(", "");
        contato_zap = contato_zap.replace(")", "");
      }

      return (
        <>
          <div
            className="col-sm-4 col-lg-4 mb-4"
            data-aos="fade-up"
            key={index}
          >
            <div className="block-2 text-center border">
              <br />

              <figure className="block-2-image" alt={src}>
                {item.status === "Indisponível" ? (
                  <Link>
                    <LazyLoad>
                      <img
                        src={src}
                        alt={src}
                        className="img-fluid"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </LazyLoad>
                  </Link>
                ) : (
                  <Link to={"/produto" + item.prod_id}>
                    <LazyLoad>
                      <img
                        src={src}
                        alt={src}
                        className="img-fluid"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </LazyLoad>
                  </Link>
                )}
              </figure>
              <div className="p-4" style={{wordWrap: 'break-word'}}>
                <h6 className="text-left text-secondary">{item.prod_nome}</h6>
                {item.status === "Indisponível" ? (
                  <h6 className="mb-1 text-left ">
                    <b>{item.status}</b>
                  </h6>
                ) : item.prod_promocao > 0 && item.prod_promocao != item.prod_preco ?  (
                  <h6 className="mb-1 text-left">
                    <span style={{ color: "#808080", fontSize: "12px" }}>
                      de
                    </span>{" "}
                    <del>
                      <span style={{ color: "#808080", fontSize: "12px" }}>
                        R$ {parseFloat(item.prod_preco).toFixed(2)}
                      </span>
                    </del>
                    <span style={{ color: "#808080", fontSize: "12px" }}>
                      {" "}
                      por
                    </span>{" "}
                    <b>
                      <span style={{ color: "#F58634" }}>
                        R${parseFloat(item.prod_promocao).toFixed(2)}
                      </span>
                    </b>
                  </h6>
                ) : (
                  <h6 className="mb-1 text-left ">
                    <b>R$ {item.prod_preco} </b>
                  </h6>
                )}
                <p className="mb-0 text-left text-secondary">
                  {item.emp_nomefantasia}
                </p>
                {item.emp_fone !== "" ? (
                  <p
                    className="mb-0 text-left font-weight-bold"
                    style={{ color: "#F58634" }}
                  >
                    {item.emp_fone}
                  </p>
                ) : (
                  ""
                )}
                {item.emp_celular !== "" ? (
                  <p className="mb-0 text-left font-weight-bold">
                    {tela === 1 ? (
                      <a
                        target="blank"
                        className="text-success"
                        href={
                          "https://wa.me/55" +
                          contato_zap +
                          "?text=" +
                          encodeURIComponent(
                            item.prod_nome +
                              " De R$ " +
                              item.prod_preco +
                              " por R$ " +
                              item.prod_promocao +
                              " " +
                              dominio +
                              "/facebook.php?produto=" +
                              item.prod_id
                          )
                        }
                      >
                        <i className="fab fa-whatsapp"></i>{" "}
                        <span style={{ color: "#F58634" }}>
                          {item.emp_celular}
                        </span>
                      </a>
                    ) : (
                      <>
                     
                      <span style={{ color: "#F58634" }}>
                        {item.emp_celular}
                      </span>
                      </>
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      );
    })}</div>
              <div
                className="btn-toolbar text-center"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
          <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={this.handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                labelRowsPerPage={"Por página"}
              />
              </div>
            </div>

            {tela === 1 ? (
              ""
            ) : (
              <>
                <div className="col-md-3 order-1 mb-5 mb-md-0">
                  <div style={{ marginBottom: "30px" }}>
                    <div
                      style={{ position: "absolute", width: "90%" }}
                      className="input-group mb-3"
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquise seu produto..."
                        aria-label="Pesquise seu produto..."
                        aria-describedby="basic-addon2"
                        onKeyPress={(e) =>
                          e.which == 13
                            ? this.pesquisarProduto(pesquisar_produto)
                            : false
                        }
                        onChange={(e) =>
                          this.setState({ pesquisar_produto: e.target.value })
                        }
                        value={pesquisar_produto}
                      />
                      <div className=" input-group-append">
                        <span
                          style={{ backgroundColor: "#f58634", color: "#fff" }}
                          onClick={(e) =>
                            this.pesquisarProduto(pesquisar_produto)
                          }
                          className=" input-group-text"
                          id="basic-addon2"
                        >
                          <i className="fas  fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <br />
                  </div>
                  <h3
                    className="mb-3 h6 text-center text-uppercase text-black d-block btn btn-outline-secondary"
                    style={{ color: "#F58634" }}
                    onClick={(e) => this.AtualizarGetDestaque()}
                  >
                    <b>PRODUTOS EM DESTAQUE</b>
                  </h3>
                  <div className="border p-4 rounded mb-4">
                    <div className="mb-4">
                      <h3
                        className="mb-3 h6 text-uppercase text-black d-block"
                        style={{ color: "#F58634" }}
                      >
                        Sub Categorias
                      </h3>
                      {this.state.subcategoria_filter.length > 0 ? (
                        this.state.subcategoria_filter.map((item) => (
                          <Link
                            to="#"
                            className="d-flex"
                            onClick={(e) => this.filtro_sub_categoria(item)}
                          >
                            <span
                              className="mb-3 h6 text-left"
                              style={{ color: "#848688" }}
                            >
                              {" "}
                              {item.pcs_descricao}
                            </span>
                          </Link>
                        ))
                      ) : (
                        <p
                          className="mb-3 h6 text-left"
                          style={{ color: "#848688" }}
                        >
                          Selecione uma categoria
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="border p-4 rounded mb-4" id="accordion">
                    <div className="mb-4">
                      <h3
                        className="mb-3 h6 text-uppercase text-black d-block"
                        style={{ color: "#F58634" }}
                      >
                        Cidades
                      </h3>
                      <Link
                        to="#"
                        className="d-flex"
                        onClick={(e) => this.reset_filtro()}
                      >
                        <span
                          className="mb-3 h6 text-left"
                          style={{ color: "#848688" }}
                        >
                          Todas Empresas
                        </span>
                      </Link>
                      {cidades.map(
                        function (item) {
                          if (item.cid_id !== 3) {
                            return (
                              <>
                                <Link
                                  key={item.cid_id}
                                  className="d-flex"
                                  data-toggle="collapse"
                                  data-target={"#collapse" + item.cid_id}
                                  aria-expanded="true"
                                  aria-controls={"collapse" + item.cid_id}
                                >
                                  <span
                                    className="mb-3 h6 text-left"
                                    style={{ color: "#848688" }}
                                  >
                                    {item.cid_nome + "/" + item.cid_uf}
                                  </span>
                                </Link>
                                <div
                                  id={"collapse" + item.cid_id}
                                  className="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordion"
                                >
                                  <Link to="#" className="d-flex">
                                    <span
                                      className="mb-3 h6 text-left"
                                      style={{ color: "#848688" }}
                                    >
                                      Todas Empresas de {item.cid_nome}
                                    </span>
                                  </Link>
                                  {empresa.map(
                                    function (empresa) {
                                      if (
                                        empresa.emp_cidade ===
                                        item.cid_nome + "/" + item.cid_uf
                                      ) {
                                        return (
                                          <Link to="#" className="d-flex">
                                            <span
                                              className="mb-3 text-left"
                                              onClick={(e) =>
                                                this.filtro_empresa(empresa)
                                              }
                                              style={{ color: "#848688" }}
                                            >
                                              {empresa.emp_nomefantasia}
                                            </span>
                                          </Link>
                                        );
                                      }
                                    }.bind(this)
                                  )}
                                </div>
                              </>
                            );
                          }
                        }.bind(this)
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default produtos;
