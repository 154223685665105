import React from "react";
import logo from "../../imagens/keep_delivery_horiz.png";
import { Link } from "react-router-dom";

function Navbar() {
  /* CODIGO PARA FECHAR MENU RESPONSIVO AO CLICAR EM UM LINK */
  var el_array = document.getElementsByClassName("link");
  for (var el of el_array) {
    el.addEventListener(
      "click",
      function FecharMenuResponsivo() {
        var element = document.getElementById("navbarSupportedContent");
        element.classList.remove("show");
      },
      false
    );
  }

  var url_atual = window.location.href;
  var splits = url_atual.split("/");
  //alert(splits[3]);
  var menu = "";
  switch (splits[3]) {
    case "logout":
      menu = 3;
      break;
    case "":
      menu = 3;
      break;
    default:
      menu = 0;
  }

  if (menu === 0) {
    return (
      <div>
        <nav
          className="navbar navbar-expand-lg navbar-light "
          style={{
            backgroundColor: "#ecf0f4 ",
            position: "fixed",
            top: "0",
            width: "100%",
            zIndex: "2",
          }}
        >
          {
            // 0 = Lojista
            localStorage.getItem("nivel") === "0" ? (
              <>
                <Link className="navbar-brand ml-5" to="/home">
                  <img src={logo} alt="logo" style={{ width: "116px" }} />
                </Link>
              </>
            ) : (
              ""
            )
          }
          {
            // 1 = Transportadora
            localStorage.getItem("nivel") === "1" ? (
              <>
                <Link className="navbar-brand ml-5" to="/sistema/carrinho">
                  <img src={logo} alt="logo" style={{ width: "116px" }} />
                </Link>
              </>
            ) : (
              ""
            )
          }
          {
            // 3 = ADM
            localStorage.getItem("nivel") === "3" ? (
              <>
                <Link className="navbar-brand ml-5" to="/admin/empresas">
                  <img src={logo} alt="logo" style={{ width: "116px" }} />
                </Link>
              </>
            ) : (
              ""
            )
          }
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>
              <i className="fas fa-bars" />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {
                // 0 = Lojista
                localStorage.getItem("nivel") === "0" ? (
                  <>
                    <li className="nav-item link">
                      <Link
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                        to="/sistema/cad_produto"
                      >
                        <b>Produtos</b>&nbsp;
                        <i
                          style={{ color: "red", fontSize: "16px" }}
                          className="fas fa-rocket"
                        ></i>
                      </Link>
                    </li>
                    <li className="nav-item link">
                      <Link
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                        to="/sistema/carrinho"
                      >
                        <b>Carrinho</b>&nbsp;
                        <i
                          style={{ color: "red", fontSize: "16px" }}
                          className="fas fa-shopping-cart"
                        ></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="dropdown-item link"
                        style={{ textDecoration: "none" }}
                        to="/sistema/conta"
                      >
                        <b>Conta</b>&nbsp;
                        <i
                          style={{ color: "#2364c5", fontSize: "16px" }}
                          className="fas fa-user-edit"
                        ></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="dropdown-item    link"
                        style={{ textDecoration: "none" }}
                        to="/sistema/alterarsenha"
                      >
                        <b>Alterar Senha</b>&nbsp;
                        <i
                          style={{ color: "#2364c5", fontSize: "16px" }}
                          className="fas fa-user-edit"
                        ></i>
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )
              }

              {
                // 1 = Transportadora
                localStorage.getItem("nivel") === "1" ? (
                  <>
                    <li className="nav-item link">
                      {" "}
                      <Link
                        className="dropdown-item   "
                        style={{ textDecoration: "none" }}
                        to="/sistema/carrinho"
                      >
                        <b>Carrinho</b>&nbsp;
                        <i
                          style={{ color: "red", fontSize: "16px" }}
                          className="fas fa-shopping-cart"
                        ></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="dropdown-item    link"
                        style={{ textDecoration: "none" }}
                        to="/sistema/alterarsenha"
                      >
                        <b>Alterar Senha</b>&nbsp;
                        <i
                          style={{ color: "#2364c5", fontSize: "16px" }}
                          className="fas fa-user-edit"
                        ></i>
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )
              }

              {
                // 3 = Adm
                localStorage.getItem("nivel") === "3" ? (
                  <>
                    <li className="nav-item link">
                      {" "}
                      <Link
                        className="dropdown-item   "
                        style={{ textDecoration: "none" }}
                        to="/admin/empresas"
                      >
                        <b>Empresas</b>&nbsp;
                        <i
                          style={{ color: "#2364c5", fontSize: "16px" }}
                          className="fas fa-user-tie"
                        ></i>
                      </Link>
                    </li>
                    <li className="nav-item link">
                      {" "}
                      <Link
                        className="dropdown-item   "
                        style={{ textDecoration: "none" }}
                        to="/admin/carrinho"
                      >
                        <b>Carrinho</b>&nbsp;
                        <i
                          style={{ color: "red", fontSize: "16px" }}
                          className="fas fa-shopping-cart"
                        ></i>
                      </Link>
                    </li>
                    <li className="nav-item link">
                      {" "}
                      <Link
                        className="dropdown-item   "
                        style={{ textDecoration: "none" }}
                        to="/admin/listarclientes"
                      >
                        <b>Listar Clientes</b>&nbsp;
                        <i
                          style={{ color: "blue", fontSize: "16px" }}
                          className="fas fa-users"
                        ></i>
                      </Link>
                    </li>
                    <li className="nav-item link">
                      {" "}
                      <Link
                        className="dropdown-item   "
                        style={{ textDecoration: "none" }}
                        to="/admin/categoria"
                      >
                        <b>Categoria</b>&nbsp;
                        <i
                          style={{ color: "green", fontSize: "16px" }}
                          className="fas fa-layer-group"
                        ></i>
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )
              }
            </ul>
            <form className="form-inline my-2 my-lg-0">
              <Link
                to="/logout"
                className="font-weight-bold  my-2 my-sm-0"
                style={{ color: "#000000", textDecoration: "none" }}
              >
                {localStorage.getItem("nome") + " "}
                <span style={{ color: "red" }}>(Sair)</span>
              </Link>
            </form>
          </div>
        </nav>
        <br />
        <br />
      </div>
    );
  } else if (menu === 3) {
    return <div></div>;
  }
}
export default Navbar;
