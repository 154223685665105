import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Menu from "../../../layouts/Navbar_public";
import no_imagem from "../../../../imagens/sem_imagem.jpg";
import { Helmet } from "react-helmet";
import { dominio, api } from "../../../plugins/Config/Config";
import logo from "../../../../imagens/keep_delivery_horiz.png";
import Footer from "../../../footer/footer";
class produto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo: {
        pagina: "Keepdelivery | Produto",
      },
      pagar: 0.0,
      produto: {
        prod_id: null,
        pc_descricao: "",
        pc_id: null,
        pcs_descricao: "",
        pcs_id: null,
        prod_nome: "",
        prod_empresa: null,
        prod_descricao: "",
        prod_preco: null,
        prod_promocao: null,
        prod_qtde: null,
        prod_caminhofoto: "",
        prod_prova: "",
        prod_produtocategoria: null,
        prod_produtocategoriasubgrupo: null,
        prod_peso: null,
        angulos: null,
      },
      disponivel: null,
      id: {
        empresa: 0,
        categoria: "0",
        subcategoria: "0",
        nome: "0",
        cliente: 1,
      },
      list: [],
      organizado: [],
      redirect_page: null,
      url: "",
      status: null,
      carrinho: {
        prod_id: "",
        prod_qtde: "",
        prod: "",
        prod_empresa: "",
      },
      texto: "",
      texto2: "",
      tela: 0,
      fotos: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const { id, list } = this.state;
    let nome = "";
    nome = localStorage.getItem("nome");
    id.nome = nome;

    this.setState({ id });
    this.verifica_tela();
    this.AtualizarGet();
    //localStorage.clear();
    this.initListStorage(list);
    id.nome = localStorage.getItem("nome");
    let id_cliente = localStorage.getItem("id");
    if (id_cliente) {
      id.cliente = id_cliente;
      this.setState({ id });
    } else {
      id.cliente = 0;
      this.setState({ id });
    }
  }
  AtualizarGet() {
    const { empresa, categoria, subcategoria } = this.state.id;
    const { id } = this.props.match.params;
    let url =
      "api/produto/" +
      empresa +
      "/" +
      categoria +
      "/" +
      subcategoria +
      "/" +
      id +
      "";
    api
      .get(url)
      .then((response) => {
        const { titulo } = this.state;
        if (response.data[0].prod_caminhofoto) {
          var prod_caminhofoto = response.data[0].prod_caminhofoto.split(",");
          var src =
            dominio +
            "/imagens/" +
            response.data[0].prod_id +
            "/" +
            prod_caminhofoto[0];
          this.setState({ fotos: prod_caminhofoto });
        } else {
          src = no_imagem;
          this.setState({ fotos: [] });
        }

        this.setState({
          produto: {
            prod_id: response.data[0].prod_id,
            pc_descricao: response.data[0].pc_descricao,
            pc_id: response.data[0].pc_id,
            pcs_descricao: response.data[0].pcs_descricao,
            pcs_id: response.data[0].pcs_id,
            prod_nome: response.data[0].prod_nome,
            prod_empresa: response.data[0].prod_empresa,
            emp_nomefantasia: response.data[0].emp_nomefantasia,
            emp_celular: response.data[0].emp_celular,
            emp_email: response.data[0].emp_email,
            prod_descricao: response.data[0].prod_descricao,
            prod_preco: response.data[0].prod_preco,
            prod_promocao: response.data[0].prod_promocao,
            prod_qtde: 1,
            prod_caminhofoto: src,
            prod_prova: response.data[0].prod_prova,
            prod_produtocategoria: response.data[0].prod_produtocategoria,
            prod_produtocategoriasubgrupo:
              response.data[0].prod_produtocategoriasubgrupo,
            prod_peso: response.data[0].prod_peso,
            prod_youtube: response.data[0].prod_youtube,
          },
        });
        if (response.data[0].prod_promocao > 0) {
          this.setState({ pagar: response.data[0].prod_promocao });
        } else {
          this.setState({ pagar: response.data[0].prod_preco });
        }

        this.setState({ disponivel: response.data[0].prod_qtde });
        titulo.pagina = "Keepdelivery | " + response.data[0].prod_nome;
        this.setState({ titulo });
      })
      .catch((error) => {});
  }
  atualizar = () => {
    const { list, organizado } = this.state;
    this.setState({ url: "/carrinho" });
    let result = [];
    // eslint-disable-next-line array-callback-return
    list.filter(function (i) {
      var hasElement = false;
      var j;
      for (j = 0; j < result.length; j++) {
        if (result[j].carp_produto === i.carp_produto) {
          hasElement = true;
          break;
        }
      }
      if (hasElement) {
        result[j].carp_qtde += i.carp_qtde;
        result[j].carp_varlortotal += i.carp_varlortotal;
      } else {
        result.push(i);
      }
    });
    organizado.push(JSON.stringify(result));
    // this.setState({ produtos: JSON.stringify(result) })
   // console.log("Organizado" + organizado);
    localStorage.setItem("organizado", organizado);
    this.setState({ redirect_page: true });
  };
  saveListStorage(list) {
    var jsonStr = JSON.stringify(list);
    localStorage.setItem("list", jsonStr);
  }
  addData(produto) {
    const { list, id, disponivel } = this.state;
    debugger;
    this.AtualizarGet();
    if (disponivel > 0) {
      if (id.cliente > 0) {
        let car_cliente = parseInt(id.cliente);
        let car_produto = produto.prod_id;
        let car_qtde = produto.prod_qtde;
        let car_valorunitario = 0;
        let car_valortotal = 0;
        let car_empresa = produto.prod_empresa;
        let prod_nome = produto.prod_nome;
        let email = produto.emp_email;
        if (produto.prod_promocao > 0) {
          car_valorunitario = parseFloat(produto.prod_promocao);
          car_valortotal =
            produto.prod_qtde * parseFloat(produto.prod_promocao);
        } else {
          car_valorunitario = parseFloat(produto.prod_preco);
          car_valortotal = produto.prod_qtde * parseFloat(produto.prod_preco);
        }
        list.push({
          carp_cliente: car_cliente,
          carp_produto: car_produto,
          carp_qtde: car_qtde,
          carp_valorunitario: car_valorunitario,
          carp_valortotal: parseFloat(car_valortotal),
          carp_empresa: car_empresa,
          prod_nome: prod_nome,
          prod_caminhofoto: produto.prod_caminhofoto,
          carp_prova: produto.prod_prova,
          prova: produto.prod_prova,
          email: email,
        });
        this.saveListStorage(list);
       // console.log("List" + JSON.stringify(list));
        this.atualizar();
        if (produto.prod_qtde > 0) {
          let i = list.length;
          localStorage.setItem("carrinho", i);
          this.setState({ status: true });
        } else {
          alert("Não Há produtos adicionados");
        }
      } else {
        alert("Você precisa estar logado");
        this.setState({ url: "/login_cliente" });
        this.setState({ redirect_page: true });
      }
    } else {
      alert("Esse produto acabou de ficar indisponível");
    }
  }
  initListStorage() {
    const { list } = this.state;
    var testList = localStorage.getItem("list");
    if (testList) {
      this.setState({ list: JSON.parse(testList) });
    }
    this.saveListStorage(list);
  }
  add = (produto) => {
   // console.log("O Array " + JSON.stringify(produto));
    produto.prod_qtde = produto.prod_qtde + 1;
    if (produto.prod_promocao > 0) {
      var total = produto.prod_qtde * produto.prod_promocao;
    } else {
      total = produto.prod_qtde * produto.prod_preco;
    }

    this.setState({ pagar: total });
   // console.log("Alterando Array " + JSON.stringify(total));
  };

  rm = (produto) => {
    if (produto.prod_qtde > 0) {
     // console.log("Alterando Array " + JSON.stringify(produto));
      produto.prod_qtde = produto.prod_qtde - 1;
      if (produto.prod_promocao > 0) {
        var total = produto.prod_qtde * produto.prod_promocao;
      } else {
        total = produto.prod_qtde * produto.prod_preco;
      }
      this.setState({ pagar: total });
      if (produto.prod_qtde === 0) {
        if (produto.prod_promocao > 0) {
          total = produto.prod_qtde * produto.prod_promocao;
        } else {
          total = produto.prod_qtde * produto.prod_preco;
        }
        this.setState({ pagar: total });
      }
    //  console.log("Alterando Array " + JSON.stringify(produto));
    } else {
      alert("Deseja cancelar a compra?");
      this.setState({ url: "/" });
      this.setState({ redirect_page: true });
    }
  };
  verifica_tela = () => {
    if (window.innerWidth < 640 || window.innerHeight < 480) {
      this.setState({ tela: 1 });
    } else if (window.innerWidth < 1024 || window.innerHeightt < 768) {
      this.setState({ tela: 2 });
    } else {
    }
  };
  facebook = (e) => {
    var facebookWindow = window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + document.URL,
      "facebook-popup",
      "height=350,width=600"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  };

  render() {
    const { produto, titulo, pagar, url, tela, fotos } = this.state;
    console.log(pagar);
    document.title = titulo.pagina;
    //   var facebookShare = document.querySelector('[data-js="facebook-share"]');
    if (produto.emp_celular) {
      var contato_zap = produto.emp_celular.replace("-", "");
      contato_zap = contato_zap.replace(" ", "");
      contato_zap = contato_zap.replace("(", "");
      contato_zap = contato_zap.replace(")", "");
    }
var textoDeCompartilhamento = '';
    if(produto.prod_promocao > 0  &&  produto.prod_promocao != produto.prod_preco){
 textoDeCompartilhamento = produto.prod_nome +" De R$ " +produto.prod_preco +" por R$ " +produto.prod_promocao+ "  ";
   }else{
textoDeCompartilhamento = produto.prod_nome +" por R$ " +produto.prod_preco+ "  ";
 
   }
    if (this.state.redirect_page === true) {
      return <Redirect to={url} />;
    } else {
      return (
        <>
          {tela === 1 ? (
            <>
              <div class="p-2 bg-danger text-white">
                <i class="fab fa-whatsapp"></i> (19) 3573-7080{" "}
                <i class="far fa-envelope"></i> contato@keepdelivery.com.br
              </div>
              <nav
                className="navbar navbar-expand-lg"
                style={{
                  backgroundColor: "#ecf0f4 ",
                  top: "1",
                  width: "100%",
                  zIndex: "2",
                }}
              >
                <Link className="navbar-brand ml-5" to="/produtos">
                  {/*<Link className="navbar-brand ml-5"  to="/login_lojista">*/}
                  <img src={logo} alt="logo" style={{ width: "116px" }} />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span>
                    <i className="fas fa-bars" />
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto"></ul>
                  <form className="form-inline my-2 my-lg-0">
                    <ul className="navbar-nav mr-auto">
                      {!localStorage.getItem("nivel") ? (
                        <>
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none", display: "block" }}
                            to="/login_transportadora"
                          >
                            <b>Transportadora</b>&nbsp;
                            <i
                              style={{ color: "#2364c5", fontSize: "16px" }}
                              className="fas fa-truck"
                            ></i>
                          </Link>
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none" }}
                            to="/login_lojista"
                          >
                            <b>Lojista</b>&nbsp;
                            <i
                              style={{ color: "#2364c5", fontSize: "16px" }}
                              className="fas fa-user-tie"
                            ></i>
                          </Link>
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none", display: "block" }}
                            to="/login_cliente"
                          >
                            <b>Cliente</b>&nbsp;
                            <i
                              style={{ color: "#2364c5", fontSize: "16px" }}
                              className="fas fa-user"
                            ></i>
                          </Link>
                        </>
                      ) : (
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            style={{ padding: "0.3rem 1rem" }}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <b>{localStorage.getItem("nome")}</b>&nbsp;
                            <i
                              style={{ color: "#ecf0f4;", fontSize: "16px" }}
                              className="fas fa-user-tie"
                            ></i>
                          </Link>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <div class="list-group">
                              {
                                // 2 = cliente
                                localStorage.getItem("nivel") === "2" ? (
                                  <>
                                    <Link
                                      to="/rastreamento"
                                      className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                                      style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <span>
                                        Meus pedidos&nbsp;
                                        <i
                                          style={{
                                            color: "red",
                                            fontSize: "16px",
                                          }}
                                          class="far fa-list-alt"
                                        ></i>
                                      </span>
                                    </Link>
                                    <Link
                                      to="/conta"
                                      className="list-group-item list-group-item-action  font-weight-bold  my-2 my-sm-0"
                                      style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <span>
                                        Conta&nbsp;
                                        <i
                                          style={{
                                            color: "blue",
                                            fontSize: "16px",
                                          }}
                                          class="fas fa-user-tie"
                                        ></i>
                                      </span>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              {
                                // 0 = lojista
                                localStorage.getItem("nivel") === "0" ? (
                                  <>
                                    <Link
                                      to="sistema/conta"
                                      className="list-group-item list-group-item-action font-weight-bold  my-2 my-sm-0"
                                      style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <span>
                                        Conta&nbsp;
                                        <i
                                          style={{
                                            color: "blue",
                                            fontSize: "16px",
                                          }}
                                          class="fas fa-user-tie"
                                        ></i>
                                      </span>
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              <Link
                                to="/logout"
                                className="list-group-item list-group-item-action   font-weight-bold  my-2 my-sm-0"
                                style={{
                                  color: "#000000",
                                  textDecoration: "none",
                                }}
                              >
                                <span>
                                  Sair&nbsp;
                                  <i
                                    style={{
                                      color: "purple",
                                      fontSize: "16px",
                                    }}
                                    class="fas fa-sign-out-alt"
                                  ></i>
                                </span>
                              </Link>
                            </div>
                          </div>
                        </li>
                      )}
                      {
                        //Se o carrinho estiver vazio,não sera permitido entrar nele
                        localStorage.getItem("carrinho") ? (
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none", display: "block" }}
                            to="/carrinho"
                          >
                            {" "}
                            <span className="icon-menu">
                              {localStorage.getItem("carrinho")}
                            </span>
                            &nbsp;
                            <i
                              style={{ color: "#11ca17", fontSize: "16px" }}
                              className="fas fa-shopping-cart"
                            ></i>
                          </Link>
                        ) : (
                          <Link
                            className="dropdown-item link"
                            style={{ textDecoration: "none", display: "block" }}
                            to="/produtos"
                          >
                            {" "}
                            <span className="icon-menu">0</span>&nbsp;
                            <i
                              style={{ color: "#11ca17", fontSize: "16px" }}
                              className="fas fa-shopping-cart"
                            ></i>
                          </Link>
                        )
                      }
                    </ul>
                  </form>
                </div>
              </nav>
            </>
          ) : (
            <Menu />
          )}
          <div className="site-wrap">
            {/*}            <div className='bg-light py-3'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12 mb-0'>
                    <Link to='/produtos'>Home</Link>{' '}
                    <span className='mx-2 mb-0'>/</span>{' '}
                    <strong className='text-black'>
                      {produto.prod_nome}
                    </strong>
                  </div>
                </div>
              </div>
            </div>

      */}
            <div className="produto">
              <Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
                <meta
                  property="og:url"
                  content="https://example.com/page.html"
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Content Title" />
                <meta
                  property="og:image"
                  content="https://example.com/image.jpg"
                />
                <meta
                  property="og:image:alt"
                  content="A description of what is in the image (not a caption)"
                />
                <meta property="og:description" content="Description Here" />
                <meta property="og:site_name" content="Site Name" />
              </Helmet>
              ...
            </div>
            <div className="site-section">
              <div className="container">
                <div className="row mb-2" key={produto.prod_id}>
                  <div className="col-md-6">
                    {fotos.length > 2 ? (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt
                      <div
                        id="carouselExampleIndicators"
                        className="carousel slide"
                        data-ride="carousel"
                        style={{ marginTop: "4px" }}
                      >
                        <ol className="carousel-indicators">
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to="0"
                            className="active"
                          ></li>
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to="1"
                          ></li>
                        </ol>
                        <div className="carousel-inner">
                          {fotos.map(function (fotos, i) {
                            if (fotos) {
                              if (i === 1) {
                                var active = "active";
                              } else {
                                active = "";
                              }
                            }
                            return (
                              <>
                                <div className={"carousel-item " + active}>
                                  <img
                                    className="img-fluid"
                                    src={
                                      dominio +
                                      "/imagens/" +
                                      produto.prod_id +
                                      "/" +
                                      fotos
                                    }
                                    alt={i + "Slide"}
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                        <a
                          className="carousel-control-prev"
                          href="#carouselExampleIndicators"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselExampleIndicators"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    ) : // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    produto.prod_caminhofoto ? (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt
                      <img
                        src={produto.prod_caminhofoto}
                        alt="Image"
                        className="img-fluid"
                        style={{ maxwidth: "450px", maxheight: "450px" }}
                      />
                    ) : (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt
                      <img
                        src={no_imagem}
                        alt="Image"
                        style={{ maxwidth: "450px", maxheight: "450px" }}
                        className="img-fluid"
                      />
                    )}
                    <hr />
                    <div className="row">
                      <div className="col-12">
                        {fotos.length > 2
                          ? fotos.map(function (item, i) {
                              if (item) {
                                return (
                                  <img
                                    src={
                                      dominio +
                                      "/imagens/" +
                                      produto.prod_id +
                                      "/" +
                                      item
                                    }
                                    alt={i + "Slide"}
                                    className="img-thumbnail mr-2"
                                    data-target="#carouselExampleIndicators"
                                    data-slide-to={i}
                                    style={{
                                      maxWidth: "120px",
                                      maxHeight: "120px",
                                    }}
                                  />
                                );
                              }
                            })
                          : ""}
                      </div>
                      {produto.prod_youtube ? (
                        <div className="col-md-6 mb-1">
                          <p className="text-left text-black h6">
                            Assistir vídeo{" "}
                            <a
                              href={produto.prod_youtube}
                              target="blank"
                              className="btn btn-info btn-sm"
                            >
                              <i className="fab fa-youtube"></i>
                            </a>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h2 className="text-black"> {produto.prod_nome}</h2>
                    <p>{produto.prod_descricao}</p>
                    <p className="mb-4">
                      <strong style={{ color: "#F58634" }}>
                        {produto.emp_nomefantasia}{" "}
                      </strong>
                    </p>
                    {produto.emp_celular !== "" ? (
                      <p
                        className="mb-0 text-left"
                        style={{ color: "#F58634" }}
                      >
                        {tela === 1 ? (
                          <a
                            target="blank"
                            className="text-success"
                            href={
                              "https://wa.me/55" +
                              contato_zap +
                              "?text=" +
                              encodeURIComponent(
                                produto.prod_nome +
                                  " De R$ " +
                                  produto.prod_preco +
                                  " por R$ " +
                                  pagar +
                                  " " +
                                  dominio +
                                  "/facebook.php?produto=" +
                                  produto.prod_id
                              )
                            }
                          >
                            <i class="fab fa-whatsapp"></i>{" "}
                            <span style={{ color: "#F58634" }}>
                              {produto.emp_celular}
                            </span>
                          </a>
                        ) : (
                          produto.emp_celular
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                    {produto.prod_promocao > 0  &&  produto.prod_promocao != produto.prod_preco ?  (
                      <p>
                        <span style={{ color: "#808080", fontSize: "12px" }}>
                          de{" "}
                          <del>
                            R$ {parseFloat(produto.prod_preco).toFixed(2)}
                          </del>{" "}
                          por{" "}
                        </span>
                        <strong className="text-primary h4">
                          R$ {parseFloat(pagar).toFixed(2)}
                        </strong>
                      </p>
                    ) : (
                      <p>
                        <strong className="text-primary h4">
                          R$ {parseFloat(produto.prod_preco).toFixed(2)}
                        </strong>
                      </p>
                    )}
                    <p>
                      <strong className="text-primary">
                        A pagar R$ {parseFloat(pagar).toFixed(2)}
                      </strong>
                    </p>
                    {/*<div className='mb-1 d-flex'>
                  <Dimensoes />
                </div>
              */}
                    <div className="mb-5">
                      <div
                        className="input-group mb-3"
                        style={{ maxWidth: "120px" }}
                      >
                        <div className="input-group-prepend">
                          <button
                            className="btn btn-outline-primary js-btn-minus"
                            type="button"
                            onClick={(e) => this.rm(produto)}
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="text"
                          className="form-control text-center"
                          placeholder="0"
                          value={produto.prod_qtde}
                          aria-label="Example text with button addon"
                          aria-describedby="button-addon1"
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-primary js-btn-plus"
                            type="button"
                            onClick={(e) => this.add(produto)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-primary mr-1"
                        onClick={(e) => this.addData(produto)}
                      >
                        Separar <i className="fas fa-shopping-cart"></i>
                      </button>
                      <Link to="/">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary mr-1"
                        >
                          Voltar <i className="fas fa-hand-point-left"></i>
                        </button>
                      </Link>
                      <div className="btn-group" role="group">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-sm btn-success mr-2 dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Compartilhar"
                        >
                          <i className="fas fa-share-alt"></i>
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                        >   
                          <a
                            className="dropdown-item"
                            target="_blank"
                            href={
                              "https://api.whatsapp.com/send?text=" +
                              encodeURIComponent(
                                textoDeCompartilhamento +
                                  dominio +
                                  "/facebook.php?produto=" +
                                  produto.prod_id
                              )
                            }
                          >
                            <i className="fab fa-whatsapp"></i> Whatsapp
                          </a>
                         
                          <a
                            className="dropdown-item"
                            target="_blank"
                            href={
                              "https://twitter.com/share?ref_src=twsrc%5Etfw&url=" +
                              dominio +
                              "/facebook.php?produto=" +
                              produto.prod_id +
                              "&text=" +
                              encodeURIComponent(
                                textoDeCompartilhamento
                              )
                            }
                          >
                            <i className="fab fa-twitter"></i> Twitter
                          </a>
                        
                          <a
                            className="dropdown-item"
                            target="_blank"
                            href={
                              "https://www.facebook.com/dialog/feed?" +
                              "app_id=554266545231005" +
                              "&display=popup" +
                              "&link=https://keepdelivery.com.br/facebook.php?produto=" +
                              produto.prod_id +
                              "&redirect_uri=https://keepdelivery.com.br/produto" +
                              produto.prod_id
                            }
                          >
                            <i className="fab fa-facebook-square"></i> Facebook
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <Footer />
        </>
      );
    }
  }
}
export default produto;
