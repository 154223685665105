import React, { Component } from "react";
import { Link } from "react-router-dom";
import { mask_numeros, mask_Moeda } from "../../plugins/Mascara/Mascara";
import "../../styles/Produtos.css";
import "../../styles/checkbox-on-of.css";
import Select from "react-select";
import {
  dominio,
  api,
  handleError,
  handleSuccess,
  handleInfo,
} from "../../plugins/Config/Config";
import marca from "../../../imagens/marca.png";
import sem_imagem from "../../../imagens/sem_imagem.jpg";

// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery

const mask_Peso = (v) => {
  if (!v) {
    return "";
  }
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito

  v = v.replace(/^([0-9]{3}\.?){3}-[0-9]{3}$/, "$1.$2");

  //v=v.replace(/(\d{3})(\d)/g,"$1,$2")

  v = v.replace(/(\d)(\d{3})$/, "$1.$2"); //Coloca ponto antes dos 2 últimos digitos

  return v;
};

export default class Produto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didmount: true,
      selectedCategoria: "",
      statusCategoria: [],
      selectedSubcategoria: "",
      statusSubcategoria: [],
      Produtos: [],
      uploadPercentage: 0,
      preloader: false,
      atualiza_produtos: "",
      prod_prova: "F",
      prod_destaque: 0,
      model: {
        prod_id: 0,
        prod_empresa: "",
        prod_nome: "",
        prod_preco: "",
        prod_promocao: "",
        prod_qtde: "",
        prod_foto: null,
        prod_peso: "",
        prod_descricao: "",
        prod_youtube: "",
      },
    };
  }
  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };
  changeState = (value) => {
   // console.log(value);
    this.setState({
      prod_prova: value,
    });
  };
  changeDestaque = (value) => {
   // console.log(value);
    this.setState({
      prod_destaque: value,
    });
  };
  Get_categoria = () => {
    api
      .get("api/categoria")
      .then((response) => {
        const statusCategoria = [];

        for (var i = 0; i < response.data.length; i++) {
          statusCategoria.push({
            value: response.data[i].pc_id,
            label: response.data[i].pc_descricao,
          });
        }

        this.setState({ statusCategoria });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // funcao para alterar o valor do campo select
  handleChange = (selectedCategoria) => {
    // console.log('valor do estado de selectedGrupo: '+ JSON.stringify(selectedGrupo.value));
    this.setState({ selectedCategoria }); // atualizando o state de  selectedGrupo
    this.setState({
      selectedSubcategoria: { value: -1, label: "Carregando..." },
    });
    api
      .get("api/subcategoria/0/" + selectedCategoria.value)
      .then((response) => {
      //  console.log(response);
        const statusSubcategoria = [];
        const selectedSubcategoria = [];
        this.setState({ statusSubcategoria });
        this.setState({ selectedSubcategoria });
        // construindo o corpo das options do select com as variaves vindas da api atraves do axios
        for (var i = 0; i < response.data.length; i++) {
          //  if (selectedCategoria.value == response.data[i].pcs_produtocategoria) {

          statusSubcategoria.push({
            value: response.data[i].pcs_id,
            label: response.data[i].pcs_descricao,
          });
          //  }
        }
        this.setState({ statusSubcategoria }); // salvando o novo state
      })
      .catch((error) => {
        const selectedSubcategoria = [];
        const statusSubcategoria = [];
        statusSubcategoria.push({
          value: "-10",
          label: "Nenhum SubGrupo cadastrado",
        });
        selectedSubcategoria.push({
          value: "-10",
          label: "Nenhum SubGrupo cadastrado",
        });

        // console.log('response de cliente é' + JSON.stringify('model do select' + model));
        this.setState({ statusSubcategoria }); // salvando o novo state
        this.setState({ selectedSubcategoria }); // salvando o novo state
      });
  };

  handleChangecliente = (selectedSubcategoria) => {
    this.setState({ selectedSubcategoria });
  };

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    model.prod_nome = model.prod_nome.toUpperCase();
    model.prod_preco = mask_Moeda(model.prod_preco);
    model.prod_promocao = mask_Moeda(model.prod_promocao);
    model.prod_qtde = mask_numeros(model.prod_qtde);
    model.prod_peso = mask_Peso(model.prod_peso);
    this.setState({ model });
  };

  limpar_moeda = (v) => {
    if (v) {
      v = v.split(".").join("");
      v = v.replace(",", ".");
      if (v.indexOf(".") >= 0) {
        return v;
      } else {
        let tamanho = v.length;
        return v.substr(0, tamanho - 2) + "." + v.substr(tamanho - 2, tamanho);
      }
    }
  };

  deletar_upload_imagem = (key) => {
    const Produtos = this.state.Produtos;
    const model = this.state.model;

    //  console.log('antes ' + Produtos[0].prod_caminhofoto); // Apenas para demonstrar

    let prod_caminhofoto = [];
    var atualiza_produtos = [];
    var els = document.querySelectorAll("output input");
    // var els = document.getElementsByClassName('checkbox_imagem');
    //  console.log(els);

    //console.log(els.length);
    for (var i = 0; i < els.length; i++) {
      if (els[i].checked) {
        //  console.log(els[i].value);
        prod_caminhofoto.push(els[i].value);
      }
      if (!els[i].checked) {
        atualiza_produtos.push(els[i].value);
      }
    }
    this.setState({ atualiza_produtos });

    // console.log(prod_caminhofoto);
    let data = {
      prod_caminhofoto: prod_caminhofoto,
    };
    //   console.log(JSON.stringify(data));
    // return false;
    api({
      method: "DELETE",
      url: "api/produto/" + model.prod_id + "/1",
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
       // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent < 100) {
          this.setState({ uploadPercentage: percent });
        }
      },
    })
      .then((response) => {
        handleSuccess(response.data.message);
        this.setState({ uploadPercentage: 0 });
        let atualiza_produtos = this.state.atualiza_produtos;

        var prod_caminhofotos = atualiza_produtos;
        let spans = "";
        for (let i = 0; i < prod_caminhofotos.length; i++) {
          //  console.log(prod_caminhofotos[i] + ' ' + i);
          spans +=
            '<input type="checkbox" className="checkbox_imagem" value="' +
            prod_caminhofotos[i] +
            '" id="' +
            prod_caminhofotos[i] +
            '" />';
          spans += "<label for=" + prod_caminhofotos[i] + ">";
          spans +=
            '<img className="thumb-upload" id=' +
            prod_caminhofotos[i] +
            " src=" +
            dominio +
            "/imagens/" +
            model.prod_id +
            "/" +
            prod_caminhofotos[i] +
            " />";
          spans += "</label>";
        }
        document.getElementById("list").innerHTML = spans;

        //    this.Get_produto();
        // this.Limpar();
      })
      .catch((error) => {
        if (error.response) {
          this.catchResponse(error.response.status);
        }
      });
  };

  save = () => {
    const model = this.state.model;
    let prod_caminhofoto = [];
    const categoria = this.state.selectedCategoria;
    const subcategoria = this.state.selectedSubcategoria;
    let prod_prova = this.state.prod_prova;
    let prod_destaque = this.state.prod_destaque;
    if (
      model.prod_nome !== "" &&
      model.prod_preco !== "" &&
      model.prod_qtde !== "" &&
      categoria.value !== undefined &&
      subcategoria.value !== undefined
    ) {
      var data_uri = document.querySelectorAll("output span img");
      // console.log(data_uri);
      if (
        document.getElementById("prod_foto").files.length === 0 &&
        model.prod_id === 0
      ) {
        handleInfo("Cadastre ao menos uma imagem para este produto");
        return false;
      }

      for (let i = 0; i < data_uri.length; i++) {
        prod_caminhofoto.push(data_uri[i].src);
      }

      var prod_promocao = "";
      if (model.prod_promocao !== "") {
        prod_promocao = this.limpar_moeda(model.prod_promocao);
      } else {
        prod_promocao = 0;
      }

      // let prod_caminhofoto = document.getElementById('imagem').value.replace(/separador/g, '');

      let data = {
        prod_id: parseInt(model.prod_id),
        prod_empresa: parseInt(localStorage.getItem("id")),
        prod_nome: model.prod_nome,
        prod_preco: this.limpar_moeda(model.prod_preco),
        prod_promocao: prod_promocao,
        prod_qtde: parseInt(model.prod_qtde),
        prod_caminhofoto: prod_caminhofoto,
        prod_produtocategoria: categoria.value,
        prod_produtocategoriasubgrupo: subcategoria.value,
        prod_prova: prod_prova,
        prod_destaque: prod_destaque,
        prod_peso: 0,
        prod_descricao: model.prod_descricao,
        prod_youtube: model.prod_youtube,
      };

      /*  console.log(
        "data eh: " +
          JSON.stringify(data) +
          " " +
          subcategoria.value +
          " " +
          JSON.stringify(data_uri[0])
      );
      return false;*/
      //  console.log('status eh: ' + JSON.stringify(product.cli_fone));
      let method = "";
      if (model.prod_id === 0) {
        method = "post";
      } else {
        method = "put";
      }

      api({
        method: method,
        url: "api/produto",
        data: data,
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
        //  console.log(`${loaded}kb of ${total}kb | ${percent}%`);

          if (percent < 100) {
            this.setState({ uploadPercentage: percent });
          }
        },
      })
        .then((response) => {
          if (response.data.status === "erro") {
            handleError(response.data.message);
            this.setState({ uploadPercentage: 0 });
          } else {
            handleSuccess(response.data.message);

            if (method === "post") {
              this.setState({ uploadPercentage: 100 }, () => {
                setTimeout(() => {
                  this.setState({ uploadPercentage: 0 });
                }, 1000);
              });
              this.Limpar();
              this.Get_produto();
            } else {
              let spans = "";
              let prod_caminhofoto = response.data.imagens_atualizadas.split(
                ","
              );
              for (let i = 0; i < prod_caminhofoto.length - 1; i++) {
                if (prod_caminhofoto[i] !== null) {
                  spans +=
                    '<input type="checkbox" className="checkbox_imagem" value="' +
                    prod_caminhofoto[i] +
                    '" id="' +
                    prod_caminhofoto[i] +
                    '" />';
                  spans += "<label for=" + prod_caminhofoto[i] + ">";
                  spans +=
                    '<img className="thumb-upload" id=' +
                    prod_caminhofoto[i] +
                    " src=" +
                    dominio +
                    "/imagens/" +
                    response.data.id +
                    "/" +
                    prod_caminhofoto[i] +
                    " />";
                  spans += "</label>";
                }
              }
              document.getElementById("list").innerHTML = spans;
              this.Limpar();
            }

            if (prod_prova === "T") {
              document.getElementById("prod_prova1").checked = true;
            } else {
              document.getElementById("prod_prova2").checked = true;
            }
            if (prod_destaque === 1) {
              document.getElementById("prod_destaque1").checked = true;
            } else {
              document.getElementById("prod_destaque2").checked = true;
            }
            document.getElementById("formulario_produtos").style.display =
              "none";
            document.getElementById("btn_cancelar").style.display = "none";
            document.getElementById("btn_cadastrar").style.display = "";
            this.Get_produto();
          }
        })
        .catch((error) => {
          //handleError(error.response.data.message);
          //  console.log(error);
          return false;
        });
    } else {
      handleInfo("Preencha todos os campos");
      return false;
    }
  };

  _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
  }

  delete = (produto_id) => {
    api({
      method: "DELETE", //you can set what request you want to be
      url: "api/produto/" + produto_id,
      data: produto_id,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        this.Limpar();
        document.getElementById("formulario_produtos").style.display = "none";
        document.getElementById("btn_cancelar").style.display = "none";
        document.getElementById("btn_cadastrar").style.display = "";
        //   const empresa = this.state.Empresa.filter(corp => corp.emp_id !== emp_id);
        //  this.setState({ products,  message: { text: 'Gerente deletado com sucesso.', alert: 'danger' } });
        //  this.timerMessage(3000);
        // this.setState({ empresa });
        this.Get_produto();
        handleInfo(response.data.message);
      })
      .catch((error) => {
        handleError(error);
        console.log(error);
      });
  };

  _handleImageChange(e) {
    e.preventDefault();

    document.getElementById("list").innerHTML = "";

    var fileInput = document.getElementById("prod_foto");
    var fileList = [];

    if (fileInput.files.length > 5) {
      handleError("No máximo 5 imagem");
      document.getElementById("prod_foto").value = "";
      return false;
    }
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }

    for (var i = 0, f; (f = fileList[i]); i++) {
      //    this.GerarDataURI(f, i);
     // console.log(JSON.stringify(f.name) + " " + JSON.stringify(fileList[i]));

      // Only process image files.
      if (!f.type.match("image.*")) {
        continue;
      }

      var reader = new FileReader();

      reader.onload = (function (theFile) {
        return function (e) {
          (function () {
            var image = new Image();
            image.addEventListener(
              "load",
              function () {
                // tamanho original

                var canvas = document.createElement("canvas"),
                  ctx = canvas.getContext("2d");
                // coordenadas origem (source)

                // tamanho destino
                var MAX_WIDTH = 400;
                var MAX_HEIGHT = 400;
                var width = this.width;
                var height = this.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(this, 0, 0, width, height);

                let ext = theFile.name.split(".");

                var dataurl = canvas.toDataURL("image/" + ext[ext.length - 1]);
                //  console.log(dataurl);
                var span = document.createElement("span");
                span.innerHTML = [
                  '<img class="img_data_uri" style="max-width: 150px;max-height: 150px;" name="img_data_uris" src="',
                  dataurl,
                  '" title="',
                  escape(theFile.name),
                  '"/>',
                ].join("");
                document.getElementById("list").insertBefore(span, null);
              },
              false
            );
            image.src = e.target.result;
          })();
        };
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  }

  Limpar = () => {
    this.setState({
      model: {
        prod_id: 0,
        prod_empresa: "",
        prod_nome: "",
        prod_preco: "",
        prod_promocao: "",
        prod_qtde: "",
        prod_foto: null,
        prod_peso: "",
        prod_descricao: "",
        prod_youtube: "",
      },
    });
    this.setState({ selectedCategoria: null });
    this.setState({ prod_prova: "F" });
    this.setState({ prod_destaque: 0 });
    this.setState({ selectedSubcategoria: null });
    document.getElementById("list").innerHTML = "";
    document.getElementById("prod_foto").value = "";
  };
  Get_produto = () => {
    this.setState({ uploadPercentage: 0 });
    api
      .get("api/produto/" + localStorage.getItem("id") + "/nome")
      .then((response) => {
       // console.log(response.data);
        this.setState({ Produtos: response.data });
      })
      .catch((error) => {
        const Produtos = [];
        this.setState({ Produtos });
        handleInfo("Nenhum produto encontrado");
        return false;
      });
  };

  alterar = (produto) => {
   // console.log(produto.prod_prova);
    if (produto.prod_prova === "T") {
      document.getElementById("prod_prova1").checked = true;
    } else {
      document.getElementById("prod_prova2").checked = true;
    }
    if (produto.prod_destaque === 1) {
      document.getElementById("prod_destaque1").checked = true;
    } else {
      document.getElementById("prod_destaque2").checked = true;
    }
    var prod_promocao = "";
    if (produto.prod_promocao !== "") {
      prod_promocao = mask_Moeda(produto.prod_promocao);
    } else {
      prod_promocao = 0;
    }
    //  console.log(JSON.stringify(produto));
    this.setState({
      model: {
        prod_id: produto.prod_id,
        prod_empresa: produto.prod_empresa,
        prod_nome: produto.prod_nome,
        prod_preco: mask_Moeda(produto.prod_preco),
        prod_promocao: prod_promocao,
        prod_qtde: produto.prod_qtde,
        prod_descricao: produto.prod_descricao,
        prod_youtube: produto.prod_youtube,
      },
    });
    var spans = "";
    // let imagem = dominio+"/"+produto.prod_caminhofoto;
    if (produto.prod_caminhofoto) {
      var prod_caminhofoto = produto.prod_caminhofoto.split(",");
      for (let i = 0; i < prod_caminhofoto.length - 1; i++) {
        if (prod_caminhofoto[i] !== null) {
          spans +=
            '<input type="checkbox" Name="checkbox_imagem" value="' +
            prod_caminhofoto[i] +
            '" id="' +
            prod_caminhofoto[i] +
            '" />';
          spans += "<label for=" + prod_caminhofoto[i] + ">";
          spans +=
            '<img class="thumb-upload" id=' +
            prod_caminhofoto[i] +
            " src=" +
            dominio +
            "/imagens/" +
            produto.prod_id +
            "/" +
            prod_caminhofoto[i] +
            " />";
          spans += "</label>";
        }
      }

      document.getElementById("list").innerHTML = spans;
    }
    this.setState({
      selectedSubcategoria: {
        value: produto.pcs_id,
        label: produto.pcs_descricao,
      },
    });
    if (spans === "") {
      document.getElementById("list").innerHTML = "";
    }
    window.scrollTo(0, 0);
    this.setState({ prod_prova: produto.prod_prova });
    this.setState({ prod_destaque: produto.prod_destaque });
   // console.log(this.state.statusCategoria);
    var selectedCategoria = this.state.statusCategoria.filter(
      ({ value }) => value === produto.prod_produtocategoria
    );
    setTimeout(() => {
      this.setState({ selectedCategoria: selectedCategoria[0] });
    }, 200);
    document.getElementById("formulario_produtos").style.display = "";
  };
  cadastrar = () => {
    this.Limpar();
    if (this.state.prod_prova === "T") {
      document.getElementById("prod_prova1").checked = true;
    } else {
      document.getElementById("prod_prova2").checked = true;
    }
    if (this.state.prod_destaque === 1) {
      document.getElementById("prod_destaque1").checked = true;
    } else {
      document.getElementById("prod_destaque2").checked = true;
    }
    document.getElementById("formulario_produtos").style.display = "";
    document.getElementById("btn_cancelar").style.display = "";
    document.getElementById("btn_cadastrar").style.display = "none";
  };
  cancelar = () => {
    this.Limpar();
    document.getElementById("formulario_produtos").style.display = "none";
    document.getElementById("btn_cancelar").style.display = "none";
    document.getElementById("btn_cadastrar").style.display = "";
  };

  componentDidMount = () => {
    if (this.state.didmount === true) {
      this.setState({ didmount: false });

      // const { prod_id } = this.props.match.params;
      this.Get_produto();
      this.Get_categoria();

      /*  if (prod_id > 0) {
      
                  api.get('api/produto/' + localStorage.getItem("id") + '/0/0/' + prod_id, {
                      auth: {
      
                          username: localStorage.getItem("username"),
                          password: localStorage.getItem("password")
                      }
                  })
                      .then((response) => {
                          console.log(response.data);
                        
                          this.setState({
                              model: {
                                  prod_id: response.data[0].prod_id,
                                  prod_empresa: response.data[0].prod_empresa,
                                  prod_nome: response.data[0].prod_nome,
                                  prod_preco: response.data[0].prod_preco,
                                  prod_promocao: response.data[0].prod_promocao,
                                  prod_qtde: response.data[0].prod_qtde,
                                  prod_peso: response.data[0].prod_peso,
                                  prod_caminhofoto: response.data[0].prod_caminhofoto,
                                  prod_descricao: response.data[0].prod_descricao
                              }
                          });
                 
                     this.setState({prod_prova: response.data[0].prod_prova });
                        var selectedCategoria  =  this.state.statusCategoria.filter(({value}) => value === response.data[0].prod_produtocategoria);             
                             this.setState({selectedCategoria: selectedCategoria[0] });
              
               const data_subcategoria_value =  response.data[0].prod_produtocategoriasubgrupo
                        api.get('api/subcategoria/0', {
                          auth: {
                              username: localStorage.getItem("username"),
                              password: localStorage.getItem("password")
                          }
                      })
                          .then((response) => {
              
                              const statusSubcategoria = []
                          
                              this.setState({ statusSubcategoria })
                          
                              // construindo o corpo das options do select com as variaves vindas da api atraves do axios
                              for (var i = 0; i < response.data.length; i++) {
                                  if (this.state.selectedCategoria.value == response.data[i].pcs_produtocategoria) {
              
                                      statusSubcategoria.push({ value: response.data[i].pcs_id, label: response.data[i].pcs_descricao });
                                  }
                              }
                              this.setState({ statusSubcategoria })// salvando o novo state
                              const selectedSubcategoria  =  this.state.statusSubcategoria.filter(({value}) => value === data_subcategoria_value);             
                              this.setState({selectedSubcategoria: selectedSubcategoria[0] });
                          })
                          .catch((error) => {
              
                              const selectedSubcategoria = [];
                              const statusSubcategoria = [];
                              statusSubcategoria.push({ value: '-10', label: 'Nenhum SubGrupo cadastrado' });
                              selectedSubcategoria.push({ value: '-10', label: 'Nenhum SubGrupo cadastrado' });
              
                              // console.log('response de cliente é' + JSON.stringify('model do select' + model));
                              this.setState({ statusSubcategoria })// salvando o novo state
                              this.setState({ selectedSubcategoria })// salvando o novo state
                          })
      
                    
             
       
                   
                          let spans = '';
                          if(response.data[0].prod_caminhofoto){
                         let prod_caminhofoto = response.data[0].prod_caminhofoto.split(',');
                          for (let i = 0; i < prod_caminhofoto.length - 1; i++) {
                              if (prod_caminhofoto[i] !== null) {
                                  spans += '<input type="checkbox" class="checkbox_imagem" value="' + prod_caminhofoto[i] + '" id="' + prod_caminhofoto[i] + '" />';
                                  spans += '<label for=' + prod_caminhofoto[i] + '>';
                                  spans += '<img  class="thumb-upload" id=' + prod_caminhofoto[i] + ' src=' + dominio + '/imagens/' + response.data[0].prod_id + '/' + prod_caminhofoto[i] + ' />';
                                  spans += '</label>';
      
                              }
                          }
                          document.getElementById('list').innerHTML = spans;
                      }
                      })
                      .catch((error) => {
                          handleInfo('Nenhum produto encontrado');
                          return false;
                      })
      
                  this.Get_produto();
              } else {
                 this.setState({
                      model: {
                          prod_id: 0,
                          prod_empresa: '',
                          prod_nome: '',
                          prod_preco: '',
                          prod_promocao: '',
                          prod_qtde: '',
                          prod_foto: null,
                          prod_descricao: '',
                      }
                  })
                  document.getElementById('list').innerHTML = '';
              }*/
    }
  };
  FiltrarCampos() {
    var input, filter, table, tr, td, i;
    input = document.getElementById("filtro");
    filter = input.value.toUpperCase();
    table = document.getElementById("produtos");
    tr = table.getElementsByTagName("tr");
    //vc já usou var lá em cima não precisa usar de novo
    for (i = 1; i < tr.length; i++) {
      var tds = tr[i].getElementsByTagName("td");
      var flag = false;
      for (var j = 0; j < tds.length - 2; j++) {
        td = tds[j];

        if (td.textContent.toUpperCase().indexOf(filter) > -1) {
          flag = true;
        }
      }
      if (flag) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  render() {
    const {
      Produtos,
      uploadPercentage,
      selectedCategoria,
      selectedSubcategoria,
      statusCategoria,
      statusSubcategoria,
    } = this.state;

    return (
      <>
        <div className="container">
          <div id="formulario_produtos" style={{ display: "none" }}>
            <h2
              className="font-weight-bold text-center"
              id="produto_titulo"
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              {" "}
              Produtos{" "}
            </h2>

            <form style={{ marginTop: "30px" }}>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 ">
                    <label htmlFor="prod_nome">Nome do Produto:</label>
                    <input
                      id="prod_nome"
                      className="form-control"
                      required="required"
                      type="text"
                      maxLength="100"
                      value={this.state.model.prod_nome}
                      placeholder="Nome do Produto"
                      onChange={(e) => this.setValues(e, "prod_nome")}
                    />
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="prod_preco">Preço:</label>
                    <input
                      id="prod_preco"
                      maxLength="14"
                      className="form-control"
                      required="required"
                      type="text"
                      value={this.state.model.prod_preco}
                      placeholder="Preço"
                      onChange={(e) => this.setValues(e, "prod_preco")}
                    />
                  </div>
                  <div className="col-md-2" style={{ display: "" }}>
                    <label htmlFor="prod_promocao">Preço de Promoção:</label>
                    <input
                      id="prod_promocao"
                      maxLength="14"
                      className="form-control"
                      required="required"
                      type="text"
                      value={this.state.model.prod_promocao}
                      placeholder="Preço de Promoção"
                      onChange={(e) => this.setValues(e, "prod_promocao")}
                    />
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="prod_qtde">Quantidade:</label>
                    <input
                      id="prod_qtde"
                      className="form-control"
                      required="required"
                      type="text"
                      value={this.state.model.prod_qtde}
                      placeholder="Quantidade"
                      onChange={(e) => this.setValues(e, "prod_qtde")}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-2">
                    <label htmlFor="supervisor">Categoria:</label>
                    <Select
                      id="supervisor"
                      value={selectedCategoria}
                      onChange={this.handleChange}
                      options={statusCategoria}
                    />
                  </div>
                  <div className="col-md-2" style={{ display: "none" }}>
                    <label htmlFor="prod_peso">Peso (KG):</label>
                    <input
                      id="prod_peso"
                      className="form-control"
                      type="text"
                      value={this.state.model.prod_peso}
                      placeholder="Peso"
                      onChange={(e) => this.setValues(e, "prod_peso")}
                    />
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="cliente">SubCategoria:</label>
                    <Select
                      id="cliente"
                      value={selectedSubcategoria}
                      onChange={this.handleChangecliente}
                      options={statusSubcategoria}
                    />
                  </div>
                  <div className="col-md-4 ">
                    <label htmlFor="prod_youtube">Link de video online:</label>
                    <input
                      id="prod_youtube"
                      className="form-control"
                      required="required"
                      maxLength="250"
                      type="text"
                      value={this.state.model.prod_youtube}
                      placeholder="Link"
                      onChange={(e) => this.setValues(e, "prod_youtube")}
                    />
                  </div>
                  <div className="col-md-2">
                    <legend
                      title="Caso esteja marcado como sim o produto em questão aparecera na home do KeepDelivery,lembrando que o limite máximo por empresa é 2 produtos marcados como sim."
                      style={{ fontSize: "13px" }}
                    >
                      Destaque?
                      <span style={{ fontSize: "10px" }}>
                        <b>(max 2 itens)</b>
                      </span>
                    </legend>
                    <div class="custom-control custom-radio">
                      <input
                        onClick={(e) => this.changeDestaque(1)}
                        type="radio"
                        id="prod_destaque1"
                        name="prod_destaque"
                        value="1"
                        class="custom-control-input"
                      />
                      <label
                        style={{ opacity: "1.0" }}
                        class="custom-control-label"
                        htmlFor="prod_destaque1"
                      >
                        Sim
                      </label>
                    </div>
                    <div
                      style={{ marginTop: "5px" }}
                      class="custom-control custom-radio"
                    >
                      <input
                        onClick={(e) => this.changeDestaque(0)}
                        type="radio"
                        value="1"
                        id="prod_destaque2"
                        name="prod_destaque"
                        class="custom-control-input"
                      />
                      <label
                        style={{ opacity: "1.0" }}
                        value="0"
                        class="custom-control-label"
                        htmlFor="prod_destaque2"
                      >
                        Não
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4" style={{ display: "none" }}>
                    <div className="mid">
                      <span>Levar para prova?</span>
                      <label className="rocker rocker-small">
                        {this.state.prod_prova === "T" ? (
                          <input
                            type="checkbox"
                            checked
                            value="T"
                            onChange={(e) => this.changeState()}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            value="F"
                            onChange={(e) => this.changeState()}
                          />
                        )}

                        <span className="switch-left">Sim</span>
                        <span className="switch-right">Não</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <legend style={{ fontSize: "13px" }}>
                      Levar para prova?
                    </legend>
                    <div class="custom-control custom-radio">
                      <input
                        onClick={(e) => this.changeState("T")}
                        type="radio"
                        id="prod_prova1"
                        name="prod_prova"
                        class="custom-control-input"
                      />
                      <label
                        style={{ opacity: "1.0" }}
                        value="T"
                        class="custom-control-label"
                        htmlFor="prod_prova1"
                      >
                        Sim
                      </label>
                    </div>
                    <div
                      style={{ marginTop: "5px" }}
                      class="custom-control custom-radio"
                    >
                      <input
                        onClick={(e) => this.changeState("F")}
                        type="radio"
                        id="prod_prova2"
                        name="prod_prova"
                        class="custom-control-input"
                      />
                      <label
                        style={{ opacity: "1.0" }}
                        value="F"
                        class="custom-control-label"
                        htmlFor="prod_prova2"
                      >
                        Não
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-12">
                    <label htmlFor="prod_descricao">
                      Descrição do produto:
                    </label>
                    <br />
                    <textarea
                      cols="135"
                      rows="5"
                      id="prod_descricao"
                      value={this.state.model.prod_descricao}
                      placeholder="Descrição do produto"
                      onChange={(e) => this.setValues(e, "prod_descricao")}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-4">
                    <label htmlFor="prod_foto">
                      <b>Imagens:</b>
                    </label>
                    <br />
                    <input
                      type="file"
                      name="prod_foto[]"
                      style={{ backgroundColor: "#ecf0f4", border: "0px" }}
                      accept=".jpg, .jpeg, .png, .webp"
                      id="prod_foto"
                      multiple
                      onChange={this._handleImageChange}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginBottom: "15px" }} className="text-center">
                {this.state.model.prod_id < 1 ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.save}
                  >
                    Cadastrar&nbsp;<i className="fas fa-save"></i>{" "}
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.save}
                    >
                      Alterar&nbsp;<i className="fas fa-save"></i>{" "}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger ml-2"
                      onClick={(e) => this.deletar_upload_imagem(e)}
                    >
                      Deletar imagens seleciondas &nbsp;
                      <i className="fas fa-save"></i>{" "}
                    </button>
                  </>
                )}
              </div>
            </form>
            {uploadPercentage > 0 ? (
              <>
                <br />
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={uploadPercentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: uploadPercentage + "%" }}
                  >
                    <b>{uploadPercentage}</b>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <img id="output"></img>
            <output id="list"></output>
          </div>
          <div style={{ marginBottom: "25px" }}>
            <button
              type="button"
              id="btn_cadastrar"
              className="btn btn-primary"
              style={{ fontSize: "12px", marginTop: "10px", marginLeft: "3px" }}
              onClick={(e) => this.cadastrar()}
            >
              <b>
                Adicionar produto&nbsp;<i class="fas fa-plus"></i>
              </b>{" "}
            </button>
            <button
              type="button"
              id="btn_cancelar"
              className="btn btn-danger"
              style={{
                fontSize: "12px",
                marginTop: "10px",
                marginLeft: "3px",
                display: "none",
              }}
              onClick={(e) => this.cancelar()}
            >
              <b>
                Cancelar cadastro&nbsp;<i class="fas fa-times"></i>
              </b>{" "}
            </button>
          </div>

          {Produtos.length > 0 ? (
            <>
              <form style={{ marginBottom: "30px" }}>
                <div className="form-row">
                  <div style={{ position: "absolute" }} className="col-md-4 ">
                    <label for="filtro">Filtrar Tabela:</label>
                    <input
                      id="filtro"
                      className="form-control"
                      onChange={(e) => this.FiltrarCampos()}
                      required="required"
                      type="text"
                      placeholder="Termo..."
                    />
                  </div>

                  <div className="col-md-4"></div>
                </div>
              </form>
              <br />
              <div className="table-responsive">
                <table
                  style={{ marginTop: "30px", marginBottom: "50px" }}
                  id="produtos"
                  className="table table-hover table-bordered text-center"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>Imagem</th>
                      <th>Nome</th>
                      <th>Categoria</th>
                      <th>SubCategoria</th>
                      <th>Preço</th>
                      <th>Promoção</th>
                      <th>Qtd produtos</th>
                      <th>Destaque</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Produtos.map(
                      function (produto, i) {

                        var textoDeCompartilhamento = '';
                        if(produto.prod_promocao > 0  &&  produto.prod_promocao != produto.prod_preco){
                     textoDeCompartilhamento = produto.prod_nome +" De R$ " +produto.prod_preco +" por R$ " +produto.prod_promocao+ "  ";
                       }else{
                    textoDeCompartilhamento = produto.prod_nome +" por R$ " +produto.prod_preco+ "  ";
                     
                       }

                        if (produto.prod_caminhofoto) {
                          let prod_caminhofoto = produto.prod_caminhofoto.split(
                            ","
                          );
                          var img_caminho =
                            dominio +
                            "/imagens/" +
                            produto.prod_id +
                            "/" +
                            prod_caminhofoto[0];
                        } else {
                          var img_caminho = sem_imagem;
                        }

                        return (
                          <tr key={produto.prod_id}>
                            <td>
                              <img
                                style={{ maxHeight: "50px" }}
                                src={img_caminho}
                              />
                            </td>
                            <td>{produto.prod_nome}</td>
                            <td>{produto.pc_descricao}</td>
                            <td>{produto.pcs_descricao}</td>
                            <td>{mask_Moeda(produto.prod_preco)}</td>
                            <td>{mask_Moeda(produto.prod_promocao)}</td>
                            <td>{produto.prod_qtde}</td>
                            <td>
                              {produto.prod_destaque === 0 ? (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "10px",
                                    marginLeft: "3px",
                                  }}
                                  className="btn btn-secondary"
                                >
                                  Não
                                </span>
                              ) : (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "10px",
                                    marginLeft: "3px",
                                  }}
                                  className="btn btn-success"
                                >
                                  Sim
                                </span>
                              )}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-primary"
                                style={{
                                  fontSize: "12px",
                                  marginTop: "10px",
                                  marginLeft: "3px",
                                }}
                                onClick={(e) => this.alterar(produto)}
                              >
                                <i className="fas fa-save"></i>{" "}
                              </button>
                              {produto.emp_situacao === "A" ? (
                                <button
                                  className="btn btn-danger"
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "10px",
                                    marginLeft: "3px",
                                  }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Você tem certeza que deseja deletar o produto "' +
                                          produto.prod_nome +
                                          '" ?'
                                      )
                                    ) {
                                      this.delete(produto.prod_id);
                                    }
                                  }}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-secondary"
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "10px",
                                    marginLeft: "3px",
                                  }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Você tem certeza que deseja deletar o produto "' +
                                          produto.prod_nome +
                                          '" ?'
                                      )
                                    ) {
                                      this.delete(produto.prod_id);
                                    }
                                  }}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              )}
                              <ul
                                style={{ marginLeft: "5px", marginTop: "5px" }}
                                class="nav nav-tabs"
                              >
                                <li class="nav-item btn-primary dropdown">
                                  <a
                                    style={{
                                      color: "white",
                                      background: "#0e7bff",
                                    }}
                                    class="nav-link  dropdown-toggle"
                                    data-toggle="dropdown"
                                    href="#"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Compartilhar
                                  </a>
                                  <div class="dropdown-menu ">
                                    <a
                                      className="dropdown-item"
                                      target="blank"
                                      href={
                                        "https://www.facebook.com/dialog/feed?" +
                                        "app_id=554266545231005" +
                                        "&display=popup" +
                                        "&link=https://keepdelivery.com.br/facebook.php?produto=" +
                                        produto.prod_id +
                                        "&redirect_uri=https://keepdelivery.com.br/produto" +
                                        produto.prod_id
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary "
                                        id="btn_twitter"
                                      >
                                        <i className="fab fa-facebook-square"></i>
                                      </button>
                                      &nbsp;<b> Facebook</b>
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      target="_blank"
                                      href={
                                        "https://api.whatsapp.com/send?text=" +
                                        encodeURIComponent(
                                          textoDeCompartilhamento +
                                            dominio +
                                            "/facebook.php?produto=" +
                                            produto.prod_id
                                        )
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-success "
                                        id="btn_whatsapp"
                                      >
                                        <i class="fab fa-whatsapp"></i>
                                      </button>
                                      &nbsp;<b>Whatsapp</b>
                                    </a>

                                    <a
                                      className="dropdown-item"
                                      target="_blank"
                                      href={
                                        "https://twitter.com/share?ref_src=twsrc%5Etfw&url=" +
                                        dominio +
                                        "/facebook.php?produto=" +
                                        produto.prod_id +
                                        "&text=" +
                                        encodeURIComponent(
                                          textoDeCompartilhamento
                                        )
                                      }
                                    >
                                      {" "}
                                      <button
                                        type="button"
                                        className="btn btn-primary "
                                        id="btn_twitter"
                                      >
                                        <i class="fab fa-twitter"></i>
                                      </button>
                                      &nbsp;<b>Twitter</b>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      }.bind(this)
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}
