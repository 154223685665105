import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../imagens/logo.png'
import Footer from '../../../components/footer/footer'
import Navbar_public from '../../layouts/Navbar_public'
export default class Politica extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tela: 0
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.Verificatela()
    }
    Verificatela = () => {
        if (window.innerWidth < 640 || window.innerHeight < 480) {
            this.setState({ tela: 1 })
        } else if (window.innerWidth < 1024 || window.innerHeightt < 768) {
            this.setState({ tela: 2 })
        } else {

        }
    }
    render() {
        const { tela } = this.state
        document.title = 'Keepdelivery | Politica de Privacidade'
        return (
            <>
                {tela === 1 ?
                    <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ fontSize: '15px' }}>
                        <Link className="navbar-brand" to="/home">
                            <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#conteudoNavbarSuportado" aria-controls="conteudoNavbarSuportado" aria-expanded="false" aria-label="Alterna navegação">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="conteudoNavbarSuportado">
                            <ul className="navbar-nav mr-auto">
                                {localStorage.getItem('nome') === null ?
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/login_transportadora">Transportadora <span class='fas fa-truck'></span></Link>
                                    </li>
                                    : ''
                                }
                                {localStorage.getItem('nome') === null ?
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login_lojista">Lojista <span class='fas fa-user-tie'></span></Link>
                                    </li>
                                    : ''
                                }
                                {localStorage.getItem('nome') === null ?
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login_cliente">Cliente <span class='fas fa-user'></span></Link>
                                    </li>

                                    :
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {localStorage.getItem('nome')} <span className='fas fa-user' />
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {localStorage.getItem('nivel') === '2' ?
                                                <>
                                                    <Link className="nav-link" to="/rastreamento">Meus Pedidos<span className="far fa-list-alt"></span></Link>
                                                    <Link className="nav-link" to="/conta">Conta <span className="fas fa-users-cog"></span></Link>
                                                </>
                                                : ''
                                            }
                                            <Link className="nav-link" to="/logout">Sair <span className="fas fa-sign-out-alt"></span></Link>
                                        </div>
                                    </li>
                                }

                                <li className="nav-item">
                                    {//mudei aqui
                                        localStorage.getItem('carrinho') ?
                                            <Link className="dropdown-item link" style={{ textDecoration: 'none', display: 'block' }} to="/carrinho"><span className='icon-menu'>{localStorage.getItem('carrinho')}</span>&nbsp;<i style={{ color: '#11ca17', fontSize: '16px' }} className="fas fa-shopping-cart"></i></Link>
                                            : <Link className="dropdown-item link" style={{ textDecoration: 'none', display: 'block' }} to="/produtos"><span className='icon-menu'>0</span>&nbsp;<i style={{ color: '#11ca17', fontSize: '16px' }} className="fas fa-shopping-cart"></i></Link>
                                    }
                                </li>
                            </ul>
                        </div>
                    </nav> :
                    <Navbar_public />}
                <div class="site-wrap">
                    <div class="site-section border-bottom" data-aos="fade">
                        <div class="container">
                            <div class="row mb-5">
                                <div class="col-md-1"></div>
                                <div class="col-md-5">
                                    <div class="site-section-heading pt-3 mb-4">
                                        <h2 class="text-black">Política de privacidade</h2>
                                    </div>
                                    <p>Todas as suas informações pessoais e corporativas que, por ventura, sejam coletadas durante sua nevegação, serão usadas única e exclusivamente para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível.</p>
                                    <p>A garantia da confidencialidade dos dados pessoais e corporativos dos utilizadores do nosso site é de extrema importância para a Keep Delivery.</p>
                                    <p>Todas as informações pessoais e corporativas relativas a membros, assinantes, clientes ou visitantes que usem os serviços da keep Delivery serão tratadas em concordância com a Lei da Proteção de Dados Pessoais de 26 de outubro de 1998 (Lei n.º 67/98).</p>
                                    <p>A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou celular, endereço, data de nascimento e/ou outros.</p>
                                    <p>O uso dos serviços da Keep Delivery pressupõe a aceitação deste Acordo de privacidade. A Keep Delivery reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.</p>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-5">
                                    <div class="site-section-heading pt-3 mb-4">
                                        <h2 class="text-black">Os anúncios</h2>
                                    </div>
                                    <p>Tal como outros websites, coletamos e utilizamos as informações de navegação, além daquelas contidas em anúncios. As informações contidas nos anúncios incluem, dentre outras, informações sobre seu endereço IP (Internet Protocol), o seu ISP (Internet Service Provider), o browser que utilizou ao visitar o nosso website, o tempo da sua visita e que páginas visitou dentro do nosso website.</p>
                                    <div class="site-section-heading pt-3 mb-4">
                                        <h2 class="text-black">Cookies do Google</h2>
                                    </div>
                                    <p>
                                        O Google, como fornecedor de terceiros, utiliza cookies para exibir anúncios em nosso website.
                                        dos cookies, o Google pode exibir anúncios com base nas visitas que o leitor fez a outros websites na Internet.
                                        usuários podem visitar a Política de privacidade da rede de conteúdo e dos anúncios do Google.
                                        </p>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-5">
                                    <div class="site-section-heading pt-3 mb-4">
                                        <h2 class="text-black">Os Cookies e Web Beacons</h2>
                                    </div>
                                    <p>
                                        Utilizamos cookies para armazenar informação, tais como as suas preferências pessoas quando visita o nosso website. Isto poderá incluir um simples popup, ou uma ligação em um dos vários serviços que oferecemos.
                                        Em adição também podemos utilizar publicidade de terceiros no nosso website. Alguns destes publicitários, poderão utilizar tecnologias como os cookies e/ou web beacons quando publicitam no nosso website, o que fará com que esses publicitários (como o Google através do Google AdSense) também recebam a sua informação pessoal, como o endereço IP, o seu ISP, o seu browser, etc. Esta função é geralmente utilizada para geotargeting ou apresentar publicidade direcionada a um tipo de usuário.
                                        Você detém o poder de desligar os seus cookies, nas opções do seu browser, ou efetuando alterações nas ferramentas de programas Anti-Virus. No entanto, isso poderá alterar a forma como interage com o nosso website, ou outros websites. Isso poderá afetar ou não permitir que faça logins em programas, sites ou fóruns da nossa e de outras redes.
                                    </p>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-5">
                                    <div class="site-section-heading pt-3 mb-4">
                                        <h2 class="text-black">Ligações a Sites de terceiros</h2>
                                    </div>
                                    <p>
                                        A Keep Delivery se reserva ao direito de estabelecer ligações para outros sites e serviços, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo.
                                        Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}