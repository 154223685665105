import React from 'react';
import styled from 'styled-components';

function Footer() {
  return (
    <FooterContainer className="main-footer ">
      <div className="footer-middle">
        <div className="container" style={{marginTop:'0px'}}>
         
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <p className="text-xs-center" style={{margin:'5px',lineHeight: '0.6em'}}>
              &copy;{new Date().getFullYear()} Viaconect - All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
}
export default Footer;

const FooterContainer = styled.footer`
  .footer-middle {
    background: var(--mainDark);
    padding-top: 0rem;
    color: var(--mainWhite);
    position: fixed;
    bottom:0;
    left:0;
    width: 100%;
    z-index: 2;
  }

  .footer-bottom {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  ul li a {
    color: var(--mainGrey);
  }

  ul li a:hover {
    color: var(--mainLightGrey);
  }
  
`;
