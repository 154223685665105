import { Component } from 'react';

export default class Logout extends Component {

    componentDidMount() {        
      
        localStorage.removeItem('id');
        localStorage.removeItem('senha');      
        localStorage.removeItem('nome');
        localStorage.removeItem('Produtos');
        localStorage.removeItem('nivel');
        localStorage.removeItem('carrinho');
        localStorage.removeItem('list');
        localStorage.removeItem('ornanizado');
        localStorage.removeItem('obs');
        sessionStorage.removeItem("token");
        this.props.history.push('/');
     
    }

    render() {
        return null;
    }
}