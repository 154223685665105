import React, { Component } from "react";
import Navbar_public from "../layouts/Navbar_public";
import Select from "react-select";
import FacebookLogin from "react-facebook-login";
import {
  mask_tel_celular_area,
  mask_CNPJ,
  mask_minusculo_sem_espaco,
  validar_Email,
} from "../plugins/Mascara/Mascara";
import {
  api,
  handleError,
  handleInfo,
  handleSuccess,
} from "../plugins/Config/Config";
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
import "../styles/styles.css";
export default class Login_lojista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      statusOptions: [],
      Empresa: [],
      photoIndex: 0,
      formulario: 0,
      preloader: false,
      isLoggedIn: false,
      userID: "",
      name: "",
      email: "",
      picture: "",
      emp_imagem400: [],
      model: {
        emp_id: 0,
        emp_senha: "",
        emp_razaosocial: "",
        emp_endereco: "",
        emp_cidade: "",
        emp_fone: "",
        emp_celular: "",
        emp_cnpj: "",
        emp_email: "",
        emp_nomefantasia: "",
        emp_senha_repetir: "",
        emp_perfilfacebook: "",
        emp_historia: "",
        emp_principaisprodutos: "",
        emp_canalyoutube: "",
        emp_website: "",
        emp_twitter: "",
        emp_instagram: "",
      },
    };
  }

  responseFacebook = (response) => {
    // console.log(response);
    if (response.picture) {
      this.setState({
        isLoggedIn: true,
        userID: response.userID,
        name: response.name,
        email: response.email,
        picture: response.picture.data.url,
      });

      localStorage.setItem("userID", response.userID);
      localStorage.setItem("name", response.name);
      localStorage.setItem("email", response.email);
      localStorage.setItem("picture", response.picture.data.url);

      api({
        method: "get",
        url:
          "api/arealojista/" +
          response.email +
          "/" +
          response.userID +
          "/facebook",
          headers: {
            Authorization: "bearer " + sessionStorage.getItem("token"),
          },
      })
        .then((response) => {
          if (response.data[0].emp_situacao === "A") {
          //  console.log(response);
            localStorage.removeItem("id");
            localStorage.removeItem("nivel");
            localStorage.removeItem("nome");

            localStorage.setItem("credentials", "basic admin : admin");
            localStorage.setItem("username", "admin");
            localStorage.setItem("password", "admin");
            localStorage.setItem("id", response.data[0].emp_id);
            localStorage.setItem("nome", response.data[0].emp_razaosocial);
            localStorage.setItem("nivel", 0);
            //  localStorage.setItem('senha',response.data[0].emp_senha);

            this.props.history.push("sistema/cad_produto");
            return;
          }
        })
        .catch((error) => {
          handleInfo(
            "Email não existe na plataforma,cadastre uma nova conta para este email"
          );

          this.setState({ formulario: 1 });

          this.setState({
            model: {
              emp_id: 0,
              emp_senha: "",
              emp_razaosocial: "",
              emp_endereco: "",
              emp_cidade: "",
              emp_fone: "",
              emp_celular: "",
              emp_cnpj: "",
              emp_perfilfacebooks: "",
              emp_historia: "",
              emp_principaisprodutos: "",
              emp_canalyoutube: "",
              emp_website: "",
              emp_twitter: "",
              emp_instagram: "",
              emp_linkedin: "",
              emp_perfilfacebook: this.state.userID,
              emp_email: this.state.email,
              emp_nomefantasia: this.state.name,
            },
          });
        });
    }
  };

  componentClicked = () => console.log("clicked");

  Get_form = (form) => {
    this.Limpar();
    if (document.getElementById("span_message")) {
      document.getElementById("esqueci_senha_message").innerHTML =
        "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
    }
    this.setState({ formulario: form });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    model.emp_nomefantasia = model.emp_nomefantasia.toUpperCase();
    model.emp_celular = mask_tel_celular_area(model.emp_celular);
    model.emp_fone = mask_tel_celular_area(model.emp_fone);
    model.emp_cnpj = mask_CNPJ(model.emp_cnpj);
    model.emp_senha = mask_minusculo_sem_espaco(model.emp_senha);
    model.emp_email = mask_minusculo_sem_espaco(model.emp_email);

    this.setState({ model });
    // console.log(JSON.stringify(this.state.model));
  };

  login = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("nivel");
    localStorage.removeItem("nome");
    sessionStorage.removeItem("token");
    localStorage.setItem("credentials", "basic admin : admin");
    localStorage.setItem("username", "admin");
    localStorage.setItem("password", "admin");

    const model = this.state.model;
    if (model.emp_email !== "" && model.emp_senha !== "") {
      let data = {
        emp_email: model.emp_email,
      };

      api({
        method: "get",
        url:
          "api/arealojista/" + model.emp_email + "/" + model.emp_senha + "/0",
        data: data,
      })
        .then((response) => {
          if (response.data[0].emp_situacao === "A") {
           // console.log(response);
            localStorage.setItem("id", response.data[0].emp_id);
            localStorage.setItem("nome", response.data[0].emp_razaosocial);
            localStorage.setItem("nivel", 0);
            sessionStorage.setItem("token",response.data[0].token);
            //  localStorage.setItem('senha',response.data[0].emp_senha);

            this.props.history.push("sistema/cad_produto");
            return;
          } else {
            handleError("login ou senha incorretos");
          }
        })
        .catch((error) => {
          handleError("login ou senha incorretos");
          console.log(error.response);
          return false;
        });
    } else {
      handleError("Insira o email e senha da sua conta");
    }
  };

  esqueci_senha = () => {
    const model = this.state.model;
    // alert(model.emp_email);
    if (model.emp_email !== "") {
      let data = {
        emp_email: model.emp_email,
      };

      api({
        method: "put",
        url: "api/arealojista/" + 1,
        data: data,
       
      })
        .then((response) => {
          let span = "";

          if (response.data.status === "sucesso") {
            handleSuccess(response.data.message);
            span +=
              '<span id="span_message" style="color:green;">' +
              response.data.message +
              "</span>";
          } else {
            handleError(response.data.message);
            span +=
              '<span id="span_message" style="color:red;">' +
              response.data.message +
              "</span>";
          }
          document.getElementById("esqueci_senha_message").innerHTML = span;
          // this.Limpar();
          // this.Get_empresa();
        })
        .catch((error) => {
          handleError("Erro ao executar");
          console.log(error.response);
          return false;
        });
    } else {
      handleError("Insira o email da sua conta");
    }
  };

  save = () => {
    const model = this.state.model;
    // console.log(JSON.stringify(model));
    if (
      model.emp_senha !== "" &&
      model.emp_razaosocial !== "" &&
      model.emp_endereco !== "" &&
      this.state.selectedOption.value !== "" &&
      model.emp_fone !== "" &&
      model.emp_celular !== "" &&
      model.emp_email !== "" &&
      model.emp_nomefantasia !== "" &&
      model.emp_senha_repetir !== ""
    ) {
      if (model.emp_senha !== model.emp_senha_repetir) {
        handleError("Senha repetida não coincide com a senha");
        return false;
      }
      if (!document.getElementById("termo_aceite").checked) {
        handleError("Aceite os termos de aceite");
        return false;
      }

      if (!validar_Email(model.emp_email)) {
        handleError("Preencha um email valido");
        return false;
      }
      var emp_lemeliquida, emp_queimaestoque;

      if (document.getElementById("emp_lemeliquida").checked) {
        emp_lemeliquida = 1;
      } else {
        emp_lemeliquida = 0;
      }
      if (document.getElementById("emp_queimaestoque").checked) {
        emp_queimaestoque = 1;
      } else {
        emp_queimaestoque = 0;
      }
      var data_uri = document.querySelectorAll("output span img");
      // console.log(data_uri);

      var empresa_logo = [];
      for (let i = 0; i < data_uri.length; i++) {
        empresa_logo.push(data_uri[i].src);
      }
      let data = {
        emp_id: parseInt(model.emp_id),
        emp_senha: model.emp_senha,
        emp_razaosocial: model.emp_razaosocial,
        emp_endereco: model.emp_endereco,
        emp_cidade: this.state.selectedOption.value,
        emp_fone: model.emp_fone,
        emp_celular: model.emp_celular,
        emp_cnpj: model.emp_cnpj,
        emp_email: model.emp_email,
        emp_nomefantasia: model.emp_nomefantasia,
        emp_perfilfacebook: model.emp_perfilfacebooks,
        emp_historia: model.emp_historia,
        emp_principaisprodutos: model.emp_principaisprodutos,
        emp_canalyoutube: model.emp_canalyoutube,
        emp_website: model.emp_website,
        emp_twitter: model.emp_twitter,
        emp_instagram: model.emp_instagram,
        emp_linkedin: model.emp_linkedin,
        emp_imagem400: empresa_logo,
        emp_lemeliquida: emp_lemeliquida,
        emp_queimaestoque: emp_queimaestoque,
      };

      // console.log("data eh: " + JSON.stringify(data));
      // return false;

      api({
        method: "post",
        url: "api/arealojista",
        data: data,
      })
        .then((response) => {
          if (response.data.status === "sucesso") {
            handleSuccess(response.data.message);
            this.Limpar();
          } else {
            handleError(response.data.message);
          }
          // this.Get_empresa();
        })
        .catch((error) => {
          handleError("Erro ao executar");
          console.log(error.response);
          return false;
        });
    } else {
      handleError("Preencha todos os campos");
    }
  };

  getCidades = () => {
    api({
      method: "get",
      url: "api/cidade",
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        const statusOptions = [];
        for (let i = 0; i < response.data.length; i++) {
          statusOptions.push({
            value: response.data[i].cid_nome + "/" + response.data[i].cid_uf,
            label: response.data[i].cid_nome + "/" + response.data[i].cid_uf,
          });
        }
        this.setState({ statusOptions });
      })
      .catch((error) => {
        handleError("Erro ao executar");
        console.log(error.response);
        return false;
      });
  };

  componentDidMount = () => {
    var url = window.location.pathname;
    var items = url.split("/");

    this.getCidades();

    if (items[2] && items[3]) {
    //  console.log(JSON.stringify(items));

      let data = {
        emp_email: items[2],
        emp_codigo: items[3],
      };

      api({
        method: "put",
        url: "api/arealojista",
        data: data,
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          let span = "";

          if (response.data.status === "sucesso") {
            handleSuccess(response.data.message);
            span +=
              '<div  class="alert alert-success" role="alert">' +
              response.data.message +
              "</div>";
          } else {
            handleError(response.data.message);
            span +=
              '<div class="alert alert-danger" role="alert">' +
              response.data.message +
              "</div>";
          }
          document.getElementById("ativacao_result").innerHTML = span;
          // this.Limpar();
          // this.Get_empresa();
        })
        .catch((error) => {
          handleError("Erro ao executar");
          console.log(error.response);
          return false;
        });
    }
  };

  Limpar = () => {
    this.setState({
      model: {
        emp_id: 0,
        emp_senha: "",
        emp_razaosocial: "",
        emp_endereco: "",
        emp_cidade: "",
        emp_fone: "",
        emp_celular: "",
        emp_cnpj: "",
        emp_email: "",
        emp_nomefantasia: "",
        emp_senha_repetir: "",
        emp_perfilfacebook: "",
        emp_historia: "",
        emp_principaisprodutos: "",
        emp_canalyoutube: "",
        emp_website: "",
        emp_twitter: "",
        emp_instagram: "",
      },
    });
    if (document.getElementById("emp_lemeliquida")) {
      document.getElementById("emp_lemeliquida").checked = false;
    }
    if (document.getElementById("emp_queimaestoque")) {
      document.getElementById("emp_queimaestoque").checked = false;
    }
    if (document.getElementById("list")) {
      document.getElementById("list").innerHTML = "";
    }
    if (document.getElementById("prod_foto")) {
      document.getElementById("prod_foto").value = "";
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    document.getElementById("list").innerHTML = "";

    var fileInput = document.getElementById("prod_foto");
    var fileList = [];

    if (fileInput.files.length > 1) {
      handleError("Escolha uma unica imagem como logo");
      document.getElementById("prod_foto").value = "";
      return false;
    }
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }

    for (var i = 0, f; (f = fileList[i]); i++) {
      //    this.GerarDataURI(f, i);
    //  console.log(JSON.stringify(f.name) + " " + JSON.stringify(fileList[i]));

      // Only process image files.
      if (!f.type.match("image.*")) {
        continue;
      }

      var reader = new FileReader();

      reader.onload = (function (theFile) {
        return function (e) {
          (function () {
            var image = new Image();
            image.addEventListener(
              "load",
              function () {
                // tamanho original

                var canvas = document.createElement("canvas"),
                  ctx = canvas.getContext("2d");
                // coordenadas origem (source)

                // tamanho destino
                var MAX_WIDTH = 400;
                var MAX_HEIGHT = 400;
                var width = this.width;
                var height = this.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(this, 0, 0, width, height);

                let ext = theFile.name.split(".");

                var dataurl = canvas.toDataURL("image/" + ext[ext.length - 1]);
                //  console.log(dataurl);
                var span = document.createElement("span");
                span.innerHTML = [
                  '<img class="img_data_uri" style="max-width: 150px;max-height: 150px;" name="img_data_uris" src="',
                  dataurl,
                  '" title="',
                  escape(theFile.name),
                  '"/>',
                ].join("");
                document.getElementById("list").insertBefore(span, null);
              },
              false
            );
            image.src = e.target.result;
          })();
        };
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  }

  render() {
    const { selectedOption, statusOptions } = this.state;
    //const { selectedGerente } = this.state;
    const { model } = this.state;
   // console.log(this.state.status_array);

    let fbContent;

    if (this.state.isLoggedIn) {
      fbContent = (
        <div
          style={{
            // width: "400px",
            margin: "auto",
            background: "#f4f4f4",
            padding: "20px",
          }}
        >
          <img src={this.state.picture} alt={this.state.name} />
          <h3>Bem-Vindo {this.state.name}</h3>
          Email: {this.state.email}
        </div>
      );
    } else {
      fbContent = (
        //   <div style={{display: 'flex',flexWrap: 'wrap' }} >
        <FacebookLogin
          appId="554266545231005"
          autoLoad={false}
          fields="name,email,picture"
          cssClass="loginBtn loginBtn--facebook"
          textButton="&nbsp;&nbsp;Login com Facebook"
          onClick={this.componentClicked}
          callback={this.responseFacebook}
        />

        //</div>
        /*   <FacebookLogin
                     appId="554266545231005"
                     autoLoad={true}
                     fields="name,email,picture"
                     onClick={this.componentClicked}
                     callback={this.responseFacebook}
                   />*/
      );
    }

    return (
      <>
        <Navbar_public />

        <div className="container">
          <div
            class="modal fade"
            id="termos-de-uso"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Termos de uso
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {" "}
                    Por favor, leia com atenção os termos e condições abaixo.
                  </p>

                  <p>
                    Ao se cadastrar no keepdelivery.com.br você ESTÁ DE ACORDO
                    COM AS CONDIÇÕES E TERMOS apresentados. Note que a recusa
                    deste “Termos do Uso e de Serviços” impedirá que você faça
                    uso dos serviços aqui descritos.
                  </p>

                  <p style={{ fontWeight: "bold" }}>1. SERVIÇOS OFERECIDOS</p>
                  <div style={{ textAlign: "justify", marginLeft: "20px" }}>
                    <p>
                      1.1 Este TERMO se aplica para regular o uso do serviço
                      oferecido pelo keepdelivery.com.br aos USUÁRIOS, qual
                      seja, possibilitar a escolha, pelos USUÁRIOS, de PRODUTOS
                      cadastrados e, via on-line, efetivar solicitações de
                      pedidos destes PRODUTOS fornecidos pelas EMPRESAS
                      participantes, de acordo com os produtos disponibilizados
                      pela respectiva EMPRESA, sendo possível, igualmente, aos
                      USUÁRIOS, selecionar se o produto deverá ser entregue ou
                      se ele será retirado pessoalmente na loja.
                    </p>

                    <p>
                      1.2 O serviço do keepdelivery.com.br consiste, portanto,
                      em aproximar, através do nosso site, os USUÁRIOS e as
                      EMPRESAS cadastradas, possibilitando que os USUÁRIOS
                      encaminhem às EMPRESAS, pedidos de PRODUTOS, bem como,
                      sendo essa a opção dos USUÁRIOS, receber os produtos em
                      sua casa ou retirar o produto na EMPRESA provedora do
                      produto selecionado.
                    </p>

                    <p>
                      1.3 Desde logo fica esclarecido ao USUÁRIO - o qual se
                      declara ciente - que o serviço oferecido pelo
                      keepdelivery.com.br se relaciona apenas à intermediação
                      (sem opção de pagamento on-line) para comercialização e
                      entrega de produtos das EMPRESAS, não abarcando nenhuma
                      outra etapa do processo, sendo estas outras etapas são de
                      responsabilidade integral da EMPRESA, a quem deverão ser
                      direcionados quaisquer reclamações acerca de problemas
                      decorrentes de vício, defeito ou extravio dos produtos.
                    </p>
                  </div>
                  <p style={{ fontWeight: "bold" }}>2. CADASTRO</p>

                  <p style={{ textAlign: "justify", marginLeft: "20px" }}>
                    2.1 O USUÁRIO, para utilizar os serviços acima descritos,
                    deverá ter capacidade jurídica para atos civis e deverá,
                    necessariamente, prestar as informações exigidas no
                    CADASTRO, assumindo integralmente a responsabilidade
                    (inclusive cível e criminal) pela exatidão e veracidade das
                    informações fornecidas no CADASTRO, que poderá ser
                    verificado, a qualquer momento, pelo keepdelivery.com.br.
                  </p>
                  <div style={{ textAlign: "justify", marginLeft: "40px" }}>
                    <p>
                      2.1.1 Em caso de informações incorretas, inverídicas ou
                      não confirmadas, bem assim na hipótese da negativa em
                      corrigi-las ou enviar documentação que comprove a
                      correção, o keepdelivery.com.br se reserva o direito de
                      não concluir o cadastramento em curso ou, ainda, de
                      bloquear o cadastro já existente, impedindo o USUÁRIO de
                      utilizar os serviços on-line até que, a critério do
                      keepdelivery.com.br, a situação de anomalia esteja
                      regularizada.
                    </p>

                    <p>
                      2.1.2 O keepdelivery.com.br se reserva o direito de
                      impedir, a seu critério, novos CADASTROS, ou cancelar os
                      já efetuados, em caso de ser detectada anomalia que, em
                      sua análise, seja revestida de gravidade ou demonstre
                      tentativa deliberada de burlar as regras aqui descritas,
                      obrigatórias para todos os USUÁRIOS. Também agirá o
                      keepdelivery.com.br de tal forma caso verifique
                      descumprimento, pelo USUÁRIO, de qualquer obrigação
                      prevista no presente TERMO.
                    </p>
                  </div>
                  <p style={{ textAlign: "justify", marginLeft: "20px" }}>
                    2.2 Efetuado o CADASTRO com sucesso, o USUÁRIO terá acesso
                    aos serviços por meio de login e senha, dados esses que se
                    compromete a não divulgar a terceiros, ficando sob sua
                    exclusiva responsabilidade qualquer solicitação de serviço
                    que seja feita com o uso de login e senha de sua
                    titularidade.
                  </p>
                  <p style={{ fontWeight: "bold" }}>3. OBRIGAÇÕES DO USUÁRIO</p>
                  <div style={{ textAlign: "justify", marginLeft: "20px" }}>
                    <p>
                      3.1 Efetuado o CADASTRO do USUÁRIO com sucesso, este se
                      obriga a não divulgar a terceiros login e senha de acesso,
                      nem permitir o uso de tais informações por terceiros,
                      responsabilizando-se pelas consequências do uso de login e
                      senha de sua titularidade.
                    </p>

                    <p>
                      3.2 É obrigação do USUÁRIO fornecer informações cadastrais
                      totalmente verídicas e exatas, responsabilizando-se
                      exclusiva e integralmente (em todas as searas jurídicas)
                      por todo o conteúdo por si informado no item CADASTRO,
                      mantendo atualizado e confirmado o endereço para entrega
                      dos produtos encomendados.
                    </p>

                    <p>
                      3.3 O USUÁRIO se obriga, também, a pagar integralmente o
                      preço dos produtos por si solicitados ou encomendados à
                      EMPRESA e efetivamente a si entregues, diretamente ao
                      portador dos produtos encomendados por meio deste site.
                    </p>

                    <p>
                      3.4 O USUÁRIO concorda com o uso das informações de
                      avaliações e feedbacks dos serviços e produtos das
                      EMPRESAS e do keepdelivery.com.br, conforme descrito nos
                      TERMOS DE PRIVACIDADE do keepdelivery.com.br.
                    </p>
                  </div>
                  <p style={{ fontWeight: "bold" }}>
                    4. OBRIGAÇÕES DO keepdelivery.com.br
                  </p>
                  <div style={{ textAlign: "justify", marginLeft: "20px" }}>
                    <p>
                      4.1 Disponibilizar no site keepdelivery.com.br espaço
                      virtual que permita ao USUÁRIO devidamente cadastrado
                      efetivar pedidos de compra dos produtos anunciados e
                      comercializados pelas EMPRESAS e, também, disponibilizar
                      ao USUÁRIO meios para selecionar a forma de entrega.
                    </p>
                    <p>
                      4.2 Proteger, por meio de armazenamento em servidores ou
                      quaisquer outros meios magnéticos de alta segurança, a
                      confidencialidade de todas as informações e cadastros
                      relativos aos USUÁRIOS, assim como valores atinentes às
                      operações financeiras advindas da operacionalização dos
                      serviços previstos no presente TERMO. Contudo, não
                      responderá pela reparação de prejuízos que possam ser
                      derivados de apreensão e cooptação de dados por parte de
                      terceiros que, rompendo os sistemas de segurança, consigam
                      acessar essas informações.
                    </p>
                  </div>
                  <p style={{ fontWeight: "bold" }}>
                    5. MODIFICAÇÕES DESTE TERMO
                  </p>
                  <div style={{ textAlign: "justify", marginLeft: "20px" }}>
                    <p>
                      5.1 O presente TERMO DE USO poderá, a qualquer tempo, ter
                      seu conteúdo, ou parte dele, modificados para adequações e
                      inserções, tudo com vistas ao aprimoramento dos serviços
                      disponibilizados.
                    </p>
                    <p>
                      5.2 As novas condições entrarão em vigência assim que sua
                      veiculada no site, sendo possível ao USUÁRIO manifestar
                      oposição a quaisquer dos termos modificados, desde que o
                      faça por escrito, através do site keepdelivery.com.br, o
                      que gerará o cancelamento de seu CADASTRO.
                    </p>
                  </div>

                  <p style={{ fontWeight: "bold" }}>6. CANAL DE COMUNICAÇÃO</p>
                  <div style={{ textAlign: "justify", marginLeft: "20px" }}>
                    <p>
                      6.1 Para estabelecer contato entre keepdelivery.com.br e o
                      USUÁRIO fica disponibilizado o endereço eletrônico deste
                      link, sendo certo que o USUÁRIO se obriga, igualmente, a
                      manter em seu cadastro endereço eletrônico atual por
                      intermédio do qual se farão as comunicações a ele
                      dirigidas pelo keepdelivery.com.br, desde logo
                      emprestando-se validade jurídica e efetividade a esse meio
                      eletrônico de troca de informações recíprocas.
                    </p>
                  </div>

                  <p style={{ fontWeight: "bold" }}>
                    7. ACEITAÇÃO DO TERMO DE USO
                  </p>
                  <div style={{ textAlign: "justify", marginLeft: "20px" }}>
                    <p>
                      7.1 O USUÁRIO declara ter lido, entendido e que aceita
                      todas as regras, condições e obrigações estabelecidas no
                      presente TERMO.
                    </p>
                  </div>

                  <p style={{ fontWeight: "bold" }}>8. FORO DE ELEIÇÃO</p>
                  <div style={{ textAlign: "justify", marginLeft: "20px" }}>
                    <p>
                      8.1 As partes elegem como competente para dirimir
                      eventuais controvérsias que venham a surgir da
                      interpretação e do cumprimento do presente TERMO o foro da
                      Comarca de Leme - SP.
                    </p>
                  </div>

                  <p style={{ fontWeight: "bold" }}>
                    9. Última atualização: 08 de maio de 2020
                  </p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <h2 className="text-center">Área Lojista</h2>
          {this.state.formulario === 0 ? (
            <>
              <div class="form-group row">
                {/* ininio do formulario de login*/}
                <div class="col-sm-4"></div>
                <div id="ativacao_result" class="col-sm-4"></div>
                <div class="col-sm-4"></div>
              </div>
              <div class="form-group row">
                {/* ininio do formulario de login*/}
                <div class="col-sm-4"></div>
                <div class="col-sm-4">
                  <div class="card text-center">
                    <div class="card-header_nav">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            <b>Login</b>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            onClick={(e) => this.Get_form(1)}
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            <b>
                              Registra-se<b></b>
                            </b>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="card-body">
                      <form>
                        <div class="form-group row">
                          <label
                            for="emp_email"
                            style={{ color: "#7c7f82" }}
                            class="col-sm-3 col-form-label"
                          >
                            <b>Email:</b>
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="emp_email"
                              class="form-control"
                              id="emp_email"
                              value={model.emp_email}
                              placeholder="Email"
                              onChange={(e) => this.setValues(e, "emp_email")}
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="emp_senha"
                            style={{ color: "#7c7f82" }}
                            class="col-sm-3 col-form-label"
                          >
                            <b>Senha:</b>
                          </label>
                          <div class="input-group col-sm-9">
                            <input
                              type="password"
                              style={{ width: "80%" }}
                              maxLength="10"
                              autoComplete="new-password"
                              class="form-control"
                              id="emp_senha"
                              value={model.emp_senha}
                              placeholder="Senha"
                              onChange={(e) => this.setValues(e, "emp_senha")}
                            />
                            <div class="input-group-prepend">
                              <div
                                onClick={(e) =>
                                  (document.getElementById("emp_senha").type =
                                    document.getElementById("emp_senha").type ==
                                    "text"
                                      ? "password"
                                      : "text")
                                }
                                class="input-group-text"
                              >
                                <i class="fas fa-eye"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <button
                              type="button"
                              onClick={this.login}
                              class="loginBtn btn-primary text-center"
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </button>
                          </div>
                        </div>{" "}
                        {this.state.email ? (
                          <div class="form-group row">
                            <div class="col-sm-2"> </div>
                            <div class="col-sm-8">{fbContent} </div>

                            <div class="col-sm-2"> </div>
                          </div>
                        ) : (
                          <div class="form-group row">
                            <div class="col-sm-12">{fbContent} </div>
                          </div>
                        )}
                      </form>
                    </div>
                    <div class="card-footer text-muted">
                      <span onClick={(e) => this.Get_form(2)}>
                        {" "}
                        esqueci minha senha?
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4"></div>
              </div>
            </>
          ) : (
            ""
          )}
          {this.state.formulario === 1 ? (
            <>
              <div class="form-group row">
                {/* ininio do formulario de registro*/}
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <div class="card text-center">
                    <div class="card-header_nav">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link "
                            onClick={(e) => this.Get_form(0)}
                            id="home-tabs"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="false"
                          >
                            <b>Login</b>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="profile-tabs"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="true"
                          >
                            <b>
                              Registra-se<b></b>
                            </b>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="card-body">
                      <form>
                        <div class="form-group row">
                          <div class="col-sm-6">
                            <label
                              for="emp_razaosocial"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Razao social:</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_razaosocial"
                              value={model.emp_razaosocial}
                              placeholder="Nome"
                              onChange={(e) =>
                                this.setValues(e, "emp_razaosocial")
                              }
                            />
                          </div>
                          <div class="col-sm-6">
                            <label
                              for="emp_nomefantasia"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Nome fantasia:</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_nomefantasia"
                              value={model.emp_nomefantasia}
                              placeholder="Nome fantasia"
                              onChange={(e) =>
                                this.setValues(e, "emp_nomefantasia")
                              }
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-5">
                            <label
                              for="emp_endereco"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Endereço:</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_endereco"
                              value={model.emp_endereco}
                              placeholder="Endereço"
                              onChange={(e) =>
                                this.setValues(e, "emp_endereco")
                              }
                            />
                          </div>
                          <div class="col-sm-4">
                            <label
                              for="emp_cidade"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Cidade:</b>
                            </label>
                            <Select
                              id="emp_cidade"
                              value={selectedOption}
                              onChange={this.handleChange}
                              options={statusOptions}
                            />
                          </div>
                          <div class="col-sm-3">
                            <label
                              for="emp_fone"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Telefone:</b>
                            </label>

                            <input
                              type="text"
                              maxLength="18"
                              class="form-control"
                              id="emp_fone"
                              value={model.emp_fone}
                              placeholder="Telefone com DDD"
                              onChange={(e) => this.setValues(e, "emp_fone")}
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-3">
                            <label
                              for="emp_celular"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Celular:</b>
                            </label>

                            <input
                              type="text"
                              maxLength="19"
                              class="form-control"
                              id="emp_celular"
                              value={model.emp_celular}
                              placeholder="Celular com DDD"
                              onChange={(e) => this.setValues(e, "emp_celular")}
                            />
                          </div>
                          <div class="col-sm-3">
                            <label
                              for="emp_email"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Email:</b>
                            </label>
                            {this.state.email ? (
                              <input
                                type="email"
                                class="form-control"
                                id="emp_email"
                                readOnly
                                value={model.emp_email}
                                placeholder="Email"
                                onChange={(e) => this.setValues(e, "emp_email")}
                              />
                            ) : (
                              <input
                                type="email"
                                class="form-control"
                                id="emp_email"
                                value={model.emp_email}
                                placeholder="Email"
                                onChange={(e) => this.setValues(e, "emp_email")}
                              />
                            )}
                          </div>
                          <div class="col-sm-3" style={{ display: "none" }}>
                            <label
                              for="emp_cnpj"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>CNPJ:</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_cnpj"
                              value={model.emp_cnpj}
                              placeholder="CNPJ"
                              onChange={(e) => this.setValues(e, "emp_cnpj")}
                            />
                          </div>

                          <div class="input-group col-sm-3">
                            <label
                              for="emp_senha"
                              style={{ width: "100%", color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>
                                Senha:
                                <b style={{ fontSize: "10px" }}>
                                  (Max 10 caracteres)
                                </b>
                              </b>
                            </label>

                            <input
                              type="password"
                              class="form-control"
                              id="emp_senha"
                              maxLength="10"
                              value={model.emp_senha}
                              placeholder="Senha"
                              onChange={(e) => this.setValues(e, "emp_senha")}
                            />
                            <div class="input-group-prepend">
                              <div
                                onClick={(e) =>
                                  (document.getElementById("emp_senha").type =
                                    document.getElementById("emp_senha").type ==
                                    "text"
                                      ? "password"
                                      : "text")
                                }
                                class="input-group-text"
                              >
                                <i class="fas fa-eye"></i>
                              </div>
                            </div>
                          </div>
                          <div class="input-group col-sm-3">
                            <label
                              for="emp_senha_repetir"
                              style={{ width: "100%", color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>
                                {" "}
                                Repita:
                                <b style={{ fontSize: "10px" }}>
                                  (Max 10 caracteres)
                                </b>
                              </b>
                            </label>

                            <input
                              type="password"
                              class="form-control"
                              id="emp_senha_repetir"
                              maxLength="10"
                              value={model.emp_senha_repetir}
                              placeholder="Repita a sua Senha"
                              onChange={(e) =>
                                this.setValues(e, "emp_senha_repetir")
                              }
                            />
                            <div class="input-group-prepend">
                              <div
                                onClick={(e) =>
                                  (document.getElementById(
                                    "emp_senha_repetir"
                                  ).type =
                                    document.getElementById("emp_senha_repetir")
                                      .type == "text"
                                      ? "password"
                                      : "text")
                                }
                                class="input-group-text"
                              >
                                <i class="fas fa-eye"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="form-group row mt-2">
                          <div class="col-sm-12">
                            <h5 className="text-center">Campos opcionais</h5>{" "}
                          </div>
                          <div class="col-sm-6 mb-3">
                            <label
                              for="emp_principaisprodutos"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Principais produtos:</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_principaisprodutos"
                              value={model.emp_principaisprodutos}
                              placeholder="Exemplo:Churrasqueiras, Lareiras, Fogões a Lenha."
                              onChange={(e) =>
                                this.setValues(e, "emp_principaisprodutos")
                              }
                            />
                          </div>
                          <div class="col-sm-6 mb-3">
                            <label
                              for="emp_perfilfacebooks"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Link do perfil do Facebook:</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_perfilfacebooks"
                              value={model.emp_perfilfacebooks}
                              placeholder="Link Facebook."
                              onChange={(e) =>
                                this.setValues(e, "emp_perfilfacebooks")
                              }
                            />
                          </div>
                          <div class="col-sm-6 mb-3">
                            <label
                              for="emp_canalyoutube"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Link do canal do Youtube:</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_canalyoutube"
                              value={model.emp_canalyoutube}
                              placeholder="Link Youtube."
                              onChange={(e) =>
                                this.setValues(e, "emp_canalyoutube")
                              }
                            />
                          </div>
                          <div class="col-sm-6 mb-3">
                            <label
                              for="emp_twitter"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Link do perfil do Twitter :</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_twitter"
                              value={model.emp_twitter}
                              placeholder="Link Twitter."
                              onChange={(e) => this.setValues(e, "emp_twitter")}
                            />
                          </div>
                          <div class="col-sm-6 mb-3">
                            <label
                              for="Instagram"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Link do perfil do Instagram :</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_instagram"
                              value={model.emp_instagram}
                              placeholder="Link Instagram."
                              onChange={(e) =>
                                this.setValues(e, "emp_instagram")
                              }
                            />
                          </div>
                          <div class="col-sm-6 mb-3">
                            <label
                              for="emp_linkedin"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Link do perfil do Linkedin :</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_linkedin"
                              value={model.emp_linkedin}
                              placeholder="Link Linkedin."
                              onChange={(e) =>
                                this.setValues(e, "emp_linkedin")
                              }
                            />
                          </div>
                          <div class="col-sm-6 mb-3">
                            <label
                              for="emp_website"
                              style={{ color: "#7c7f82" }}
                              class="col-form-label"
                            >
                              <b>Link do Website da empresa :</b>
                            </label>

                            <input
                              type="text"
                              class="form-control"
                              id="emp_website"
                              value={model.emp_website}
                              placeholder="Link Website."
                              onChange={(e) => this.setValues(e, "emp_website")}
                            />
                          </div>
                          <div class="col-sm-6 mb-3">
                            <div class="col-sm-12">
                              <h6 className="text-left">
                                <b> Participar das Campanhas?</b>
                              </h6>{" "}
                            </div>
                            <div class="text-left custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="emp_lemeliquida"
                              />
                              <label
                                class="custom-control-label"
                                for="emp_lemeliquida"
                              >
                                Leme Liquida
                              </label>
                            </div>
                            <div class="text-left custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="emp_queimaestoque"
                              />
                              <label
                                class="custom-control-label"
                                for="emp_queimaestoque"
                              >
                                Queima de estoque
                              </label>
                            </div>{" "}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label htmlFor="emp_historia">
                              <b>História:</b>
                            </label>
                            <br />
                            <textarea
                              className="form-control"
                              style={{
                                minWidth: "500px",
                                maxWidth: "100%",
                                minHeight: "50px",
                                height: "100%",
                                width: "100%",
                              }}
                              id="emp_historia"
                              value={this.state.model.emp_historia}
                              placeholder="História da empresa"
                              onChange={(e) =>
                                this.setValues(e, "emp_historia")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 mb-3 text-left">
                          <label htmlFor="prod_foto">
                            <b>Logo da empresa:</b>
                          </label>
                          <br />
                          <input
                            type="file"
                            name="prod_foto[]"
                            style={{
                              border: "0px",
                            }}
                            accept=".jpg, .jpeg, .png, .webp"
                            id="prod_foto"
                            onChange={this._handleImageChange}
                          />
                        </div>
                        <output id="list"></output>
                        <hr />
                        <div class="form-group row">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="termo_aceite"
                            />
                            <label
                              style={{ opacity: "1" }}
                              class="custom-control-label"
                              for="termo_aceite"
                            >
                              LI,COMPREENDI E CONCORDO COM OS
                            </label>{" "}
                            <strong
                              style={{ color: "#ff07ff" }}
                              data-toggle="modal"
                              data-target="#termos-de-uso"
                            >
                              TERMOS DE USO.
                            </strong>
                          </div>
                        </div>

                        <div class="form-group row mt-2">
                          <div class="col-sm-4"> </div>
                          <div class="col-sm-4">
                            <button
                              type="button"
                              onClick={this.save}
                              class="btn btn-block btn-primary"
                            >
                              Registrar
                            </button>
                          </div>
                          <div class="col-sm-4"> </div>
                        </div>
                      </form>
                    </div>
                    <div class="card-footer text-muted">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </>
          ) : (
            ""
          )}
          {this.state.formulario === 2 ? (
            <div class="form-group row">
              <div class="col-sm-4"></div>
              <div class="col-sm-4">
                <div class="card text-center">
                  <div class="card-header_nav">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          onClick={(e) => this.Get_form(0)}
                          id="home-tab"
                          data-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          <b>Login</b>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          onClick={(e) => this.Get_form(1)}
                          id="profile-tab"
                          data-toggle="tab"
                          href="#profile"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          <b>
                            Registra-se<b></b>
                          </b>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <legend style={{ fontSize: "14px" }}>
                      Insira o email atual de sua conta para a equipe do keep
                      delivery lhe enviar a senha tual para o seu email
                    </legend>
                    <form>
                      <div class="form-group row">
                        <label
                          for="emp_email"
                          style={{ color: "#7c7f82" }}
                          class="col-sm-3 col-form-label"
                        >
                          <b>Email:</b>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="email"
                            class="form-control"
                            id="emp_email"
                            value={model.emp_email}
                            placeholder="Email"
                            onChange={(e) => this.setValues(e, "emp_email")}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-2"> </div>
                        <div class="col-sm-8">
                          <button
                            type="button"
                            onClick={(e) => this.esqueci_senha()}
                            class="btn btn-block btn-primary"
                          >
                            Enviar
                          </button>
                        </div>
                        <div class="col-sm-2"> </div>
                      </div>
                    </form>
                  </div>
                  <div
                    id="esqueci_senha_message"
                    class="card-footer text-muted"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
              <div class="col-sm-4"></div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}
