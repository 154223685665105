import React, { Component } from "react";
import {
  mask_tel_celular_area,
  mask_CNPJ,
} from "../../plugins/Mascara/Mascara";
import "../../styles/Produtos.css";
import {
  dominio,
  api,
  handleError,
  handleSuccess,
  handleInfo,
} from "../../plugins/Config/Config";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "../../styles/pre_loader.css";
import preloader from "../../../loading.gif";
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery

export default class Empresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      statusOptions: [],
      Empresa: [],
      photoIndex: 0,
      isOpen: false,
      images: {
        titulo: "",
        imagem: "",
        texto: "",
        id: "",
      },
      preloader: false,
      model: {
        emp_id: 0,
        emp_nome: "",
        emp_endereco: "",
        emp_cidade: "",
        emp_fone: "",
        emp_celular: "",
        emp_cnpj: "",
        emp_senha: "",
        emp_logo: "",
        emp_email: "",
      },
    };
  }

  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };
  Galeria = (id, img_local, emp_nome, e) => {
    if (!img_local) {
      handleInfo("Nenhum logo cadastrado");
      return false;
    }
    this.setState({ isOpen: true });
    const images = [];
    const imagem = [];
    const imagem_id = [];
    const titulo = [];
    const texto = [];
    var botao = "";
    //console.log(dominio+'/'+img_local);

    imagem.push(dominio + "/" + img_local);
    titulo.push(emp_nome);
    imagem_id.push(id);

    botao = (
      <button
        type="button"
        onClick={(e) => {
          if (
            window.confirm("Voce tem certeza que deseja deletar esta imagem ? ")
          )
            this.DeletarImg(id, e);
        }}
        className="btn btn-outline-primary"
      >
        Deletar Imagem
      </button>
    );

    texto.push(botao);

    images.imagem = imagem;
    images.titulo = titulo;
    images.texto = texto;
    images.id = imagem_id;
    this.setState({ photoIndex: 0 });
    this.setState({ images });
  };
  filtrarArray(array, id) {
    return array.filter(function (val) {
      return val.emp_id === id;
    });
  }
  DeletarImg = (id, e) => {
    //comentei variaveis q não estao sendo usadas
    //var numero = String(id);
    const images = this.state.images;
    // var index = images.id.indexOf(numero);
    //  alert('index da galeria '+this.state.photoIndex+'\nesta deletando este id: '+images.id[this.state.photoIndex]+',\nPosicao 0 de emages:  '+images.id[0]+',\nPosicao 1 de emages:  '+images.id[1]+',\nPosicao 2 de emages:  '+images.id[2]);
    //var id_delete = images.id[this.state.photoIndex];
    images.id.splice(this.state.photoIndex, 1);
    images.texto.splice(this.state.photoIndex, 1);
    images.titulo.splice(this.state.photoIndex, 1);
    images.imagem.splice(this.state.photoIndex, 1);

    this.setState({ images });
    if (this.state.images.length < 1) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ photoIndex: 0 });
    }

    api({
      method: "put",
      url: "api/empresa/" + id,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        handleInfo(response.data.message);
        this.Get_empresa();
      })
      .catch((error) => {
        if (error.response) {
          this.catchResponse(error.response.status);
        }
      });
  };

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    model.emp_fone = mask_tel_celular_area(model.emp_fone);
    model.emp_celular = mask_tel_celular_area(model.emp_celular);
    model.emp_cnpj = mask_CNPJ(model.emp_cnpj, "on");
    this.setState({ model });
   // console.log(JSON.stringify(this.state.model));
  };

  delete = (emp_id, emp_situacao) => {
    api({
      method: "DELETE", //you can set what request you want to be
      url: "api/empresa/" + emp_id + "/" + emp_situacao,
      data: emp_id,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        //   const empresa = this.state.Empresa.filter(corp => corp.emp_id !== emp_id);
        //  this.setState({ products,  message: { text: 'Gerente deletado com sucesso.', alert: 'danger' } });
        //  this.timerMessage(3000);
        // this.setState({ empresa });
        this.Get_empresa();
        handleInfo(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          this.catchResponse(error.response.status);
        }
      });
  };
  alterar = (empresa) => {
   // console.log(JSON.stringify(empresa));
    this.setState({
      model: {
        emp_id: empresa.emp_id,
        emp_nome: empresa.emp_nome,
        emp_endereco: empresa.emp_endereco,
        emp_cidade: empresa.emp_cidade,
        emp_fone: empresa.emp_fone,
        emp_celular: empresa.emp_celular,
        emp_cnpj: empresa.emp_cnpj,
        emp_senha: empresa.emp_senha,
        emp_logo: empresa.emp_logo,
        emp_email: empresa.emp_email,
      },
    });
  };
  save = () => {
    const model = this.state.model;
    //console.log(JSON.stringify(model));
    if (
      model.emp_nome !== "" &&
      model.emp_endereco !== "" &&
      model.emp_cidade !== "" &&
      model.emp_fone !== "" &&
      model.emp_celular !== "" &&
      model.emp_cnpj !== "" &&
      model.emp_senha !== "" &&
      model.emp_email !== ""
    ) {
      var logo = document
        .getElementById("imagem")
        .value.replace(/separador/g, "");

      let data = {
        emp_id: parseInt(model.emp_id),
        emp_nome: model.emp_nome,
        emp_endereco: model.emp_endereco,
        emp_cidade: model.emp_cidade,
        emp_fone: model.emp_fone,
        emp_celular: model.emp_celular,
        emp_cnpj: model.emp_cnpj,
        emp_senha: model.emp_senha,
        emp_email: model.emp_email,
        emp_logo: logo,
        emp_situacao: "A",
      };

    //  console.log("data eh: " + JSON.stringify(data));
      //  console.log('status eh: ' + JSON.stringify(product.cli_fone));
      let method = "";
      if (model.emp_id === 0) {
        method = "post";
        if (document.getElementById("imagem").value === "") {
          handleInfo("envie um logo para esta empresa");
          return false;
        }
      } else {
        method = "put";
      }

      api({
        method: method,
        url: "api/empresa",
        data: data,
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          handleSuccess(response.data.message);
          this.Limpar();
          this.Get_empresa();
        })
        .catch((error) => {
          if (error.response) {
            this.catchResponse(error.response.status);
          }
        });
    } else {
      handleError("Preencha todos os campos");
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    document.getElementById("list").innerHTML = "";

    var fileInput = document.getElementById("prod_foto");
    var fileList = [];

    if (fileInput.files.length > 1) {
      handleError("No máximo 1 arquivo como anexo");
      document.getElementById("prod_foto").value = "";
      return false;
    }
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }
    //vc já criou o i lá em cima não precisa usar var de novo
    for (var f; f === fileList[i]; i++) {
      //    this.GerarDataURI(f, i);
      //console.log(JSON.stringify(f.name)+' '+JSON.stringify(fileList[i]));

      // Only process image files.
      if (!f.type.match("image.*")) {
        continue;
      }

      var reader = new FileReader();

      // Closure to capture the file information.
      reader.onload = (function (theFile) {
        //  console.log(PegarExtensaoDoNome(JSON.stringify(theFile)));
        return function (e) {
          // Render thumbnail.

          var span = document.createElement("span");
          span.id = "span_img";
          span.innerHTML = [
            '<img class="thumb-upload" src="',
            e.target.result,
            '" title="',
            escape(theFile.name),
            '"/>',
          ].join("");
          document.getElementById("list").insertBefore(span, null);
          document.getElementById("imagem").dataset.filename0 =
            document.getElementById("imagem").dataset.filename0 +
            "," +
            theFile.name;
          document.getElementById("imagem").value =
            document.getElementById("imagem").value +
            "separador" +
            e.target.result;
        };
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  }
  componentDidMount = () => {
    this.Get_empresa();
  };

  Limpar = () => {
    this.setState({
      model: {
        emp_id: 0,
        emp_nome: "",
        emp_endereco: "",
        emp_cidade: "",
        emp_fone: "",
        emp_celular: "",
        emp_cnpj: "",
        emp_senha: "",
        emp_logo: "",
        emp_email: "",
      },
    });
    var node = document.getElementById("list");
    var filho = document.getElementById("span_img");

    if (filho) {
      node.removeChild(filho);
    }
    document.getElementById("imagem").value = "";
    document.getElementById("prod_foto").value = "";
  };
  Get_empresa = () => {
    api
      .get("api/empresa")
      .then((response) => {
       // console.log(response.data);
        this.setState({ Empresa: response.data });
      })
      .catch((error) => {
        const Empresa = [];
        this.setState({ Empresa });
        handleInfo("Nenhum produto encontrado");
        return false;
      });
  };

  render() {
    const { photoIndex, isOpen, Empresa, images, model } = this.state;
    return (
      <>
        {this.state.preloader ? (
          <div id="loading-wrapper">
            <div id="loading-text">
              <picture data-fileentryid="1138056">
                <img src={preloader} width={"110px"} alt={preloader} />
              </picture>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <h2
            className="font-weight-bold text-center"
            style={{ marginTop: "30px", marginBottom: "30px" }}
          >
            {" "}
            Empresa{" "}
          </h2>
          <form style={{ marginTop: "30px" }}>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-4 ">
                  <label htmlFor="emp_nome">Nome da Empresa:</label>
                  <input
                    id="emp_nome"
                    className="form-control"
                    required="required"
                    type="text"
                    value={model.emp_nome}
                    placeholder="Nome da Empresa"
                    onChange={(e) => this.setValues(e, "emp_nome")}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="emp_endereco">Endereço:</label>
                  <input
                    id="emp_endereco"
                    className="form-control"
                    required="required"
                    type="text"
                    value={model.emp_endereco}
                    placeholder="Endereço"
                    onChange={(e) => this.setValues(e, "emp_endereco")}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="emp_cidade">Cidade:</label>
                  <input
                    id="emp_cidade"
                    className="form-control"
                    required="required"
                    type="text"
                    value={model.emp_cidade}
                    placeholder="Preço de Promoção"
                    onChange={(e) => this.setValues(e, "emp_cidade")}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-2">
                  <label htmlFor="emp_fone">Telefone:</label>
                  <input
                    id="emp_fone"
                    className="form-control"
                    maxLength="14"
                    required="required"
                    type="text"
                    value={model.emp_fone}
                    placeholder="Telefone"
                    onChange={(e) => this.setValues(e, "emp_fone")}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="emp_celular">Celular:</label>
                  <input
                    id="emp_celular"
                    className="form-control"
                    maxLength="15"
                    required="required"
                    type="text"
                    value={model.emp_celular}
                    placeholder="Celular"
                    onChange={(e) => this.setValues(e, "emp_celular")}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="emp_cnpj">CNPJ:</label>
                  <input
                    id="emp_cnpj"
                    className="form-control"
                    maxLength="18"
                    required="required"
                    type="text"
                    value={model.emp_cnpj}
                    placeholder="CNPJ"
                    onChange={(e) => this.setValues(e, "emp_cnpj")}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="emp_email">Email:</label>
                  <input
                    id="emp_email"
                    className="form-control"
                    required="required"
                    type="text"
                    value={model.emp_email}
                    placeholder="Email"
                    onChange={(e) => this.setValues(e, "emp_email")}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="emp_senha">Senha:</label>
                  <input
                    id="emp_senha"
                    className="form-control"
                    required="required"
                    type="text"
                    value={model.emp_senha}
                    placeholder="Senha"
                    onChange={(e) => this.setValues(e, "emp_senha")}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="prod_foto">
                    <b>Imagens:</b>
                  </label>
                  <br />
                  <input
                    type="file"
                    name="prod_foto[]"
                    style={{ backgroundColor: "#ecf0f4", border: "0px" }}
                    accept="image/*"
                    id="prod_foto"
                    multiple
                    onChange={this._handleImageChange}
                  />
                </div>
              </div>
            </div>
            <div className="text-center">
              {this.state.model.emp_id < 1 ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.save}
                >
                  Cadastrar&nbsp;<i className="fas fa-save"></i>{" "}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.save}
                >
                  Alterar&nbsp;<i className="fas fa-save"></i>{" "}
                </button>
              )}{" "}
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.Limpar}
              >
                Limpar Campos&nbsp;<i className="fas fa-trash-alt"></i>{" "}
              </button>
            </div>
          </form>
          <output id="list"></output>
          <input data-filename0 id="imagem" type="hidden" />
          <div className="row">
            <div className="col-12">
              <form>
                <div className="row">
                  <div className="col-md-4 ">
                    <label htmlFor="supervisor">Buscar por:</label>
                    <input
                      className="form-control"
                      id="busca"
                      onChange={(e) => this.FiltrarCampos()}
                      type="text"
                      placeholder="Termo..."
                    />
                  </div>
                  <div className="col-md-4 "></div>
                  {localStorage.getItem("supervisor") < 1 ? (
                    <div className="col-md-4"></div>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
            {Empresa.length > 0 ? (
              <div className="table-responsive">
                <table
                  style={{ marginTop: "30px", marginBottom: "50px" }}
                  id="clientes"
                  className="table table-hover table-bordered text-center"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>Nome</th>
                      <th>Cidade</th>
                      <th>Telefone</th>
                      <th>Celular</th>
                      <th>Qtd produtos</th>
                      <th>Status</th>
                      <th>Email</th>
                      <th>Senha</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Empresa.map((corp) => (
                      <tr key={corp.emp_id}>
                        <td>{corp.emp_nome}</td>
                        <td>{corp.emp_cidade}</td>
                        <td>{corp.emp_fone}</td>
                        <td>{corp.emp_celular}</td>
                        <td>{corp.emp_totalprodutos}</td>
                        {corp.emp_situacao === "A" ? (
                          <td>Ativo</td>
                        ) : (
                          <td>Inativo</td>
                        )}
                        <td>{corp.emp_email}</td>
                        <td>{corp.emp_senha}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              fontSize: "12px",
                              marginTop: "10px",
                              marginLeft: "3px",
                            }}
                            onClick={(e) => this.alterar(corp)}
                          >
                            <i className="fas fa-save"></i>{" "}
                          </button>
                          <button
                            className="btn btn-info"
                            title="Galeria de fotos"
                            type="button"
                            style={{
                              fontSize: "12px",
                              marginTop: "10px",
                              marginLeft: "3px",
                            }}
                            onClick={(e) =>
                              this.Galeria(
                                corp.emp_id,
                                corp.emp_logo,
                                corp.emp_nome,
                                e
                              )
                            }
                          >
                            <i className="fas fa-camera-retro"></i>
                          </button>

                          {corp.emp_situacao === "A" ? (
                            <button
                              className="btn btn-danger"
                              style={{
                                fontSize: "12px",
                                marginTop: "10px",
                                marginLeft: "3px",
                              }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Voce tem certeza que deseja desativar a empresa "' +
                                      corp.emp_nome +
                                      '" ?'
                                  )
                                ) {
                                  this.delete(corp.emp_id, "I");
                                }
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          ) : (
                            <button
                              className="btn btn-secondary"
                              style={{
                                fontSize: "12px",
                                marginTop: "10px",
                                marginLeft: "3px",
                              }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Voce tem certeza que deseja ativar a empresa "' +
                                      corp.emp_nome +
                                      '" ?'
                                  )
                                ) {
                                  this.delete(corp.emp_id, "A");
                                }
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          )}

                          {isOpen && (
                            <Lightbox
                              mainSrc={images.imagem[photoIndex]}
                              nextSrc={
                                images.imagem[
                                  (photoIndex + 1) % images.imagem.length
                                ]
                              }
                              prevSrc={
                                images.imagem[
                                  (photoIndex + images.imagem.length - 1) %
                                    images.imagem.length
                                ]
                              }
                              onCloseRequest={() =>
                                this.setState({ isOpen: false })
                              }
                              imageTitle={images.titulo[photoIndex]}
                              imageCaption={images.texto[photoIndex]}
                              onMovePrevRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + images.imagem.length - 1) %
                                    images.imagem.length,
                                })
                              }
                              onMoveNextRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + 1) % images.imagem.length,
                                })
                              }
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}
