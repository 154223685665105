import React, { Component } from "react";
import { mask_numeros, mask_Moeda } from "../../plugins/Mascara/Mascara";
import "../../styles/Produtos.css";
import {
  dominio,
  api,
  handleError,
  handleSuccess,
  handleInfo,
} from "../../plugins/Config/Config";
import sem_imagem from "../../../imagens/sem_imagem.jpg";

export default class Empresas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didmount: true,
      empresas: [],
      Produtos: [],
    };
  }

  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  corPorQuantidade = (quantidade) => {
    var cor = "";
    if (quantidade === 0) {
      cor = "#6c757d";
    } else if (quantidade < 50) {
      cor = "#dc3545";
    } else if (quantidade < 100) {
      cor = "#fa746d";
    } else if (quantidade < 150) {
      cor = "#fcb279";
    } else if (quantidade < 200) {
      cor = "#fec77d";
    } else if (quantidade < 250) {
      cor = "#cbdc81";
    } else if (quantidade < 300) {
      cor = "#b3d57f";
    } else if (quantidade < 350) {
      cor = "#63be7a";
    } else if (quantidade < 400) {
      cor = "#28a745";
    }
    return cor;
  };

  limpar_moeda = (v) => {
    if (v) {
      v = v.split(".").join("");
      v = v.replace(",", ".");
      if (v.indexOf(".") >= 0) {
        return v;
      } else {
        let tamanho = v.length;
        return v.substr(0, tamanho - 2) + "." + v.substr(tamanho - 2, tamanho);
      }
    }
  };

  Get_produto = () => {
    this.setState({ uploadPercentage: 0 });
    api
      .get("api/admin",{ headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      }},)
      .then((response) => {
      //  console.log(response.data);
        this.setState({ empresas: response.data });
      })
      .catch((error) => {
        const empresas = [];
        this.setState({ empresas });
        handleInfo("Nenhum produto encontrado");
        return false;
      });
  };
  status = (emp_id, status) => {
    let data = {
      emp_id: emp_id,
      emp_situacao: status,
      tipo: "empresa",
    };

    api({
      method: "put", //you can set what request you want to be
      url: "api/admin/" + emp_id,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        this.Get_produto();
        handleInfo(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          this.catchResponse(error.response.status);
        }
      });
  };

  componentDidMount = () => {
    if (this.state.didmount === true) {
      this.setState({ didmount: false });

      // const { prod_id } = this.props.match.params;
      this.Get_produto();
    }
  };

  render() {
    const { empresas } = this.state;

    return (
      <>
        <div className="container">
          <h2
            className="font-weight-bold text-center"
            style={{ marginBottom: "30px" }}
          >
            {" "}
            Estoque das Empresas{" "}
          </h2>

          <h6>
            Tabela realacionando a quantidade de produtos de cada empresa com a
            sua disponibilidade no estoque
          </h6>
          {empresas.length > 0 ? (
            <>
              <div className="table-responsive">
                <table
                  style={{ marginTop: "30px", marginBottom: "50px" }}
                  id="clientes"
                  className="table table-hover table-bordered text-center"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>Empresa</th>

                      <th>Ativos</th>
                      <th>Inativos</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {empresas.map(
                      function (corp, i) {
                        let status;
                        if (corp.emp_situacao == "A") {
                          status = "Ativo";
                        } else {
                          status = "Inativo";
                        }
                        var cor_ativo = this.corPorQuantidade(corp.ativo);

                        var cor_inativo = this.corPorQuantidade(corp.inativo);
                        let total =
                          parseInt(corp.ativo) + parseInt(corp.inativo);

                        const style_ativo = {
                          fontSize: "12px",
                          color: "#fff",
                          backgroundColor: cor_ativo,
                          borderColor: cor_ativo,
                        };
                        return (
                          <tr key={corp.emp_id}>
                            <td>
                              <b>{corp.emp_nomefantasia}</b>
                            </td>
                            <td>
                              {" "}
                              {corp.ativo == 0 ? (
                                <span
                                  className="btn btn-secondary"
                                  style={{ fontSize: "12px" }}
                                >
                                  <b>0</b>
                                </span>
                              ) : (
                                <span
                                  className="btn btn-success"
                                  style={style_ativo}
                                >
                                  <b>{corp.ativo + " / " + total}</b>
                                </span>
                              )}
                            </td>
                            <td>
                              {corp.inativo == 0 ? (
                                <span
                                  className="btn btn-secondary"
                                  style={{ fontSize: "12px" }}
                                >
                                  <b>0</b>
                                </span>
                              ) : (
                                <span
                                  className="btn btn-success"
                                  style={{ fontSize: "12px" }}
                                >
                                  <b>{corp.inativo + " / " + total}</b>
                                </span>
                              )}
                            </td>
                            <td>
                              {" "}
                              {corp.emp_situacao == "A" ? (
                                <button
                                  className="btn btn-success"
                                  style={{ fontSize: "12px" }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Você tem certeza que deseja desativar a empresa "' +
                                          corp.emp_nomefantasia +
                                          '" ?'
                                      )
                                    ) {
                                      this.status(corp.emp_id, "I");
                                    }
                                  }}
                                >
                                  <b>Ativo</b>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-secondary"
                                  style={{ fontSize: "12px" }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Você tem certeza que deseja ativar a empresa "' +
                                          corp.emp_nomefantasia +
                                          '" ?'
                                      )
                                    ) {
                                      this.status(corp.emp_id, "A");
                                    }
                                  }}
                                >
                                  <b>Inativo</b>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      }.bind(this)
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}
