import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./auth";

// React Boostrap e outros  css Import

import "bootstrap/dist/css/bootstrap.min.css";
//import "jquery/dist/jquery.slim.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
//import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/brands.min.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";
// React Router Import
import { Switch, Route, BrowserRouter } from "react-router-dom";

// React Menu e Footer Import
import Navbar from "./components/layouts/NavBar";
import Footer from "./components/layouts/Footer";

// Import Pages publicas
import Login_cliente from "./components/Login/Login_cliente";
import Login_lojista from "./components/Login/Login_lojista";
import Conta_cliente from "./components/Login/Conta_cliente";
import AlterarSenhaCliente from "./components/Login/AlterarSenhaCliente";
// layout douglas

//import menu from './components/menu'
//import footer from './components/footer'
import produtos_site from "./components/pages/produtos/produtos";
import obrigado from "./components/pages/obrigado/obrigado";
import cart from "./components/pages/cart/carrinho";
import categoria_site from "./components/pages/categoria";
import Carrinho_cliente from "./components/pages/Carrinho_cliente";
//import Home from './components/pages/home'
import Sobre from "./components/pages/sobre/sobre";
import produto_site from "./components/pages/produtos/produto/produto";

// Import Pages ADM
import AdmEmpresas from "./components/pages/admin/Empresas";
import Categoria from "./components/pages/admin/Categoria";
import AdmCarrinho from "./components/pages/admin/Carrinho";
import ListarClientes from "./components/pages/admin/ListarClientes";

// Import Pages sistema
//import Home from './components/pages/Home';
import Conta from "./components/pages/sistema/Conta";
import Cad_produto from "./components/pages/sistema/Cad_produto";
import Empresa from "./components/pages/sistema/Empresa";
import AlterarSenha from "./components/pages/sistema/AlterarSenha";
import Error404 from "./components/pages/sistema/Error404";
import Interesse from "./components/pages/Interesse";
import Carrinho from "./components/pages/sistema/Carrinho";
import Politica from "./components/pages/politica/politica";
import Login_adm from "./components/Login/Login_adm";
import Login_transportadora from "./components/Login/Login_transportadora";
import Novasenha from "./components/Login/Novasenha";
import Logout from "./components/Login/Logout";
import GlobalStyle from "./components/styles";
import "./App.css";

class App extends Component {
  render() {
    const Layout = ({ children }) => (
      <div>
        <Navbar />
        <Switch>{children}</Switch>
        <Footer />
      </div>
    );

    return (
      <div>
        <GlobalStyle />
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            {/* <Route exact path="/" component={Login_lojista}/> */}
            <Route exact path="/" component={produtos_site} />
            <Route exact path="/home" component={produtos_site} />
            <Route path="/produtos" component={produtos_site} />
            <Route path="/categoria:cat" component={categoria_site} />
            <Route path="/carrinho" component={cart} />
            <Route path="/produto:id" component={produto_site} />
            <Route path="/obrigado" component={obrigado} />
            <Route path="/politica" component={Politica} />
            <Route path="/sobre" component={Sobre} />
            <Route path="/novasenha" component={Novasenha} />
            <Route exact path="/logout" component={Logout} />
            <Route path="/interesse" component={Interesse} />
            <Route path="/login_lojista" component={Login_lojista} />
            <Route path="/login_cliente" component={Login_cliente} />
            <Route
              path="/login_transportadora"
              component={Login_transportadora}
            />
            <Route path="/alterarsenha" component={AlterarSenhaCliente} />
            <Route path="/adm" component={Login_adm} />
            <PrivateRoute path="/conta" component={Conta_cliente} />
            <PrivateRoute path="/rastreamento" component={Carrinho_cliente} />
            <Layout>
              {/* rotas do painel ADM */}
              <PrivateRoute path="/admin/categoria" component={Categoria} />
              <PrivateRoute path="/admin/empresas" component={AdmEmpresas} />
              <PrivateRoute path="/admin/carrinho" component={AdmCarrinho} />
              <PrivateRoute
                path="/admin/listarclientes"
                component={ListarClientes}
              />

              <PrivateRoute path="/sistema/conta" component={Conta} />
              <PrivateRoute path="/sistema/empresa" component={Empresa} />

              <PrivateRoute path="/sistema/carrinho" component={Carrinho} />
              {/*  <PrivateRoute path='/sistema/produto' component={Produto} /> */}
              <PrivateRoute
                path="/sistema/cad_produto"
                component={Cad_produto}
              />
              {/*<PrivateRoute path='/sistema/cad_produto/:prod_id' component={Cad_produto} />*/}
              <PrivateRoute
                path="/sistema/alterarsenha"
                component={AlterarSenha}
              />
              <PrivateRoute path="*" component={Error404} />
            </Layout>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
