import React, { Component } from "react";
import Navbar_public from "../layouts/Navbar_public";
import "../styles/Produtos.css";
import { mask_minusculo_sem_espaco } from "../plugins/Mascara/Mascara";
import { api, handleError } from "../plugins/Config/Config";

export default class Login_lojista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_onload: true,
      tipo: "",
      email: "",
      codigo: "",
      model: {
        senha: "",
        email: "",
      },
    };
  }

  login = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("nivel");
    localStorage.removeItem("nome");
    sessionStorage.removeItem("token");
    localStorage.setItem("credentials", "basic admin: admin");
    localStorage.setItem("username", "admin");
    localStorage.setItem("password", "admin");

    const model = this.state.model;
    if (model.email !== "" && model.senha !== "") {
      let data = {
        email: model.email,
      };

      api({
        method: "get",
        url: "api/login/" + model.email + "/" + model.senha + "/3",
        data: data,
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.adm_situacao === "A") {
           // console.log(response);
            localStorage.setItem("id", response.data.adm_id);
            localStorage.setItem("nome", response.data.adm_nome);
            localStorage.setItem("nivel", 3);
            sessionStorage.setItem("token",response.data.token);
            //  localStorage.setItem('senha',response.data[0].senha);

            this.props.history.push("admin/empresas");
            return;
          } else {
            handleError("login ou senha incorretos");
          }
        })
        .catch((error) => {
          handleError("login ou senha incorretos");
          console.log(error.response);
          return false;
        });
    } else {
      handleError("Insira o email e senha da sua conta");
    }
  };

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = mask_minusculo_sem_espaco(e.target.value);

    this.setState({ model });
  };

  componentDidMount = () => {
    if (this.state.state_onload === true) {
      this.setState({ state_onload: false });
    }
  };

  Limpar = () => {
    this.setState({
      model: {
        senha: "",
        email: "",
      },
    });
    // this.setState({ tipo: '' });
    //this.setState({ email: '' });
  };
  render() {
    const { model } = this.state;

    return (
      <>
        <Navbar_public />

        <div className="container">
          <div class="form-group row">
            {/* ininio do formulario de login*/}
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
              <div class="card text-center">
                <div class="card-header_nav">
                  <div class="card-header_nav">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="home-tab"
                          data-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          <b>Painel ADM</b>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-body">
                  <form>
                    <div class="form-group row">
                      <label
                        for="email"
                        style={{ color: "#7c7f82" }}
                        class="col-sm-3 col-form-label"
                      >
                        <b>Email:</b>
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          value={model.email}
                          placeholder="Email"
                          onChange={(e) => this.setValues(e, "email")}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="senha"
                        style={{ color: "#7c7f82" }}
                        class="col-sm-3 col-form-label"
                      >
                        <b>Senha:</b>
                      </label>
                      <div class="input-group col-sm-9">
                        <input
                          type="password"
                          style={{ width: "80%" }}
                          autoComplete="new-password"
                          maxLength="10"
                          class="form-control"
                          id="senha"
                          value={model.senha}
                          placeholder="Senha"
                          onChange={(e) => this.setValues(e, "senha")}
                        />
                        <div class="input-group-prepend">
                          <div
                            onClick={(e) =>
                              (document.getElementById("senha").type =
                                document.getElementById("senha").type == "text"
                                  ? "password"
                                  : "text")
                            }
                            class="input-group-text"
                          >
                            <i class="fas fa-eye"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-2"> </div>
                      <div class="col-sm-8">
                        <button
                          type="button"
                          onClick={this.login}
                          class="btn btn-block btn-primary"
                        >
                          Login
                        </button>
                      </div>
                      <div class="col-sm-2"> </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer text-muted">
                  <span> esqueci minha senha?</span>
                </div>
              </div>
            </div>
            <div class="col-sm-4"></div>
          </div>
        </div>
      </>
    );
  }
}
