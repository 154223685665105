import React, { Component } from "react";
import Navbar_public from "../layouts/Navbar_public";
import "../styles/Produtos.css";
import { api, handleError, handleSuccess,handleInfo } from "../plugins/Config/Config";
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
export default class Login_lojista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_onload: true,
      tipo: "",
      email: "",
      codigo: "",
      model: {
        senha_repetir: "",
        senha: "",
      },
    };
  }

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    this.setState({ model });
  };

  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  novasenha = () => {
    var tipo = this.state.tipo;
    const model = this.state.model;
    if (
      model.email !== "" &&
      model.senha !== "" &&
      model.senha_repetir !== ""
    ) {
      if (model.senha === model.senha_repetir) {
        let data = {
          email: this.state.email,
          senha: model.senha,
          codigo: this.state.codigo,
        };

        if (tipo === "1") {
          var url = "api/arealojista/1";
        } else {
          url = "api/cliente/2";
        }

        api({
          method: "put",
          url: url,
          data: data,
          headers: {
            Authorization: "bearer " + sessionStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status === "sucesso") {
              handleSuccess(response.data.message);
              if (tipo === "1") {
                this.props.history.push("/login_lojista");
              } else {
                this.props.history.push("/login_cliente");
              }
            } else {
              handleError(response.data.message);
              this.Limpar();
            }
          })
          .catch((error) => {
            if (error.response) {
              this.catchResponse(error.response.status);
            }
          });
      } else {
        handleError("A senha repetida difere da nova senha");
      }
    } else {
      handleError("Preencha todos campos");
    }
  };

  componentDidMount = () => {
    if (this.state.state_onload === true) {
      this.setState({ state_onload: false });
      var url = window.location.pathname;
      var items = url.split("/");
      if (items[2] && items[3]) {
      //  console.log(JSON.stringify(items));

        let tipo = items[2].substr(-1);
        this.setState({ tipo: tipo });
        let email = atob(items[2].substr(0, items[2].length - 1));
      //  console.log(email);
        this.setState({ email: email });
        this.setState({ codigo: items[3] });
      }
    }
  };

  Limpar = () => {
    this.setState({
      model: {
        senha: "",
        senha_repetir: "",
      },
    });
    // this.setState({ tipo: '' });
    //this.setState({ email: '' });
  };
  render() {
    const { model } = this.state;

    return (
      <>
        <Navbar_public />

        <div className="container">
          <div class="form-group row">
            {/* ininio do formulario de login*/}
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
              <div class="card text-center">
                <div class="card-header_nav">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        <b>Alterar senha</b>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <form>
                    <div class="form-group row">
                      <label
                        for="email"
                        style={{ color: "#7c7f82" }}
                        class="col-sm-3 col-form-label"
                      >
                        <b>Login:</b>
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          readOnly
                          value={this.state.email}
                          placeholder="Email"
                          onChange={(e) => this.setValues(e, "email")}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="senha"
                        style={{ color: "#7c7f82" }}
                        class="col-sm-3 col-form-label"
                      >
                        <b>Senha:</b>
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="password"
                          style={{ width: "100%" }}
                          class="form-control"
                          id="senha"
                          value={model.senha}
                          placeholder="Senha"
                          onChange={(e) => this.setValues(e, "senha")}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="senha_repetir"
                        style={{ color: "#7c7f82" }}
                        class="col-sm-3 col-form-label"
                      >
                        <b>Repetir:</b>
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="password"
                          style={{ width: "100%" }}
                          class="form-control"
                          id="senha_repetir"
                          value={model.senha_repetir}
                          placeholder="Repetir Senha"
                          onChange={(e) => this.setValues(e, "senha_repetir")}
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-2"> </div>
                      <div class="col-sm-8">
                        <button
                          type="button"
                          onClick={this.novasenha}
                          class="btn btn-block btn-primary"
                        >
                          Alterar
                        </button>
                      </div>
                      <div class="col-sm-2"> </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer text-muted">
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
              </div>
            </div>
            <div class="col-sm-4"></div>
          </div>
        </div>
      </>
    );
  }
}
