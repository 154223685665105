import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../imagens/logo.png'
import Footer from '../../../components/footer/footer'
import Navbar_public from '../../layouts/Navbar_public'
export default class Sobre extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tela: 0
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.Verificatela()
    }
    Verificatela = () => {
        if (window.innerWidth < 640 || window.innerHeight < 480) {
            this.setState({ tela: 1 })
        } else if (window.innerWidth < 1024 || window.innerHeightt < 768) {
            this.setState({ tela: 2 })
        } else {

        }
    }
    render() {
        const { tela } = this.state
        document.title = 'Keepdelivery | Sobre'
        return (
            <>
                {tela === 1 ?
                    <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ fontSize: '15px' }}>
                        <Link className="navbar-brand" to="/home">
                            <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#conteudoNavbarSuportado" aria-controls="conteudoNavbarSuportado" aria-expanded="false" aria-label="Alterna navegação">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="conteudoNavbarSuportado">
                            <ul className="navbar-nav mr-auto">
                                {localStorage.getItem('nome') === null ?
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/login_transportadora">Transportadora <span class='fas fa-truck'></span></Link>
                                    </li>
                                    : ''
                                }
                                {localStorage.getItem('nome') === null ?
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login_lojista">Lojista <span class='fas fa-user-tie'></span></Link>
                                    </li>
                                    : ''
                                }
                                {localStorage.getItem('nome') === null ?
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login_cliente">Cliente <span class='fas fa-user'></span></Link>
                                    </li>

                                    :
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {localStorage.getItem('nome')} <span className='fas fa-user' />
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {localStorage.getItem('nivel') === '2' ?
                                                <>
                                                    <Link className="nav-link" to="/rastreamento">Meus Pedidos<span className="far fa-list-alt"></span></Link>
                                                    <Link className="nav-link" to="/conta">Conta <span className="fas fa-users-cog"></span></Link>
                                                </>
                                                : ''
                                            }
                                            <Link className="nav-link" to="/logout">Sair <span className="fas fa-sign-out-alt"></span></Link>
                                        </div>
                                    </li>
                                }

                                <li className="nav-item">
                                    {//mudei aqui
                                        localStorage.getItem('carrinho') ?
                                            <Link className="dropdown-item link" style={{ textDecoration: 'none', display: 'block' }} to="/carrinho"><span className='icon-menu'>{localStorage.getItem('carrinho')}</span>&nbsp;<i style={{ color: '#11ca17', fontSize: '16px' }} className="fas fa-shopping-cart"></i></Link>
                                            : <Link className="dropdown-item link" style={{ textDecoration: 'none', display: 'block' }} to="/produtos"><span className='icon-menu'>0</span>&nbsp;<i style={{ color: '#11ca17', fontSize: '16px' }} className="fas fa-shopping-cart"></i></Link>
                                    }
                                </li>
                            </ul>
                        </div>
                    </nav> :
                    <Navbar_public />}
                <div class="site-wrap">
                    <div class="site-section border-bottom" data-aos="fade">
                        <div class="container">
                            <div class="row mb-5">
                                <div class="col-md-6">
                                    <div class="block-16">
                                        <figure>
                                            <img src='http://cadastre.keepdelivery.com.br/wp-content/uploads/2020/04/img1.jpg' alt="Image placeholder" class="img-fluid rounded" />
                                            
                                        </figure>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-5">
                                    <div class="site-section-heading pt-3 mb-4">
                                        <h2 class="text-black">AMPLIANDO SEU CANAL DE COMUNICAÇÃO E VENDAS</h2>
                                    </div>
                                    <p>Nossa plataforma vai disponibilizar gratuitamente de forma organizada e intuitiva, os produtos de lojas, farmácias, supermercados ou qualquer estabelecimento comercial, com integração as suas redes sociais em apenas um click, você disponibiliza seus produtos inserindo fotos, valores quantidades em estoque, tudo muito simples e descomplicado.</p>
                                </div>
                            </div>
                            <div class="row mb-5">
                                <div class="col-md-6">
                                    <div class="block-16">
                                        <figure>
                                            <img src='http://cadastre.keepdelivery.com.br/wp-content/uploads/2020/04/img2-1.jpg' alt="Image placeholder" class="img-fluid rounded" />
                                            

                                        </figure>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-5">
                                    <div class="site-section-heading pt-3 mb-4">
                                        <h2 class="text-black">PLATAFORMA DE FÁCIL UTILIZAÇÃO</h2>
                                    </div>
                                    <p>O cliente escolhe os produtos e informa se quer fazer uma prova (montar uma sacola) ou comprar, e imediatamente você recebe uma notificação via app e um email com informações do pedido, tudo muito fácil e rápido, sem a necessidade de ter uma loja on-line, o lojista poderá oferecer seus produtos aos clientes diretamente nas telas de nosso aplicativo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}