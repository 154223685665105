import React, { Component } from "react";
import Navbar_public from "../layouts/Navbar_public";

import {
  mask_tel_celular_area,
  mask_CNPJ,
  mask_minusculo_sem_espaco,
} from "../plugins/Mascara/Mascara";
import {
  api,
  handleError,
  handleInfo,
  handleSuccess,
} from "../plugins/Config/Config";
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
export default class Login_cliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        cli_id: 0,
        cli_nome: "",
        cli_email: "",
        cli_perfilfacebook: "",
        cli_nascimento: "",
        cli_cnpj: "",
        cli_fone: "",
        cli_senha: "",
        cli_bairro: "",
        cli_cidade: "",
        cli_complemento: "",
        cli_numero: "",
        cli_senha_repetir: "",
        cli_endereco: "",
      },
    };
  }

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;

    model.cli_fone = mask_tel_celular_area(model.cli_fone);
    model.cli_cnpj = mask_CNPJ(model.cli_cnpj);

    model.cli_email = mask_minusculo_sem_espaco(model.cli_email);
    model.cli_senha = mask_minusculo_sem_espaco(model.cli_senha);
    this.setState({ model });
  };

  get_cliente = () => {
    api
      .get("api/cliente/" + localStorage.getItem("id"))
      .then((response) => {
        response.data = response.data[0];

        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data != "") {
          this.setState({
            model: {
              cli_id: response.data.cli_id,
              cli_nome: response.data.cli_nome,
              cli_email: response.data.cli_email,
              cli_perfilfacebook: response.data.cli_perfilfacebook,
              cli_nascimento: response.data.cli_nascimento,
              cli_cnpj: response.data.cli_cnpj,
              cli_fone: response.data.cli_fone,
              cli_senha: response.data.cli_senha,
              cli_bairro: response.data.cli_bairro,
              cli_cidade: response.data.cli_cidade,
              cli_complemento: response.data.cli_complemento,
              cli_numero: response.data.cli_numero,
              cli_senha_repetir: response.data.cli_senha,
              cli_endereco: response.data.cli_endereco,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  save = () => {
    const model = this.state.model;
    // console.log(JSON.stringify(model));
    if (
      model.cli_nome !== "" &&
      model.cli_email !== "" &&
      model.cli_fone !== "" &&
      model.cli_bairro !== "" &&
      model.cli_cidade !== "" &&
      model.cli_numero !== "" &&
      model.cli_endereco !== ""
    ) {
      /*    if (model.cli_senha !== model.cli_senha_repetir) {
        handleError("Senha repetida não coincide com a senha");
        return false;
      }*/

      let data = {
        cli_id: parseInt(model.cli_id),
        cli_nome: model.cli_nome,
        cli_email: model.cli_email,
        cli_nascimento: model.cli_nascimento,
        cli_cnpj: model.cli_cnpj,
        cli_fone: model.cli_fone,
        cli_perfilfacebook: model.cli_perfilfacebook,
        cli_bairro: model.cli_bairro,
        cli_cidade: model.cli_cidade,
        cli_complemento: model.cli_complemento,
        cli_numero: model.cli_numero,
        cli_endereco: model.cli_endereco,
      };

      // console.log('data eh: ' + JSON.stringify(data));

      api({
        method: "put",
        url: "api/cliente",
        data: data,
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          handleSuccess(response.data.message);
          //this.Limpar();
          this.get_cliente();
        })
        .catch((error) => {
          handleError("Erro ao executar");
          console.log(error.response);
          return false;
        });
    } else {
      handleError("Preencha todos os campos");
    }
  };

  goBack = () => {
    window.history.back();
  };
  componentDidMount = () => {
    this.get_cliente();
  };

  Limpar = () => {
    this.setState({
      model: {
        cli_id: 0,
        cli_nome: "",
        cli_email: "",
        cli_perfilfacebook: "",
        cli_nascimento: "",
        cli_cnpj: "",
        cli_fone: "",
        cli_senha: "",
        cli_bairro: "",
        cli_cidade: "",
        cli_complemento: "",
        cli_numero: "",
        cli_senha_repetir: "",
        cli_endereco: "",
      },
    });
  };

  render() {
    const { model } = this.state;

    return (
      <>
        <Navbar_public />
        <div className="container">
          <div class="form-group row">
            {/* ininio do formulario de registro*/}
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <button
                type="button"
                onClick={(e) => this.goBack()}
                class="btn btn-primary"
              >
                <i class="fas fa-arrow-left"></i>&nbsp;Voltar
              </button>
              <div class="card text-center">
                <div class="card-header_nav">
                  <h2 className="text-center">Dados da conta</h2>
                </div>
                <div class="card-body">
                  <form>
                    <div class="form-group row">
                      <div class="col-sm-5">
                        <label
                          for="cli_nome"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Nome:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="cli_nome"
                          value={model.cli_nome}
                          placeholder="Nome"
                          onChange={(e) => this.setValues(e, "cli_nome")}
                        />
                      </div>
                      <div class="col-sm-5">
                        <label
                          for="cli_endereco"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Endereço:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="cli_endereco"
                          value={model.cli_endereco}
                          placeholder="Endereço"
                          onChange={(e) => this.setValues(e, "cli_endereco")}
                        />
                      </div>
                      <div class="col-sm-2">
                        <label
                          for="cli_numero"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Numero:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="cli_numero"
                          value={model.cli_numero}
                          placeholder="Numero"
                          onChange={(e) => this.setValues(e, "cli_numero")}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label
                          for="cli_complemento"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Complemento:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="cli_complemento"
                          value={model.cli_complemento}
                          placeholder="Exemplo: Ao lado da casa de muro verde"
                          onChange={(e) => this.setValues(e, "cli_complemento")}
                        />
                      </div>
                      <div class="col-sm-6">
                        <label
                          for="cli_bairro"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Bairro:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="cli_bairro"
                          value={model.cli_bairro}
                          placeholder="Bairro"
                          onChange={(e) => this.setValues(e, "cli_bairro")}
                        />
                      </div>
                      <div class="col-sm-5">
                        <label
                          for="cli_cidade"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Cidade:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="cli_cidade"
                          value={model.cli_cidade}
                          placeholder="Cidade"
                          onChange={(e) => this.setValues(e, "cli_cidade")}
                        />
                      </div>
                      <div class="col-sm-3">
                        <label
                          for="cli_fone"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Telefone/Celular:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="cli_fone"
                          value={model.cli_fone}
                          placeholder="Fone com DDD"
                          onChange={(e) => this.setValues(e, "cli_fone")}
                        />
                      </div>
                      <div class="col-sm-4">
                        <label
                          for="cli_email"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>
                            Email:<b style={{ fontSize: "10px" }}>(Login)</b>
                          </b>
                        </label>

                        <input
                          type="email"
                          class="form-control"
                          id="cli_email"
                          readOnly
                          value={model.cli_email}
                          placeholder="Email"
                          onChange={(e) => this.setValues(e, "cli_email")}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div
                        class="input-group col-sm-3"
                        style={{ display: "none" }}
                      >
                        <label
                          for="cli_senha"
                          style={{ width: "100%", color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>
                            Senha:
                            <b style={{ fontSize: "10px" }}>
                              (Max 10 caracteres)
                            </b>
                          </b>
                        </label>

                        <input
                          type="password"
                          class="form-control"
                          id="cli_senha"
                          maxLength="10"
                          value={model.cli_senha}
                          placeholder="Senha"
                          onChange={(e) => this.setValues(e, "cli_senha")}
                        />
                        <div class="input-group-prepend">
                          <div
                            onClick={(e) =>
                              (document.getElementById("cli_senha").type =
                                document.getElementById("cli_senha").type ==
                                "text"
                                  ? "password"
                                  : "text")
                            }
                            class="input-group-text"
                          >
                            <i class="fas fa-eye"></i>
                          </div>
                        </div>
                      </div>
                      <div
                        class="input-group col-sm-3"
                        style={{ display: "none" }}
                      >
                        <label
                          for="cli_senha_repetir"
                          style={{ width: "100%", color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>
                            {" "}
                            Repetir:
                            <b style={{ fontSize: "10px" }}>
                              (Max 10 caracteres)
                            </b>
                          </b>
                        </label>

                        <input
                          type="password"
                          class="form-control"
                          id="cli_senha_repetir"
                          maxLength="10"
                          value={model.cli_senha_repetir}
                          placeholder="Repeta a sua Senha"
                          onChange={(e) =>
                            this.setValues(e, "cli_senha_repetir")
                          }
                        />
                        <div class="input-group-prepend">
                          <div
                            onClick={(e) =>
                              (document.getElementById(
                                "cli_senha_repetir"
                              ).type =
                                document.getElementById("cli_senha_repetir")
                                  .type == "text"
                                  ? "password"
                                  : "text")
                            }
                            class="input-group-text"
                          >
                            <i class="fas fa-eye"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-3 " style={{ display: "none" }}>
                        <label
                          for="cli_nascimento"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>Data de Nascimento:</b>
                        </label>

                        <input
                          type="date"
                          class="form-control"
                          id="cli_nascimento"
                          value={model.cli_nascimento}
                          placeholder="Data de Nascimento"
                          onChange={(e) => this.setValues(e, "cli_nascimento")}
                        />
                      </div>
                      <div class="col-sm-3" style={{ display: "none" }}>
                        <label
                          for="cli_cnpj"
                          style={{ color: "#7c7f82" }}
                          class="col-form-label"
                        >
                          <b>CPF/CNPJ:</b>
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="cli_cnpj"
                          value={model.cli_cnpj}
                          placeholder="CPF ou CNPJ"
                          onChange={(e) => this.setValues(e, "cli_cnpj")}
                        />
                      </div>
                    </div>
                    <br />
                    <div class="form-group row mt-2">
                      <div class="col-sm-4"> </div>
                      <div class="col-sm-4">
                        <button
                          type="button"
                          onClick={this.save}
                          class="btn btn-block btn-primary"
                        >
                          Alterar Dados
                        </button>
                      </div>
                      <div class="col-sm-4"> </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer text-muted">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
            <div class="col-sm-1"></div>
          </div>
        </div>
      </>
    );
  }
}
