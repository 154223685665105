/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../imagens/footer/apoio-acil.png";
import LazyLoad from "react-lazyload";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tela: 0,
      col: 6,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.verifica_tela();
  }
  verifica_tela = () => {
    if (window.innerWidth < 640 || window.innerHeight < 480) {
      this.setState({ tela: 1 });

      this.setState({ col: 12 });
    } else if (window.innerWidth < 1024 || window.innerHeight < 768) {
      this.setState({ tela: 0 });
    } else {
    }
  };
  render() {
    const { tela, col } = this.state;
    return (
      <footer className="page-footer font-small blue pt-4">
        <div
          className="container-fluid text-center text-md-left"
          style={{ backgroundColor: "#E6E7E8" }}
        >
          <br />
          <div className="row">
            {tela === 1 ? (
              <div className={`col-${col} col-md-4`}>
                <span className="text-secondary h5">
                  <b>SIGA-NOS</b>
                </span>
                <br />
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <a
                    target="blank"
                    href="https://www.facebook.com/keepdeliveryoficial/"
                  >
                    <button type="button" className="btn btn-info btn-lg mr-2">
                      <i className="fab fa-facebook-square"></i>
                    </button>
                  </a>
                  <a
                    target="blank"
                    href="https://www.instagram.com/keepdelivery/"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-lg  mr-2"
                    >
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>
                  <a
                    target="blank"
                    href="https://api.whatsapp.com/send?phone=551935737080"
                  >
                    <button type="button" className="btn btn-success btn-lg">
                      <i className="fab fa-whatsapp"></i>
                    </button>
                  </a>
                </div>
              </div>
            ) : (
              <div className={`col-${col} col-md-4`}>
                <span className="text-secondary h5">
                  <b>SIGA-NOS</b>
                </span>
                <br />
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <a
                    target="blank"
                    href="https://www.facebook.com/keepdeliveryoficial/"
                  >
                    <button type="button" className="btn btn-info btn-lg mr-2">
                      <i className="fab fa-facebook-square"></i>
                    </button>
                  </a>
                  <a
                    target="blank"
                    href="https://www.instagram.com/keepdelivery/"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-lg  mr-2"
                    >
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>
                </div>
              </div>
            )}

            <div className={`col-${col} col-md-4`}>
              {tela === 1 ? <hr /> : ""}
              <span className="text-secondary h5">
                <b>INSTITUCIONAL</b>
              </span>
              <br />
              <div className="col-md-8 mb-md-0 mb-3">
                <ul className="list-unstyled">
                  <li>
                    <a className="text-secondary" href="/produtos">
                      <b>INICIO</b>
                    </a>
                  </li>
                  <li>
                    <Link className="text-secondary" to="/sobre">
                      <b>SOBRE NÓS</b>
                    </Link>
                  </li>
                  <li>
                    <Link className="text-secondary" to="/politica">
                      <b> POLITICA DE PRIVAVIDADE</b>
                    </Link>
                  </li>
                  <li>
                    <a
                      className="text-secondary"
                      href="mail:contato@keepdelivery.com.br"
                    >
                      <b>CONTATO:</b>
                      {tela === 1 ? <br /> : ""} contato@keepdelivery.com.br
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`col-${col} col-md-4`}>
              {tela === 1 ? <hr /> : ""}
              {/*<span className="text-secondary h6">
                <b>Apoio</b>
              </span>
            <br />{" "}*/}
              <LazyLoad>
                <img
                  src={logo}
                  className="img-fluid mr-2"
                  style={{ maxWidht: "60px", maxHeight: "60px" }}
                  alt="Responsive image"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
        <div
          className="footer-copyright text-center py-3  bg-danger"
          style={{ color: "#ffff" }}
        >
           © Keepdelivery Todos os direitos
          reservados
        </div>
      </footer>
    );
  }
}
export default Footer;
