import React, { Component } from "react";
import "../../styles/styles.css";
import {
  dominio,
  api,
  handleError,
  handleSuccess,
  handleInfo,
} from "../../plugins/Config/Config";
import Select from "react-select";
import sem_imagem from "../../../imagens/sem_imagem.jpg";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo: "",
      tipo: "",
      legenda: "",
      carrinho: [],
      selectedOption: "",
      statusOptions: [],
      carrinho_produtos: [],
      status_array: [],
      modal: [],
      model: {
        horario_recoleta: "",
        data_recoleta: "",
        horario_coleta: "",
        data_coleta: "",
        car_datarecoleta: "",
      },
    };
    this.FiltrarCampos = this.FiltrarCampos.bind(this);
  }

  catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        this.props.history.push("/logout");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };
  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;

    this.setState({ model });
  };
  get_carrinho() {
    let url = "";

    url = "api/carrinho/1/" + localStorage.getItem("nivel") + "/";

    api
      .get(url + localStorage.getItem("id"))
      .then((response) => {
        // console.log(response.data);
        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data !== "") {
          this.setState({ carrinho: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  get_carrinho_produtos(carrinho) {
    const status_array = [];
    this.setState({ status_array });
    let modal = [];
    modal = carrinho;
    this.setState({ modal });
    api
      .get("api/carrinho/2/" + modal.car_id)
      .then((response) => {
        // console.log(response.data);

        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data !== "") {
          this.setState({ carrinho_produtos: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  get_transportadora() {
    api
      .get("api/transportadora/1/")
      .then((response) => {
        const statusOptions = [];
        // construindo o corpo das options do select com as variaves vindas da api atraves do axios
        statusOptions.push({ value: 0, label: localStorage.getItem("nome") });
        for (var i = 0; i < response.data.length; i++) {
          statusOptions.push({
            value: response.data[i].tr_id,
            label: response.data[i].tr_nomefantasia,
          });
        }
        //  statusOptions.push({ value: -1, label: 'TODOS GERENTES' });
        this.setState({ statusOptions }); // salvando o novo state
      })
      .catch((error) => {
        console.log(error);
      });
  }

  validar_data_hora = (data_estimada) => {
    // Inicializamos o objeto Date() com data e horário atual
    const date1 = new Date();
    // Inicializamos uma data no passado
    //const date2 = new Date('2018-04-07 12:30:00');
    const date2 = new Date(data_estimada);
    // Verificamos se primeira data é igual, maior ou menor que a segunda
    if (date1.getTime() === date2.getTime()) {
   //   console.log("As datas são iguais");
      return 0;
    } else if (date1.getTime() > date2.getTime()) {
      //console.log(date1.toString() + ' maior que ' + date2.toString());
      return 0;
    } else {
      // console.log(date1.toString() + ' menor que ' + date2.toString());
      return 1;
    }
  };

  save(id_modal) {
    const modal = this.state.modal;
    const model = this.state.model;
    const tipo = this.state.tipo;
    // console.log(tipo);
    if (tipo === 0) {
      if (!model.horario_coleta || !model.data_coleta) {
        handleError("Preencha o campo data e horario de coleta");
        return false;
      } else if (
        this.validar_data_hora(
          model.data_coleta + " " + model.horario_coleta + ": 00"
        ) === 0
      ) {
        handleError("Data de coleta estimada é anterior a data atual");
        return false;
      }
    } else if (tipo === 4) {
      if (!model.horario_recoleta || !model.data_recoleta) {
        handleError("Preencha o campo data e horario de recoleta");
        return false;
      } else if (
        this.validar_data_hora(
          model.data_recoleta + " " + model.horario_recoleta + ": 00"
        ) === 0
      ) {
        handleError("Data de recoleta estimada é anterior a data atual");
        return false;
      }
    } else if (tipo === 2) {
      if (
        !this.state.selectedOption.value &&
        this.state.selectedOption.value !== 0
      ) {
        handleError("Selecione uma transportadora");
        return false;
      }
    }

    let data = {
      car_datarecoleta:
        model.data_recoleta + " " + model.horario_recoleta + ":00",
      car_datacoleta: model.data_coleta + " " + model.horario_coleta + ":00",
      car_transportadora: this.state.selectedOption.value,
      car_id: modal.car_id,
      tipo: tipo,
    };

    // console.log(JSON.stringify(data));return false;
    api({
      method: "put", //you can set what request you want to be
      url: "api/carrinho",
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.data.status === "sucesso") {
          handleSuccess(response.data.message);
          document.getElementById("btnclose1").click();
          document.getElementById("btnclose2").click();
          this.get_carrinho();
        } else {
          handleInfo(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          this.catchResponse(error.response.status);
        }
      });
  }
  save_status = () => {
    const status_array = this.state.status_array;
    if (status_array.length < 1) {
      handleInfo("Nenhum alteração realizada");
      return false;
    }
    let data = {
      status_array: status_array,
    };

    //  console.log(JSON.stringify(data));return false;
    api({
      method: "put", //you can set what request you want to be
      url: "api/carrinho",
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        const status_array = [];
        this.setState({ status_array });
        if (response.data.status === "sucesso") {
          handleSuccess(response.data.message);
        } else {
          handleInfo(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        handleError("Falha ao executar");
        return false;
      });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }); // atualizando o state de  selectedOption
  };
  FiltrarCampos() {
    var input, filter, table, tr, td, i;
    input = document.getElementById("filtro");
    filter = input.value.toUpperCase();
    table = document.getElementById("liberacao");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
      var tds = tr[i].getElementsByTagName("td");
      var flag = false;
      for (var j = 0; j < tds.length - 2; j++) {
        td = tds[j];

        if (td.textContent.toUpperCase().indexOf(filter) > -1) {
          flag = true;
        }
      }
      if (flag) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }

  set_modal = (value) => {
    return this.setState({ modal: value });
  };

  modal = (carrinho, tipo, titulo, legenda) => {
    this.setState({ titulo: titulo });
    this.setState({ legenda: legenda });

    this.get_carrinho_produtos(carrinho);

    this.setState({ tipo: tipo });
    setTimeout(() => {
      if (tipo > 2) {
      //  console.log(tipo);
        this.save();
      }
    }, 100);
  };

  mask_data_hora = (data_hora) => {
    if (!data_hora || data_hora.length < 19) {
      return false;
    }
    data_hora = new Date(data_hora);
    let dateString = data_hora
      .toLocaleString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(/\//g, "/");
    return dateString;
  };

  status_carrinho_produto = (carp_idcarrinho, carp_produto, status, carp) => {
    if (localStorage.getItem("nivel") !== "1") {
      return false;
    }

    const status_array = this.state.status_array;
    const carrinho_produtos = this.state.carrinho_produtos;

    let index = status_array.findIndex(
      (val) =>
        val.carp_idcarrinho === carp_idcarrinho &&
        val.carp_produto === carp_produto
    );
    if (index < 0) {
      status_array.push({
        carp_idcarrinho: carp_idcarrinho,
        carp_produto: carp_produto,
        carp_status: status,
      });
    } else {
      status_array.splice(index, 1);
    }
    this.setState({ status_array });

    this.setState({ carrinho_produtos });
  };
  filter_carrinho_produtos = (prod_id, carp_idcarrinho, status_char) => {
    //console.log('filter '+JSON.stringify(value));
    var status = false;

    const status_array = this.state.status_array;

    if (status_array.length > 0) {
      for (let i = 0; i < status_array.length; i++) {
       // console.log("statys array " + JSON.stringify(status_array[i].carp_status));
        if (
          status_array[i].carp_idcarrinho === carp_idcarrinho &&
          status_array[i].carp_produto === prod_id
        ) {
          return status_array[i].carp_status;
        } else {
          status = true;
        }
      }
      if (status) {
        return status_char;
      }
    } else {
      return status_char;
    }
  };
  componentDidMount = () => {
  //  console.log("esta vazio? " + localStorage.getItem("nivel"));
    this.get_carrinho();
    this.get_transportadora();
  };

  render() {
    const { carrinho, modal, carrinho_produtos } = this.state;
  //  console.log(JSON.stringify(this.state.status_array));

    const { selectedOption, statusOptions } = this.state;
  //  console.log("modal " + JSON.stringify(modal));
    return (
      <div className="container-fluid" style={{ marginBottom: "50px" }}>
        <div
          className="modal fade bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          id="carrinho_produtos"
          aria-labelledby="carrinho_produtos"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div
                style={{
                  borderBottom: "0px solid #dee2e6",
                  padding: "0px 10px 0px 0px",
                  // backgroundColor: "#dee2e6",
                }}
                className="text-center modal-header"
              >
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      class="nav-item nav-link active"
                      id="nav-home-tab"
                      data-toggle="tab"
                      href="#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      <b>Cliente</b>
                    </a>
                    {localStorage.getItem("nivel") == "1" ? (
                      <a
                        class="nav-item nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="#nav-profile"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <b>Empresa</b>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </nav>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item list-group-item-action">
                            <b>Cliente:</b>
                            {" " + modal.cli_nome}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Endereço:</b>
                            {" " + modal.cli_endereco}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Complemento:</b>
                            {" " + modal.cli_complemento}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Cidade:</b>
                            {" " + modal.cli_cidade}
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item list-group-item-action">
                            <b>Número:</b>
                            {" " + modal.cli_numero}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Bairro:</b>
                            {" " + modal.cli_bairro}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Telefone:</b>
                            {" " + modal.cli_fone}
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item list-group-item-action">
                            <b>Observação:</b>
                            {" " + modal.car_texto}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item list-group-item-action">
                            <b>Empresa:</b>
                            {" " + modal.emp_nomefantasia}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Endereço:</b>
                            {" " + modal.emp_endereco}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Cidade:</b>
                            {" " + modal.emp_cidade}
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item list-group-item-action">
                            <b>Bairro:</b>
                            {" " + modal.cli_bairro}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Celular:</b>
                            {" " + modal.emp_celular}
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <b>Telefone:</b>
                            {" " + modal.emp_fone}
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item list-group-item-action">
                            <b>Observação:</b>
                            {" " + modal.car_texto}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-hover table-bordered text-center"
                    id="caap_produtos"
                    style={{ marginTop: "30px" }}
                  >
                    <thead className="thead-dark">
                      <tr style={{ display: "table-row" }}>
                        <th scope="col">Foto</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Quantidade</th>
                        {localStorage.getItem("nivel") === "0" ||
                        localStorage.getItem("nivel") === "2" ? (
                          <>
                            {" "}
                            <th scope="col">Valor unitario</th>
                            <th scope="col">Valor total</th>
                          </>
                        ) : (
                          ""
                        )}
                        <th scope="col">Prova</th>
                        <th scope="col">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {carrinho_produtos.map(
                        function (carp, i) {
                          var prod_img_src;

                          if (carp.prod_caminhofoto) {
                            var prod_caminhofoto = carp.prod_caminhofoto.split(
                              ","
                            );
                            prod_img_src =
                              dominio +
                              "/imagens/" +
                              carp.prod_id +
                              "/" +
                              prod_caminhofoto[0];
                          } else {
                            prod_img_src = sem_imagem;
                          } //console.log(modal);

                          return (
                            <tr key={i}>
                              <td>
                                <img
                                  style={{ maxHeight: "50px" }}
                                  src={prod_img_src}
                                  alt={prod_img_src}
                                />
                              </td>
                              <td>{carp.prod_nome}</td>
                              <td>{carp.carp_qtde}</td>
                              {localStorage.getItem("nivel") === "0" ||
                              localStorage.getItem("nivel") === "2" ? (
                                <>
                                  {" "}
                                  <td>{carp.carp_valorunitario}</td>
                                  <td>{carp.carp_valortotal}</td>
                                  <td>
                                    {" "}
                                    {carp.carp_prova === "T" ? (
                                      <button
                                        type="submit"
                                        title="SIM"
                                        className="btn btn-success"
                                        style={{ fontSize: "12px" }}
                                      >
                                        SIM
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        title="NÃO"
                                        className="btn btn-danger"
                                        style={{ fontSize: "12px" }}
                                      >
                                        NÃO
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    {this.filter_carrinho_produtos(
                                      carp.prod_id,
                                      carp.carp_idcarrinho,
                                      carp.carp_status
                                    ) === "C" ? (
                                      <button
                                        type="submit"
                                        title="Devolver produto"
                                        className="btn btn-warning"
                                        style={{ fontSize: "12px" }}
                                        onClick={(e) =>
                                          this.status_carrinho_produto(
                                            carp.carp_idcarrinho,
                                            carp.prod_id,
                                            "D",
                                            carp
                                          )
                                        }
                                      >
                                        Devolver
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        title="Produto Entregue"
                                        className="btn btn-success"
                                        style={{ fontSize: "12px" }}
                                        onClick={(e) =>
                                          this.status_carrinho_produto(
                                            carp.carp_idcarrinho,
                                            carp.prod_id,
                                            "C",
                                            carp
                                          )
                                        }
                                      >
                                        Entregue
                                      </button>
                                    )}
                                  </td>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        }.bind(this)
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                {this.state.status_array.length > 0 ? (
                  <button
                    type="button"
                    onClick={(e) => this.save_status()}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Salvar
                  </button>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="estimativa-de-entrega"
          style={{ marginTop: "50px" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="estimativa-de-entrega"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-dark"
                  id="exampleModalLongTitle"
                >
                  {this.state.titulo}
                </h5>
                <button
                  type="button"
                  className="close"
                  id="btnclose2"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <legend style={{ fontSize: "14px" }}>
                  {this.state.legenda}
                </legend>
                <form style={{ marginTop: "30px", marginBottom: "30px" }}>
                  <div className="form-row">
                    {this.state.tipo === 4 ? (
                      <>
                        <div className="col-md-5">
                          <label htmlFor="data_recoleta">Data estimada:</label>
                          <input
                            id="data_recoleta"
                            onChange={(e) => this.setValues(e, "data_recoleta")}
                            required="required"
                            value={this.state.model.data_recoleta}
                            className="form-control"
                            type="date"
                          />
                        </div>

                        <div className="col-md-5">
                          <label htmlFor="horario_recoleta">Horario:</label>
                          <input
                            type="time"
                            onChange={(e) =>
                              this.setValues(e, "horario_recoleta")
                            }
                            value={this.state.model.horario_recoleta}
                            className="form-control"
                            id="horario_recoleta"
                            name="horario_recoleta"
                            min="09:00"
                            max="18:00"
                            required
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-5">
                          <label htmlFor="data_coleta">Data Final:</label>
                          <input
                            id="data_coleta"
                            onChange={(e) => this.setValues(e, "data_coleta")}
                            required="required"
                            value={this.state.data_coleta}
                            className="form-control"
                            type="date"
                          />
                        </div>

                        <div className="col-md-5">
                          <label htmlFor="horario_coleta">Horario:</label>
                          <input
                            type="time"
                            onChange={(e) =>
                              this.setValues(e, "horario_coleta")
                            }
                            value={this.state.horario_coleta}
                            className="form-control"
                            id="horario_coleta"
                            name="horario_coleta"
                            min="09:00"
                            max="18:00"
                            required
                          />
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button
                  type="button"
                  onClick={(e) => this.save()}
                  className="btn btn-primary"
                >
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="escolha-de-transpotadora"
          style={{ marginTop: "50px" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="escolha-de-transpotadora"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-dark"
                  id="exampleModalLongTitle"
                >
                  Escolha de Transportadora
                </h5>
                <button
                  type="button"
                  className="close"
                  id="btnclose1"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <legend style={{ fontSize: "14px" }}>
                  Tesmpo estimado para a mercadoria ser entregue no cliente
                </legend>
                <form style={{ marginTop: "30px", marginBottom: "30px" }}>
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="gerente">Transportadora:</label>
                      <Select
                        id="gerente"
                        required
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={statusOptions}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button
                  type="button"
                  onClick={(e) => this.save()}
                  className="btn btn-primary"
                >
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <h2
          className="font-weight-bold text-center"
          style={{ marginTop: "30px", marginBottom: "30px" }}
        >
          {" "}
          Controle do Carrinho{" "}
        </h2>

        <form style={{ marginTop: "30px", marginBottom: "30px" }}>
          <div className="form-row">
            <div className="col-md-4 ">
              <label for="filtro">Filtrar Tabela:</label>
              <input
                id="filtro"
                className="form-control"
                onChange={(e) => this.FiltrarCampos()}
                required="required"
                type="text"
                placeholder="Termo..."
              />
            </div>

            <div className="col-md-2">
              <label htmlFor="atualizar">&nbsp;&nbsp;&nbsp;</label>
              <button
                type="button"
                title="Atualizar pagina"
                onClick={(e) => this.get_carrinho()}
                id="atualizar"
                className="form-control btn btn-success"
              >
                <b>
                  Atualizar Página&nbsp;<i className="fas fa-redo-alt"></i>
                </b>
              </button>
            </div>
            <div className="col-md-6"></div>
          </div>
        </form>
        {this.state.carrinho.length > 0 ? (
          <div className="table-responsive">
            <table
              className="table table-hover table-bordered text-center"
              id="liberacao"
              style={{ marginTop: "30px" }}
            >
              <thead className="thead-dark">
                <tr style={{ display: "table-row" }}>
                  {localStorage.getItem("nivel") === "0" ||
                  localStorage.getItem("nivel") === "2" ? (
                    <>
                      <th scope="col">Cliente</th>
                      <th scope="col">Carrinho criado</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Transportadora</th>
                      <th scope="col">Solicitação</th>
                      <th scope="col">Coleta</th>
                      <th scope="col">Entrega</th>
                      <th style={{ display: "none" }} scope="col">
                        Recoleta
                      </th>
                    </>
                  ) : (
                    <>
                      <th scope="col">Empresa</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Carrinho criado</th>
                      <th scope="col">Transportadora</th>
                      <th scope="col">Solicitação</th>
                      <th scope="col">Coleta</th>
                      <th scope="col">Entrega</th>
                      <th style={{ display: "none" }} scope="col">
                        Recoleta
                      </th>
                    </>
                  )}
                  <th scope="col">Status</th>
                  <th scope="col">Açóes</th>
                </tr>
              </thead>
              <tbody>
                {carrinho.map(
                  function (carro, i) {
                    var status;
                    switch (carro.car_statusentrega) {
                      case "P":
                        status = "Pendente";
                        break;
                      case "I":
                        status = "Informado";
                        break;
                      case "C":
                        status = "Coletado";
                        break;
                      case "E":
                        status = "Entregue";
                        break;
                      case "D":
                        status = "Devolvido";
                        break;
                    }
                    if (carro.tr_nomefantasia) {
                      var transportadora = carro.tr_nomefantasia;
                    } else if (
                      carro.car_datasolicitacao &&
                      !carro.tr_nomefantasia
                    ) {
                      transportadora = carro.emp_nomefantasia;
                    }
                    return (
                      <tr key={carro.car_id}>
                        {localStorage.getItem("nivel") === "0" ||
                        localStorage.getItem("nivel") === "2" ? (
                          <>
                            <td>{carro.cli_nome}</td>
                            <td>{this.mask_data_hora(carro.car_data)}</td>
                            <td>{carro.car_valortotal}</td>
                            <td>{transportadora}</td>
                            <td>
                              {this.mask_data_hora(carro.car_datasolicitacao)}
                            </td>
                            <td>{this.mask_data_hora(carro.car_datacoleta)}</td>
                            <td>
                              {this.mask_data_hora(
                                carro.car_dataentreguecliente
                              )}
                            </td>
                            <td style={{ display: "none" }}>
                              {this.mask_data_hora(carro.car_datarecoleta)}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>{carro.emp_nomefantasia}</td>
                            <td>{carro.cli_nome}</td>
                            <td>{this.mask_data_hora(carro.car_data)}</td>
                            <td>{transportadora}</td>
                            <td>
                              {this.mask_data_hora(carro.car_datasolicitacao)}
                            </td>
                            <td>{this.mask_data_hora(carro.car_datacoleta)}</td>
                            <td>
                              {this.mask_data_hora(
                                carro.car_dataentreguecliente
                              )}
                            </td>
                            <td style={{ display: "none" }}>
                              {this.mask_data_hora(carro.car_datarecoleta)}
                            </td>
                          </>
                        )}
                        <td>{status}</td>
                        <td>
                          {localStorage.getItem("nivel") === "1" ||
                          (carro.car_datasolicitacao !== null &&
                            carro.tr_nomefantasia === null) ? (
                            <>
                              {status === "Informado" ? (
                                <button
                                  title="Data de coleta"
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#estimativa-de-entrega"
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={(e) =>
                                    this.modal(
                                      carro,
                                      0,
                                      "Data de coleta",
                                      "Tempo estimado para a mercadoria ser coletada na loja pela transportadora"
                                    )
                                  }
                                >
                                  <i className="fas fa-truck"></i>{" "}
                                </button>
                              ) : (
                                ""
                              )}
                              {status === "Coletado" ? (
                                <button
                                  title="Confirma entrega ao cliente"
                                  type="button"
                                  className="btn btn-success"
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={(e) => this.modal(carro, 5)}
                                >
                                  <i className="fas fa-check"></i>{" "}
                                </button>
                              ) : (
                                ""
                              )}
                              {status === "Entregue" ? (
                                <button
                                  title="Data de recoleta"
                                  type="button"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#estimativa-de-entrega"
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={(e) =>
                                    this.modal(
                                      carro,
                                      4,
                                      "Data de Recoleta",
                                      "Data e hora que a transportadora agenda com o cliente para buscar o produto"
                                    )
                                  }
                                >
                                  <i className="fas fa-undo-alt"></i>{" "}
                                </button>
                              ) : (
                                ""
                              )}
                              {status === "Devolvido" ? "" : ""}
                            </>
                          ) : (
                            ""
                          )}
                          {localStorage.getItem("nivel") === "0" ? (
                            <>
                              {status === "Pendente" ? (
                                <button
                                  title="Escolhe a transportadora para entrega"
                                  type="button"
                                  className="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#escolha-de-transpotadora"
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={(e) => this.modal(carro, 2)}
                                >
                                  <i className="fas fa-truck"></i>{" "}
                                </button>
                              ) : (
                                ""
                              )}
                              {status === "Informado" ? (
                                <button
                                  title="confirma que a transportadora sacou o produto da loja"
                                  type="button"
                                  className="btn btn-primary"
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={(e) => this.modal(carro, 3)}
                                >
                                  <i className="fas fa-check"></i>{" "}
                                </button>
                              ) : (
                                ""
                              )}
                              {status === "Entregue" ? (
                                <button
                                  title="Cliente não quis o produto"
                                  type="button"
                                  className="btn btn-danger"
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={(e) => this.modal(carro, 6)}
                                >
                                  <i className="fas fa-times"></i>{" "}
                                </button>
                              ) : (
                                ""
                              )}
                              {status === "Devolvido" ? "" : ""}
                            </>
                          ) : (
                            ""
                          )}
                          <button
                            title="Ver detalhes"
                            data-toggle="modal"
                            data-target="#carrinho_produtos"
                            onClick={(e) => this.modal(carro, 1)}
                            type="button"
                            className="btn btn-primary"
                            style={{
                              fontSize: "14px",
                              marginTop: "10px",
                              marginLeft: "5px",
                            }}
                          >
                            <i className="far fa-eye"></i>{" "}
                          </button>
                        </td>
                      </tr>
                    );
                  }.bind(this)
                )}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default Home;
