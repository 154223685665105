import React, { Component } from 'react'
import Navbar_public from '../layouts/Navbar_public';
import FacebookLogin from "react-facebook-login";
import { mask_tel_celular_area, mask_CNPJ, mask_minusculo_sem_espaco, validar_Email } from '../plugins/Mascara/Mascara';
import { api, handleError, handleInfo, handleSuccess } from '../plugins/Config/Config';
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
export default class Login_cliente extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedOption: null,
            statusOptions: [],
            Empresa: [],
            formulario: 0,
            preloader: false,
            isLoggedIn: false,
            userID: "",
            name: "",
            email: "",
            picture: "",
            model: {
                cli_id: 0,
                cli_nome: '',
                cli_email: '',
                cli_perfilfacebook: '',
                cli_nascimento: '',
                cli_cnpj: '',
                cli_fone: '',
                cli_senha: '',
                cli_bairro: '',
                cli_cidade: '',
                cli_complemento: '',
                cli_numero: '',
                cli_senha_repetir: '',
                cli_endereco: '',

            },
        }
    }

    responseFacebook = response => {
        // console.log(response);
        if (response.picture) {
            this.setState({
                isLoggedIn: true,
                userID: response.userID,
                name: response.name,
                email: response.email,
                picture: response.picture.data.url
            });

            localStorage.setItem('userID', response.userID);
            localStorage.setItem('name', response.name);
            localStorage.setItem('email', response.email);
            localStorage.setItem('picture', response.picture.data.url);

            api({
                method: 'get',
                url: 'api/cliente/' + response.email + '/' + response.userID + '/facebook',               
            })
                .then(response => {
                    if (response.data[0].cli_situacao === 'A') {
                        //  console.log(response);
                        localStorage.removeItem('id');
                        localStorage.removeItem('nivel');
                        localStorage.removeItem('nome');
                        
                      
                        localStorage.setItem('id', response.data[0].cli_id);
                        localStorage.setItem('nome', response.data[0].cli_nome);
                        localStorage.setItem('nivel', '2');
                        //  localStorage.setItem('senha',response.data[0].emp_senha);

                        this.props.history.push("/produtos");
                        return;
                    }



                })
                .catch(error => {
                    handleInfo('Email não existe na plataforma,cadastre uma nova conta para este email');

                    this.setState({ formulario: 1 });
                    this.setState({
                        model: {
                            cli_id: 0,
                            cli_nome: this.state.name,
                            cli_email: this.state.email,
                            cli_perfilfacebook: this.state.userID,
                            cli_nascimento: '',
                            cli_cnpj: '',
                            cli_fone: '',
                            cli_senha: '',
                            cli_bairro: '',
                            cli_cidade: '',
                            cli_complemento: '',
                            cli_numero: '',
                            cli_senha_repetir: '',
                            cli_endereco: '',

                        }
                    })



                })


        }
    };

    componentClicked = () => console.log("clicked");

    Get_form = (form) => {
        this.Limpar();
        if (document.getElementById('span_message')) {
            document.getElementById('esqueci_senha_message').innerHTML = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
        }
        this.setState({ formulario: form });

    }
    Get_produtos = () => {
        api.get('api/produto')
            .then((response) => {
                console.log(response.data);
                this.setState({ Produtos: response.data });

            })
            .catch((error) => {
                const Produtos = [];
                this.setState({ Produtos });
                handleInfo('Nenhum produto encontrado');
                return false;
            })
    }

    setValues = (e, field) => {
        const { model } = this.state;
        model[field] = e.target.value;

        model.cli_fone = mask_tel_celular_area(model.cli_fone);
        model.cli_cnpj = mask_CNPJ(model.cli_cnpj);
        model.cli_email = mask_minusculo_sem_espaco(model.cli_email);
        model.cli_senha = mask_minusculo_sem_espaco(model.cli_senha);

        this.setState({ model });

    }

    esqueci_senha = () => {
        const model = this.state.model;
        if (model.cli_email !== '') {
            let data = {
                cli_email: model.cli_email
            };

            api({
                method: 'put',
                url: 'api/cliente/' + 2,
                data: data,  
                headers: {
                    Authorization: "bearer " + sessionStorage.getItem("token"),
                  },             
            })
                .then(response => {
                    let span = '';
                    //troquei == por ===
                    if (response.data.status === 'sucesso') {
                        handleSuccess(response.data.message);
                        span += '<span id="span_message" style="color:green;">' + response.data.message + '</span>';
                    } else {
                        handleError(response.data.message);
                        span += '<span id="span_message" style="color:red;">' + response.data.message + '</span>';
                    }
                    document.getElementById('esqueci_senha_message').innerHTML = span;
                    // this.Limpar();
                    // this.Get_empresa();

                })
                .catch(error => {
                    handleError('Erro ao executar');
                    console.log(error.response);
                    return false;


                })

        } else {
            handleError('Insira o email da sua conta');
        }
    }

    login = () => {
        localStorage.removeItem('id');
        localStorage.removeItem('nivel');
        localStorage.removeItem('nome');
        sessionStorage.removeItem("token");
        //localStorage.setItem('credentials', 'basic ' + "admin" + ':' + "admin");
        localStorage.setItem('credentials', 'basic admin: admin');
        localStorage.setItem('username', 'admin');
        localStorage.setItem('password', 'admin');

        const model = this.state.model;
        if (model.cli_email !== '' && model.cli_senha !== '') {
            let data = {
                cli_email: model.cli_email
            };

            api({
                method: 'get',
                url: 'api/login/' + model.cli_email + '/' + model.cli_senha + '/2',
                data: data,    
                headers: {
                    Authorization: "bearer " + sessionStorage.getItem("token"),
                  },            
            })
                .then(response => {
                    if (response.data[0].cli_situacao === 'A') {
                       // console.log(response);
                        localStorage.setItem('id', response.data[0].cli_id);
                        localStorage.setItem('nome', response.data[0].cli_nome);
                        localStorage.setItem('nivel', '2');
                        sessionStorage.setItem("token",response.data[0].token);
                        //  localStorage.setItem('senha',response.data[0].emp_senha);

                        this.props.history.push("/produtos");
                        return;
                    } else {
                        handleError('login ou senha incorretos');
                    }

                })
                .catch(error => {
                    handleError('login ou senha incorretos');
                    console.log(error.response);
                    return false;


                })

        } else {
            handleError('Insira o email e senha da sua conta');
        }

    }

    save = () => {
    
        const model = this.state.model;
        // console.log(JSON.stringify(model));
        if (model.cli_nome !== '' && model.cli_email !== ''
            && model.cli_fone !== '' && model.cli_senha !== ''
            && model.cli_bairro !== '' && model.cli_cidade !== ''
            && model.cli_numero !== '' && model.cli_senha_repetir !== ''
            && model.cli_endereco !== ''

        ) {
            if (model.cli_senha !== model.cli_senha_repetir) {
                handleError('Senha repetida não coincide com a senha');
                return false
            }
            if (!document.getElementById('termo_aceite').checked) {
                handleError('Aceite os termos de aceite');
                return false
            }
            if (!validar_Email(model.cli_email)) {
                handleError('Preencha um email valido');
                return false
            }


            let data = {
                cli_id: parseInt(model.cli_id),
                cli_nome: model.cli_nome,
                cli_email: model.cli_email,
                cli_nascimento: model.cli_nascimento,
                cli_cnpj: model.cli_cnpj,
                cli_fone: model.cli_fone,
                cli_senha: model.cli_senha,
                cli_perfilfacebook: model.cli_perfilfacebook,
                cli_bairro: model.cli_bairro,
                cli_cidade: model.cli_cidade,
                cli_complemento: model.cli_complemento,
                cli_numero: model.cli_numero,
                cli_endereco: model.cli_endereco,
            };

            // console.log('data eh: ' + JSON.stringify(data));       

            api({
                method: 'post',
                url: 'api/cliente',
                data: data, 
                headers: {
                    Authorization: "bearer " + sessionStorage.getItem("token"),
                  },  
                             
            })
                .then(response => {
                    if (response.data.status === 'sucesso') {
                        handleSuccess(response.data.message);
                        this.Limpar();
                    } else {
                        handleError(response.data.message);
                    }

                    // this.Get_empresa();

                })
                .catch(error => {

                    handleError('Erro ao executar');
                    console.log(error.response);
                    return false;
                })

        } else {
            handleError('Preencha todos os campos');
        }
    }

  
    componentDidMount = () => {

      
    
        var url = window.location.pathname
        var items = url.split("/");
        if (items[2] && items[3]) {
          //  console.log(JSON.stringify(items));




            let data = {
                cli_email: items[2],
                cli_codigo: items[3]
            };

            api({
                method: 'put',
                url: 'api/cliente',
                data: data,      
                headers: {
                    Authorization: "bearer " + sessionStorage.getItem("token"),
                  },          
            })
                .then(response => {
                    let span = '';

                    if (response.data.status === 'sucesso') {
                        handleSuccess(response.data.message);
                        span += '<div  class="alert alert-success" role="alert">' + response.data.message + '</div>';
                    } else {
                        handleError(response.data.message);
                        span += '<div class="alert alert-danger" role="alert">' + response.data.message + '</div>';
                    }
                    document.getElementById('ativacao_result').innerHTML = span;
                    // this.Limpar();
                    // this.Get_empresa();

                })
                .catch(error => {
                    handleError('Erro ao executar');
                    console.log(error.response);
                    return false;


                })
        }
    }

    Limpar = () => {
        this.setState({
            model: {
                cli_id: 0,
                cli_nome: '',
                cli_email: '',
                cli_perfilfacebook: '',
                cli_nascimento: '',
                cli_cnpj: '',
                cli_fone: '',
                cli_senha: '',
                cli_bairro: '',
                cli_cidade: '',
                cli_complemento: '',
                cli_numero: '',
                cli_senha_repetir: '',
                cli_endereco: '',

            }
        })
    }

    render() {

        const { model } = this.state;
        let fbContent;

        if (this.state.isLoggedIn) {
            fbContent = (
                <div
                    style={{
                        // width: "400px",
                        margin: "auto",
                        background: "#f4f4f4",
                        padding: "20px"
                    }}
                >
                    <img src={this.state.picture} alt={this.state.name} />
                    <h3>Bem-Vindo {this.state.name}</h3>
              Email: {this.state.email}
                </div>
            );
        } else {
            fbContent = (

                //   <div style={{display: 'flex',flexWrap: 'wrap' }} >                           
                <FacebookLogin
                    appId='554266545231005'
                    autoLoad={false}
                    fields='name,email,picture'
                    cssClass='loginBtn loginBtn--facebook'
                    textButton='&nbsp;&nbsp;Login com Facebook'
                    onClick={this.componentClicked}
                    callback={this.responseFacebook}
                />

                //</div>
                /*   <FacebookLogin
                     appId="554266545231005"
                     autoLoad={true}
                     fields="name,email,picture"
                     onClick={this.componentClicked}
                     callback={this.responseFacebook}
                   />*/
            );
        }

        return (
            <>
                <Navbar_public />





                <div className="container" >





                    <div class="modal fade" id="termos-de-uso" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLongTitle">Termos de uso</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p> Por favor, leia com atenção os termos e condições abaixo.</p>

                                    <p>Ao se cadastrar no keepdelivery.com.br você ESTÁ DE ACORDO COM AS CONDIÇÕES E TERMOS apresentados. Note que a recusa deste “Termos do Uso e de Serviços” impedirá que você faça uso dos serviços aqui descritos.</p>

                                    <p style={{ fontWeight: 'bold' }}>1. SERVIÇOS OFERECIDOS</p>
                                    <div style={{ textAlign: 'justify', marginLeft: '20px' }}>
                                        <p>1.1 Este TERMO se aplica para regular o uso do serviço oferecido pelo keepdelivery.com.br aos USUÁRIOS, qual seja, possibilitar a escolha, pelos USUÁRIOS, de PRODUTOS cadastrados e, via on-line, efetivar solicitações de pedidos destes PRODUTOS fornecidos pelas EMPRESAS participantes, de acordo com os produtos disponibilizados pela respectiva EMPRESA, sendo possível, igualmente, aos USUÁRIOS, selecionar se o produto deverá ser entregue ou se ele será retirado pessoalmente na loja.
</p>

                                        <p >1.2 O serviço do keepdelivery.com.br consiste, portanto, em aproximar, através do nosso site, os USUÁRIOS e as EMPRESAS cadastradas, possibilitando que os USUÁRIOS encaminhem às EMPRESAS, pedidos de PRODUTOS, bem como, sendo essa a opção dos USUÁRIOS, receber os produtos em sua casa ou retirar o produto na EMPRESA provedora do produto selecionado.
</p>

                                        <p>1.3 Desde logo fica esclarecido ao USUÁRIO - o qual se declara ciente - que o serviço oferecido pelo keepdelivery.com.br se relaciona apenas à intermediação (sem opção de pagamento on-line) para comercialização e entrega de produtos das EMPRESAS, não abarcando nenhuma outra etapa do processo, sendo estas outras etapas são de responsabilidade integral da EMPRESA, a quem deverão ser direcionados quaisquer reclamações acerca de problemas decorrentes de vício, defeito ou extravio dos produtos.
</p>
                                    </div>
                                    <p style={{ fontWeight: 'bold' }}>2. CADASTRO</p>

                                    <p style={{ textAlign: 'justify', marginLeft: '20px' }}>2.1 O USUÁRIO, para utilizar os serviços acima descritos, deverá ter capacidade jurídica para atos civis e deverá, necessariamente, prestar as informações exigidas no CADASTRO, assumindo integralmente a responsabilidade (inclusive cível e criminal) pela exatidão e veracidade das informações fornecidas no CADASTRO, que poderá ser verificado, a qualquer momento, pelo keepdelivery.com.br.
</p>
                                    <div style={{ textAlign: 'justify', marginLeft: '40px' }}>

                                        <p >2.1.1 Em caso de informações incorretas, inverídicas ou não confirmadas, bem assim na hipótese da negativa em corrigi-las ou enviar documentação que comprove a correção, o keepdelivery.com.br se reserva o direito de não concluir o cadastramento em curso ou, ainda, de bloquear o cadastro já existente, impedindo o USUÁRIO de utilizar os serviços on-line até que, a critério do keepdelivery.com.br, a situação de anomalia esteja regularizada.
</p>


                                        <p >2.1.2 O keepdelivery.com.br se reserva o direito de impedir, a seu critério, novos CADASTROS, ou cancelar os já efetuados, em caso de ser detectada anomalia que, em sua análise, seja revestida de gravidade ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para todos os USUÁRIOS. Também agirá o keepdelivery.com.br de tal forma caso verifique descumprimento, pelo USUÁRIO, de qualquer obrigação prevista no presente TERMO.
</p>

                                    </div>
                                    <p style={{ textAlign: 'justify', marginLeft: '20px' }}>2.2 Efetuado o CADASTRO com sucesso, o USUÁRIO terá acesso aos serviços por meio de login e senha, dados esses que se compromete a não divulgar a terceiros, ficando sob sua exclusiva responsabilidade qualquer solicitação de serviço que seja feita com o uso de login e senha de sua titularidade.
</p>
                                    <p style={{ fontWeight: 'bold' }}>3. OBRIGAÇÕES DO USUÁRIO</p>
                                    <div style={{ textAlign: 'justify', marginLeft: '20px' }}>

                                        <p>3.1 Efetuado o CADASTRO do USUÁRIO com sucesso, este se obriga a não divulgar a terceiros login e senha de acesso, nem permitir o uso de tais informações por terceiros, responsabilizando-se pelas consequências do uso de login e senha de sua titularidade.
</p>


                                        <p>3.2 É obrigação do USUÁRIO fornecer informações cadastrais totalmente verídicas e exatas, responsabilizando-se exclusiva e integralmente (em todas as searas jurídicas) por todo o conteúdo por si informado no item CADASTRO, mantendo atualizado e confirmado o endereço para entrega dos produtos encomendados.
</p>

                                        <p>3.3 O USUÁRIO se obriga, também, a pagar integralmente o preço dos produtos por si solicitados ou encomendados à EMPRESA e efetivamente a si entregues, diretamente ao portador dos produtos encomendados por meio deste site.
</p>

                                        <p>3.4 O USUÁRIO concorda com o uso das informações de avaliações e feedbacks dos serviços e produtos das EMPRESAS e do keepdelivery.com.br, conforme descrito nos TERMOS DE PRIVACIDADE do keepdelivery.com.br.
</p>
                                    </div>
                                    <p style={{ fontWeight: 'bold' }}>4. OBRIGAÇÕES DO keepdelivery.com.br</p>
                                    <div style={{ textAlign: 'justify', marginLeft: '20px' }}>
                                        <p>
                                            4.1 Disponibilizar no site keepdelivery.com.br espaço virtual que permita ao USUÁRIO devidamente cadastrado efetivar pedidos de compra dos produtos anunciados e comercializados pelas EMPRESAS e, também, disponibilizar ao USUÁRIO meios para selecionar a forma de entrega.
</p>
                                        <p>
                                            4.2 Proteger, por meio de armazenamento em servidores ou quaisquer outros meios magnéticos de alta segurança, a confidencialidade de todas as informações e cadastros relativos aos USUÁRIOS, assim como valores atinentes às operações financeiras advindas da operacionalização dos serviços previstos no presente TERMO. Contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações.
</p>
                                    </div>
                                    <p style={{ fontWeight: 'bold' }}>5. MODIFICAÇÕES DESTE TERMO</p>
                                    <div style={{ textAlign: 'justify', marginLeft: '20px' }}>
                                        <p>
                                            5.1 O presente TERMO DE USO poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços disponibilizados.
</p>
                                        <p>
                                            5.2 As novas condições entrarão em vigência assim que sua veiculada no site, sendo possível ao USUÁRIO manifestar oposição a quaisquer dos termos modificados, desde que o faça por escrito, através do site keepdelivery.com.br, o que gerará o cancelamento de seu CADASTRO.
</p>
                                    </div>


                                    <p style={{ fontWeight: 'bold' }}>6. CANAL DE COMUNICAÇÃO</p>
                                    <div style={{ textAlign: 'justify', marginLeft: '20px' }}>
                                        <p>
                                            6.1 Para estabelecer contato entre keepdelivery.com.br e o USUÁRIO fica disponibilizado o endereço eletrônico deste link, sendo certo que o USUÁRIO se obriga, igualmente, a manter em seu cadastro endereço eletrônico atual por intermédio do qual se farão as comunicações a ele dirigidas pelo keepdelivery.com.br, desde logo emprestando-se validade jurídica e efetividade a esse meio eletrônico de troca de informações recíprocas.
</p>
                                    </div>


                                    <p style={{ fontWeight: 'bold' }}>7. ACEITAÇÃO DO TERMO DE USO</p>
                                    <div style={{ textAlign: 'justify', marginLeft: '20px' }}>
                                        <p>
                                            7.1 O USUÁRIO declara ter lido, entendido e que aceita todas as regras, condições e obrigações estabelecidas no presente TERMO.
</p>
                                    </div>

                                    <p style={{ fontWeight: 'bold' }}>8. FORO DE ELEIÇÃO</p>
                                    <div style={{ textAlign: 'justify', marginLeft: '20px' }}>
                                        <p>
                                            8.1 As partes elegem como competente para dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do presente TERMO o foro da Comarca de Leme - SP.
</p>
                                    </div>

                                    <p style={{ fontWeight: 'bold' }}>9. Última atualização: 08 de maio de 2020</p>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal">Fechar</button>
                                </div>
                            </div>
                        </div>
                    </div>






                    <h2 className='text-center'>Área  Cliente</h2>
                    {this.state.formulario === 0 ?
                        <>
                            <div class="form-group row">

                                {/* ininio do formulario de login*/}
                                <div class="col-sm-4"></div>
                                <div id='ativacao_result' class="col-sm-4">

                                </div>
                                <div class="col-sm-4"></div></div>
                            <div class="form-group row">

                                {/* ininio do formulario de login*/}
                                <div class="col-sm-4"></div>
                                <div class="col-sm-4">

                                    <div class="card text-center">
                                        <div class="card-header_nav" >
                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><b>Login</b></a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" onClick={e => this.Get_form(1)} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><b>Registra-se<b></b></b></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="card-body">

                                            <form>
                                                <div class="form-group row">
                                                    <label htmlFor="cli_email" style={{ color: '#7c7f82' }} class="col-sm-3 col-form-label"><b>Email:</b></label>
                                                    <div class="col-sm-9">
                                                        <input type="email" class="form-control" id="cli_email"  autoComplete="on" value={model.cli_email} placeholder="Email"
                                                            onChange={e => this.setValues(e, 'cli_email')} />
                                                    </div>
                                                </div>
                                                <div class=" form-group row">
                                                    <label for="cli_senha" style={{ color: '#7c7f82' }} class="col-sm-3 col-form-label"><b>Senha:</b></label>
                                                    <div class="input-group col-sm-9">
                                                        <input type="password" style={{ width: '80%' }} maxLength='10' autoComplete="new-password" class="form-control" id="cli_senha" value={model.cli_senha} placeholder="Senha"
                                                            onChange={e => this.setValues(e, 'cli_senha')} />
                                                              <div class="input-group-prepend">
                                                            <div onClick={e => document.getElementById('cli_senha').type = document.getElementById('cli_senha').type == 'text' ? 'password' : 'text'} class="input-group-text"><i class="fas fa-eye"></i></div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="form-group row">

                                                    <div class="col-sm-12">
                                                        <button type="button" onClick={this.login} class="loginBtn btn-primary text-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                                                    </div>

                                                </div>
                                                {this.state.email ?
                                                    <div class="form-group row">
                                                        <div class="col-sm-2"> </div>
                                                        <div class="col-sm-8">
                                                            {fbContent}       </div>

                                                        <div class="col-sm-2"> </div>
                                                    </div>
                                                    :
                                                    <div class="form-group row">

                                                        <div class="col-sm-12">
                                                            {fbContent}       </div>


                                                    </div>
                                                }
                                            </form>
                                        </div>
                                        <div class="card-footer text-muted">
                                            <span onClick={e => this.Get_form(2)}>  esqueci minha senha?</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4"></div>
                            </div>
                        </>
                        : ''
                    }











                    {this.state.formulario === 1 ?
                        <div class="form-group row">
                            {/* ininio do formulario de registro*/}
                            <div class="col-sm-1"></div>
                            <div class="col-sm-10">

                                <div class="card text-center">
                                    <div class="card-header_nav" >
                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link " onClick={e => this.Get_form(0)} id="home-tabs" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="false"><b>Login</b></a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link active" id="profile-tabs" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="true"><b>Registra-se<b></b></b></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-body">

                                        <form class="needs-validation" novalidate>
                                            <div class="form-group row">

                                                <div class="col-sm-5">
                                                    <label for="cli_nome" style={{ color: '#7c7f82' }} class="col-form-label"><b>Nome:</b></label>

                                                    <input type="text" required class="form-control" id="cli_nome" value={model.cli_nome} placeholder="Nome"
                                                        onChange={e => this.setValues(e, 'cli_nome')} />
                                                </div>
                                                <div class="col-sm-5">
                                                    <label for="cli_endereco" style={{ color: '#7c7f82' }} class="col-form-label"><b>Endereço:</b></label>

                                                    <input type="text" required   class="form-control" id="cli_endereco" value={model.cli_endereco} placeholder="Endereço"
                                                        onChange={e => this.setValues(e, 'cli_endereco')} />
                                                </div>
                                                <div class="col-sm-2">
                                                    <label for="cli_numero" style={{ color: '#7c7f82' }} class="col-form-label"><b>Numero:</b></label>

                                                    <input type="text" required class="form-control" id="cli_numero" value={model.cli_numero} placeholder="Numero"
                                                        onChange={e => this.setValues(e, 'cli_numero')} />
                                                </div>

                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-6">
                                                    <label for="cli_complemento" style={{ color: '#7c7f82' }} class="col-form-label"><b>Complemento:</b></label>

                                                    <input type="text"   class="form-control" id="cli_complemento" value={model.cli_complemento} placeholder="Exemplo: Ao lado da casa de muro verde"
                                                        onChange={e => this.setValues(e, 'cli_complemento')} />
                                                </div>
                                                <div class="col-sm-6">
                                                    <label for="cli_bairro" style={{ color: '#7c7f82' }} class="col-form-label"><b>Bairro:</b></label>

                                                    <input type="text" required class="form-control" id="cli_bairro" value={model.cli_bairro} placeholder="Bairro"
                                                        onChange={e => this.setValues(e, 'cli_bairro')} />
                                                         
                                                </div>
                                                <div class="col-sm-5">
                                                    <label for="cli_cidade" style={{ color: '#7c7f82' }} class="col-form-label"><b>Cidade:</b></label>

                                                    <input type="text" required class="form-control" id="cli_cidade" value={model.cli_cidade} placeholder="Cidade"
                                                        onChange={e => this.setValues(e, 'cli_cidade')} />
                                                </div>
                                                <div class="col-sm-3">
                                                    <label for="cli_fone"  style={{ color: '#7c7f82' }} class="col-form-label"><b>Telefone/Celular:</b></label>

                                                    <input type="text" required class="form-control" id="cli_fone" value={model.cli_fone} placeholder="Fone com DDD"
                                                        onChange={e => this.setValues(e, 'cli_fone')} />
                                                </div>
                                                <div class="col-sm-4">
                                                    <label for="cli_email" style={{ color: '#7c7f82' }} class="col-form-label"><b>Login:<b style={{ fontSize: '10px' }}>(Email)</b></b></label>
                                                    {this.state.email ?
                                                        <input type="email" required class="form-control" id="cli_email" readOnly value={model.cli_email} placeholder="Email"
                                                            onChange={e => this.setValues(e, 'cli_email')} />
                                                        :
                                                        <input type="email" required class="form-control" id="cli_email" value={model.cli_email} placeholder="Email"
                                                            onChange={e => this.setValues(e, 'cli_email')} />

                                                    }
                                                </div>

                                            </div>
                                            <div class="form-group row">


                                                <div class="input-group col-sm-3">
                                                    <label for="cli_senha" style={{ width: '100%', color: '#7c7f82' }} class="col-form-label"><b>Senha:<b style={{fontSize:'10px'}}>(Max 10 caracteres)</b></b></label>

                                                    <input type="password" required class="form-control" id="cli_senha" maxLength='10' value={model.cli_senha} placeholder="Senha"
                                                        onChange={e => this.setValues(e, 'cli_senha')} />
                                                           <div class="input-group-prepend">
                                                            <div onClick={e => document.getElementById('cli_senha').type = document.getElementById('cli_senha').type == 'text' ? 'password' : 'text'} class="input-group-text"><i class="fas fa-eye"></i></div>
                                                        </div>
                                                </div>
                                                <div class="input-group col-sm-3">
                                                    <label for="cli_senha_repetir" style={{ width: '100%', color: '#7c7f82' }} class="col-form-label"><b> Repita:<b style={{fontSize:'10px'}}>(Max 10 caracteres)</b></b></label>

                                                    <input type="password" required class="form-control" id="cli_senha_repetir" maxLength='10' value={model.cli_senha_repetir} placeholder="Repita a sua Senha"
                                                        onChange={e => this.setValues(e, 'cli_senha_repetir')} />
                                                               <div class="input-group-prepend">
                                                            <div onClick={e => document.getElementById('cli_senha_repetir').type = document.getElementById('cli_senha_repetir').type == 'text' ? 'password' : 'text'} class="input-group-text"><i class="fas fa-eye"></i></div>
                                                        </div>
                                                </div>

                                            </div>
                                            <div class="form-group row">
                                                <div class="custom-control custom-checkbox">
                                                    <input required type="checkbox" class="custom-control-input" id="termo_aceite" />
                                                    <label style={{ opacity: '1' }} class="custom-control-label" for="termo_aceite">LI,COMPREENDI E CONCORDO COM OS</label> <strong style={{ color: '#ff07ff' }} data-toggle="modal" data-target="#termos-de-uso">TERMOS DE USO.</strong>
                                                </div>

                                                <div class="col-sm-3 " style={{ display: 'none' }}>
                                                    <label for="cli_nascimento" style={{ color: '#7c7f82' }} class="col-form-label"><b>Data de Nascimento:</b></label>

                                                    <input type="date"   class="form-control" id="cli_nascimento" value={model.cli_nascimento} placeholder="Data de Nascimento"
                                                        onChange={e => this.setValues(e, 'cli_nascimento')} />
                                                </div>
                                                <div class="col-sm-3" style={{ display: 'none' }}>
                                                    <label for="cli_cnpj" style={{ color: '#7c7f82' }} class="col-form-label"><b>CPF/CNPJ:</b></label>

                                                    <input type="text" class="form-control" id="cli_cnpj" value={model.cli_cnpj} placeholder="CPF ou CNPJ"
                                                        onChange={e => this.setValues(e, 'cli_cnpj')} />
                                                </div>
                                            </div>

                                            <div class="form-group row mt-2">
                                                <div class="col-sm-4"> </div>
                                                <div class="col-sm-4">
                                                    <button type="button" type='submit' onClick={this.save} class="btn btn-block btn-primary">Registrar</button>
                                                </div>
                                                <div class="col-sm-4"> </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="card-footer text-muted">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                                </div>
                            </div>
                            <div class="col-sm-1"></div>
                        </div>
                        : ''
                    }

                    {this.state.formulario === 2 ?
                        <div class="form-group row">

                            <div class="col-sm-4"></div>
                            <div class="col-sm-4">

                                <div class="card text-center">
                                    <div class="card-header_nav" >
                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" onClick={e => this.Get_form(0)} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><b>Login</b></a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" onClick={e => this.Get_form(1)} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><b>Registra-se<b></b></b></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-body">
                                        <legend style={{ fontSize: '14px' }}>Insira o email atual de sua conta para a equipe do keep delivery lhe enviar um link em seu email para geração de uma nova senha</legend>
                                        <form>
                                            <div class="form-group row">
                                                <label for="cli_email" style={{ color: '#7c7f82' }} class="col-sm-3 col-form-label"><b>Email:</b></label>
                                                <div class="col-sm-9">
                                                    <input type="email" class="form-control" id="cli_email" value={model.cli_email} placeholder="Email"
                                                        onChange={e => this.setValues(e, 'cli_email')} />
                                                </div>
                                            </div>



                                            <div class="form-group row">
                                                <div class="col-sm-2"> </div>
                                                <div class="col-sm-8">
                                                    <button type="button" onClick={e => this.esqueci_senha()} class="btn btn-block btn-primary">Enviar</button>
                                                </div>
                                                <div class="col-sm-2"> </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id='esqueci_senha_message' class="card-footer text-muted">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                                </div>
                            </div>
                            <div class="col-sm-4"></div>
                        </div>
                        : ''
                    }













                </div>
            </>
        );
    }
}











