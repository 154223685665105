import { toast } from "react-toastify";
import axios from "axios";

export const api = axios.create({
  baseURL: "https://keepdelivery.com.br",
  // baseURL:'https://keepsell.com.br',
  headers: {
  /*  Authorization: "Basic " + btoa("admin" + ":" + "admin"),*/
    "Content-Type": "application/json",
  },
});
export const dominio = "https://keepdelivery.com.br";
//export const dominio = 'https://keepsell.com.br';

export const handleError = (e) => {
  toast.error(e);
};
export const handleSuccess = (e) => {
  toast.success(e);
};
export const handleInfo = (e) => {
  toast.info(e);
};
export const handleWarn = (e) => {
  toast.warn(e);
};
export const handleDefault = (e) => {
  toast("Mensagem default");
};

export const handleCustom = (e) => {
  toast(e, {
    className: "sua-classe",
    autoClose: 10000,
    hideProgressBar: (toast.hideProgressBar = false),
    closeOnClick: (toast.closeOnClick = true),
    pauseOnHover: (toast.pauseOnHover = true),
    draggable: (toast.draggable = true),
    position: toast.POSITION.TOP_CENTER,
  });
};
